import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import fish from 'img/game/fish.svg'
import anchorImg from 'img/game/anchor.svg'
import { Background } from './Bg'

interface Props {
  isSlow: boolean
}

function BgIndex(props: Props) {
  const canvasRef = useRef(null)
  const fishRef = useRef(null)
  const anchorRef = useRef(null)
  const [background, setBackground] = useState<any>(null)

  useEffect(() => {
    if (!canvasRef.current) return
    const canvas: HTMLCanvasElement = canvasRef.current
    const ctx = canvas.getContext('2d')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    if (ctx) {
      const background = new Background(
        window.innerWidth,
        window.innerHeight,
        canvas,
        ctx,
        fishRef.current,
        anchorRef.current
      )
      setBackground(background)
    }
  }, [])

  useEffect(() => {
    if (background) background.animate(0)
  }, [background])

  useEffect(() => {
    if (props.isSlow) {
      if (background) background.slow()
    }
  }, [props.isSlow])

  return (
    <Root>
      <Canvas ref={canvasRef} />
      <Fish ref={fishRef} src={fish} />
      <Anchor ref={anchorRef} src={anchorImg} />
    </Root>
  )
}

export default BgIndex

const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #090909;
`

const Canvas = styled.canvas`
  display: block;
  transform: translate3d(0, 0, 0);
`

const Fish = styled.img`
  display: none;
`

const Anchor = styled.img`
  display: none;
`
