import React from 'react'
import { BrowserRouter as RouterReact, Routes, Route } from 'react-router-dom'
import Index from 'components/index'
import Incubator from 'components/Incubator'
import Pad from 'components/pad'
import Dao from 'components/dao'
import ScrollToTop from 'pages/ScrollToTop'

export default function Router() {
  return (
    <RouterReact>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/incubator" element={<Incubator />} />
        <Route path="/pad" element={<Pad />} />
        <Route path="/dao" element={<Dao />} />
      </Routes>
    </RouterReact>
  )
}
