import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import borders from 'img/pad/tiers/borders.svg'
import bg from 'img/pad/tiers/bg.png'
import { ReactComponent as ArrLeft } from 'img/pad/tiers/arr-left.svg'
import { ReactComponent as PrevBg } from 'img/pad/tiers/prev-bg.svg'
import { ReactComponent as AllBg } from 'img/pad/tiers/all-bg.svg'
import copyImg from 'img/pad/tiers/copy-bg.svg'
import copy2Img from 'img/pad/tiers/copy-bg-2.svg'
import tiersList from 'store/tiers'
import gsap from 'gsap'
import { FadeIn, anim, showAllAnim } from './animations'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useWindowSize from 'helpers/utils/useWindowSize'
import { ReactComponent as ItemBg } from 'img/pad/tiers/item-bg.svg'
import { ReactComponent as ItemBgLast } from 'img/pad/tiers/item-bg-last.svg'
import { useSwipeable } from 'react-swipeable'

function Tiers() {
  const [activeId, setActiveId] = useState(0)
  const [showAll, setShowAll] = useState(false)
  const ItemRef = useRef([])
  const wrapRef = useRef(null)
  const copyRef = useRef(null)
  const copy2Ref = useRef(null)
  const bgRef = useRef(null)
  const titleRef = useRef(null)
  const btnRef = useRef(null)
  const listRef = useRef(null)
  ItemRef.current = []
  const { width } = useWindowSize()
  const [resizeTimeout, setReszieTimeout] = useState<any>(null)

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    function handleResize() {
      clearTimeout(resizeTimeout)
      setReszieTimeout(
        setTimeout(() => {
          if (width > 960) {
            if (
              wrapRef &&
              copyRef &&
              copy2Ref &&
              bgRef &&
              titleRef &&
              btnRef &&
              listRef
            ) {
              FadeIn(copy2Ref.current, 0)
              FadeIn(copyRef.current, 0.2)
              FadeIn(
                wrapRef.current,
                0.4,
                bgRef.current,
                titleRef.current,
                btnRef.current,
                listRef.current
              )
            }
          } else {
            setShowAll(false)
          }
        }, 100)
      )
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [width])

  useEffect(() => {
    if (ItemRef.current) {
      if (showAll) {
        ItemRef.current.forEach((el, idx) => {
          showAllAnim(el, idx)
        })
      } else {
        ItemRef.current.forEach((el, idx) => {
          console.log(activeId)
          if (activeId >= idx) {
            let val = activeId - idx
            if (activeId > 2) val = 2 - idx
            let translateVal = `translate(-${50 + 15 * val}%, -50%)`
            let rotateVal = `rotate(-${6.8 * val}deg)`
            let scaleVal = `scale(${1.13 - 0.1 * val})`
            if (idx === 2 || idx === 3) {
              translateVal = `translate(-${50 - 15 * (activeId - idx)}%, -50%)`
              rotateVal = `rotate(${6.8 * (activeId - idx)}deg)`
              scaleVal = `scale(${1.13 - 0.1 * (activeId - idx)})`
            }
            if (idx === 4) {
              translateVal = `translate(-${50 + 15 * (activeId - idx)}%, -50%)`
              rotateVal = `rotate(-${6.8 * (activeId - idx)}deg)`
              scaleVal = `scale(${1.13 - 0.1 * (activeId - idx)})`
            }
            anim(el, translateVal, rotateVal, scaleVal)
          } else if (activeId < idx) {
            let left = '100%'
            if (idx > 2) {
              left = '0'
            }
            gsap.to(el, {
              opacity: 0,
              transform: `rotate(0deg)`,
              left: left,
              duration: 0.4,
            })
          }
        })
      }
    }
  }, [activeId, showAll])

  const onAllClick = () => {
    setShowAll(true)
  }

  const addToItemRefs = (el: any) => {
    // @ts-ignore
    if (el && !ItemRef.current.includes(el)) {
      // @ts-ignore
      ItemRef.current.push(el)
    }
  }

  const onNextClick = () => {
    if (activeId < tiersList.length - 1) {
      setActiveId(activeId + 1)
    }
  }

  const onPrevClick = () => {
    if (showAll) {
      setShowAll(false)
    } else {
      if (activeId > 0) {
        setActiveId(activeId - 1)
      }
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => onNextClick(),
    onSwipedRight: (eventData) => onPrevClick(),
  })

  return (
    <Root>
      <Container {...handlers}>
        <CopyBg2 ref={copy2Ref} />
        <CopyBg ref={copyRef} />
        <Wrap ref={wrapRef}>
          <Cont ref={bgRef}>
            <Title ref={titleRef}>Tier Structure</Title>
            <List ref={listRef}>
              {tiersList.map((item, idx) => {
                return (
                  <Item
                    key={idx}
                    ref={addToItemRefs}
                    active={activeId === idx || showAll}
                  >
                    {idx > 2 && <ItemBgLast />}
                    {idx <= 2 && <ItemBg />}
                    <ItemTitle titleColor={idx > 1 ? '#e90ea2' : '#3acd99'}>
                      {'TIER '}
                      <span>{item.title}</span>
                    </ItemTitle>
                    <ItemName>{item.name}</ItemName>
                    <ItemImg src={item.img} />
                    <NStart>{item.nstart}</NStart>
                    <StakeRequireds>NSTART Stake requireds</StakeRequireds>
                    <RectRow>
                      {Array.from(Array(17)).map((rect, index) => {
                        return (
                          <Rect
                            isPurple={
                              index + 1 <= item.purpleRects + item.greenRects
                            }
                            isGreen={index + 1 <= item.greenRects}
                          />
                        )
                      })}
                    </RectRow>
                    <Border />
                    <ItemRow>
                      <ItemText>Participation Odds Multiplier</ItemText>
                      <Participation>{item.participation}</Participation>
                    </ItemRow>
                    <ItemRow>
                      <ItemText>Pool Weight Multiplier</ItemText>
                      <Pool poolColor={idx > 1 ? '#e90ea2' : '#3acd99'}>
                        {item.pool}
                      </Pool>
                    </ItemRow>
                  </Item>
                )
              })}
            </List>
            <Buttons ref={btnRef}>
              <Prev
                active={activeId !== 0 || showAll}
                onClick={() => onPrevClick()}
              >
                <BtnBg>
                  <PrevBg />
                </BtnBg>
                <BtnText>Prev</BtnText>
                <ArrowIcon>
                  <ArrLeft />
                </ArrowIcon>
              </Prev>
              <All onClick={() => onAllClick()}>
                <BtnBg>
                  <AllBg />
                </BtnBg>
                <BtnText>All</BtnText>
              </All>
              <Next
                active={activeId !== 4}
                onClick={() => {
                  onNextClick()
                }}
              >
                <BtnBg>
                  <PrevBg />
                </BtnBg>
                <BtnText>Next</BtnText>
                <ArrowIcon>
                  <ArrLeft />
                </ArrowIcon>
              </Next>
            </Buttons>
          </Cont>
        </Wrap>
      </Container>
    </Root>
  )
}

export default Tiers

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 162px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 137px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 125px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 111px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 89px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 588px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 654px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 496px;
  }
`

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 877px;
  padding: 42px 5px 5px 5px;
  background: url('${borders}') center no-repeat;
  background-size: cover;
  opacity: 0;
  ${({ theme }) => theme.adaptive.xl} {
    height: 737px;
    padding: 35px 4px 4px 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 664px;
    padding: 32px 4px 4px 4px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 591px;
    padding: 28px 4px 4px 4px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 473px;
    padding: 23px 3px 3px 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: auto;
    padding: 0;
    background: transparent;
    opacity: 1;
  }
`

const CopyBg = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  width: 100%;
  height: 100%;
  background: url('${copyImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 25px;
    left: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 23px;
    left: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 20px;
    left: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 16px;
    left: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const CopyBg2 = styled.div`
  position: absolute;
  top: 60px;
  left: 60px;
  width: 100%;
  height: 100%;
  background: url('${copy2Img}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 50px;
    left: 50px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 46px;
    left: 46px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 40px;
    left: 40px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 32px;
    left: 32px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Cont = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 86px;
  background: url('${bg}') center no-repeat;
  background-size: cover;
  opacity: 0;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 72px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 65px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 58px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 46px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 0;
    background: transparent;
    opacity: 1;
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 145px;
  line-height: 71%;
  letter-spacing: -0.03em;
  text-align: center;
  color: #090909;
  transform: translateY(200px);
  opacity: 0;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 120px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 108px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 95px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 76px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 118px;
    color: #f4f3eb;
    opacity: 1;
    transform: unset;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 70px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 56px;
  }
`

const Buttons = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  margin-top: 45px;
  opacity: 0;
  transform: translateY(-200px);
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 39px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 35px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 25px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 64px;
    opacity: 1;
    transform: unset;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 50px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    display: none;
  }
`

const BtnText = styled.div`
  position: relative;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #000000;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 32px;
    color: #f4f3eb;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 26px;
  }
`

const BtnBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #f2ecdb;
    stroke: black;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.sm} {
      fill: transparent;
      stroke: #434343;
    }
  }
`

const ArrowIcon = styled.div`
  position: absolute;
  top: 14px;
  left: 21px;
  width: 11px;
  height: 9px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 12px;
    left: 18px;
    width: 10px;
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 11px;
    left: 16px;
    width: 9px;
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 9px;
    left: 14px;
    width: 8px;
    height: 6px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 8px;
    left: 11px;
    width: 6px;
    height: 5px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 15px;
    left: 22px;
    width: 12px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 12px;
    left: 18px;
    width: 10px;
    height: 8px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const BtnCss = css`
  position: relative;
  transition: 0.4s;
  &:hover {
    ${BtnBg} {
      svg {
        fill: #74e7bf;
        ${({ theme }) => theme.adaptive.sm} {
          ${BtnText} {
            color: #000000;
          }
          ${ArrowIcon} {
            svg {
              fill: #000000;
            }
          }
        }
      }
    }
  }
`

const Prev = styled.button<{ active: boolean }>`
  ${BtnCss};
  width: 153px;
  height: 37px;
  padding: 1px 45px 0 64px;
  margin-right: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 129px;
    height: 31px;
    padding: 3px 40px 0 54px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 116px;
    height: 28px;
    padding: 3px 36px 0 48px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 103px;
    height: 25px;
    padding: 3px 34px 0 43px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 82px;
    height: 20px;
    padding: 2px 26px 0 34px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 160px;
    height: 39px;
    padding: 0px 47px 0 67px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 132px;
    height: 32px;
    padding: 0px 39px 0 55px;
    margin-right: 9px;
  }
  ${BtnBg} {
    svg {
      fill: ${({ active }) => (active ? '#f2ecdb' : '#F1EBDA')};
      stroke: ${({ active }) => (active ? '#000' : '#ABABAB')};
      ${({ theme }) => theme.adaptive.sm} {
        fill: none;
        stroke: #434343;
      }
    }
  }
  ${BtnText} {
    color: ${({ active }) => (active ? '#000' : '#ABABAB')};
    ${({ theme }) => theme.adaptive.sm} {
      color: #f4f3eb;
    }
  }
  ${ArrowIcon} {
    svg {
      fill: ${({ active }) => (active ? '#000' : '#ABABAB')};
      ${({ theme }) => theme.adaptive.sm} {
        fill: #f4f3eb;
      }
    }
  }
`

const All = styled.button`
  ${BtnCss};
  width: 82px;
  height: 37px;
  padding: 1px 24px 0 24px;
  margin-right: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 69px;
    height: 31px;
    padding: 3px 23px 0 20px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 62px;
    height: 28px;
    padding: 3px 21px 0 18px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 55px;
    height: 25px;
    padding: 3px 19px 0 16px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 44px;
    height: 20px;
    padding: 2px 14px 0 13px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Next = styled(Prev)`
  transform: scale(-1, 1);
  ${BtnText} {
    transform: scale(-1, 1);
  }
`

const List = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  height: 449px;
  opacity: 0;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 22px;
    height: 367px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 19px;
    height: 331px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 18px;
    height: 294px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 14px;
    height: 235px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 43px;
    height: 442px;
    opacity: 1;
  }
  // ${({ theme }) => theme.adaptive.smm} {
  //   margin-top: 37px;
  //   height: 448px;
  // }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 32px;
    height: 331px;
  }
`

const Item = styled.div<{ idx?: number; active: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  opacity: 0;
  width: 274px;
  height: 376px;
  z-index: ${({ idx }) => (idx ? idx : 1)};
  padding: 0 36px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 265px;
    height: 364px;
    padding: 0 34px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 239px;
    height: 328px;
    padding: 0 31px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 213px;
    height: 293px;
    padding: 0 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 170px;
    height: 233px;
    padding: 0 21px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 320px;
    height: 439px;
    padding: 0 41px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 239px;
    height: 328px;
    padding: 0 31px;
  }
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    right: 0;
  }
  &:nth-child(4),
  &:nth-child(5) {
    left: 0;
  }
  &:nth-child(4),
  &:nth-child(5) {
    width: 292px;
    padding: 0 32px 0 54px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 283px;
      padding: 0 36px 0 54px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 255px;
      padding: 0 34px 0 47px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 228px;
      padding: 0 26px 0 44px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 181px;
      padding: 0 20px 0 33px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 342px;
      padding: 0 43px 0 61px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 255px;
      padding: 0 34px 0 47px;
    }
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .bg path {
    transition: 0.4s;
    fill: ${({ active }) => (active ? '#fff' : '#F4F3EB')};
  }
`

const ItemImg = styled.img`
  position: relative;
  display: block;
  width: 133px;
  height: 133px;
  margin: 0 auto;
  margin-top: 4px;
  transform: scale(-1, 1);
  ${({ theme }) => theme.adaptive.xl} {
    width: 129px;
    height: 129px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 116px;
    height: 116px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 104px;
    height: 104px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 82px;
    height: 82px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 155px;
    height: 155px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 116px;
    height: 116px;
    margin-top: 3px;
  }
`

const ItemTitle = styled.div<{ titleColor?: string }>`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 62px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  margin-top: 22px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 60px;
    line-height: 34px;
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 54px;
    line-height: 30px;
    margin-top: 22px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 48px;
    line-height: 27px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 38px;
    line-height: 21px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 72px;
    line-height: 40px;
    margin-top: 26px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 54px;
    line-height: 30px;
    margin-top: 22px;
  }
  span {
    color: ${({ titleColor }) => (titleColor ? titleColor : '')};
  }
`

const ItemName = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 24px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.35;
  margin-top: 3px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 23px;
    line-height: 13px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 21px;
    line-height: 12px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 18px;
    line-height: 10px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 15px;
    line-height: 8px;
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 28px;
    line-height: 16px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 21px;
    line-height: 12px;
    margin-top: 3px;
  }
`

const NStart = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 44px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 42px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 38px;
    line-height: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 34px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 28px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 52px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 38px;
    line-height: 21px;
  }
`

const StakeRequireds = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.35;
  margin-top: 4px;
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 9px;
    line-height: 12px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 14px;
    line-height: 19px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 12px;
    line-height: 16px;
    margin-top: 3px;
  }
`

const ItemRow = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 3px;
  }
`

const ItemText = styled.div`
  max-width: 98px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.03em;
  color: #1d1e1f;
  ${({ theme }) => theme.adaptive.xl} {
    max-width: 92px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    max-width: 90px;
    font-size: 12px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    max-width: 80px;
    font-size: 10px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    max-width: 59px;
    font-size: 8px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    max-width: 111px;
    font-size: 14px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    max-width: 90px;
    font-size: 12px;
    line-height: 16px;
  }
`

const ValueCss = css`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 52px;
  line-height: 29px;
  text-align: right;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.5;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 52px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 45px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 40px;
    line-height: 22px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 32px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 60px;
    line-height: 34px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 45px;
    line-height: 25px;
  }
`

const Participation = styled.div`
  ${ValueCss};
`

const Pool = styled.div<{ poolColor: string }>`
  ${ValueCss};
  opacity: 1;
  letter-spacing: -0.12em;
  color: ${({ poolColor }) => (poolColor ? poolColor : '')};
`

const RectRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 8px;
  }
`

const Rect = styled.div<{ isGreen: boolean; isPurple: boolean }>`
  width: 6px;
  height: 6px;
  background: #e7e7e7;
  ${({ isGreen, isPurple }) => {
    if (isGreen)
      return css`
        background: #74e7bf;
      `
    if (isPurple)
      return css`
        background: #e90ea2;
      `
  }}
  ${({ theme }) => theme.adaptive.xl} {
    width: 5px;
    height: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 4px;
    height: 4px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 3px;
    height: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 6px;
    height: 6px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 5px;
    height: 5px;
  }
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 4px;
  opacity: 0.2;
  background: #000000;
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 4px;
  }
`
