import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Main from './main'
import Incubator from './incubator'
import News from './news'
import Contact from '../contact'
import Footer from '../footer'
import useWindowSize from 'helpers/utils/useWindowSize'
import MobIncubator from './incubator/mobile'
import PlayerForm from '../modals/PlayerForm'
import ModalTemplate from '../modals/modal'
import { sendPlayer } from 'helpers/sendPlayer'
import TableForm from '../modals/TableForm'
import Loader from './loader'

function Index() {
  const [isRestart, setIsRestart] = useState(false)
  const [score, setScore] = useState(0)
  const [formModalOpen, setFormModalOpen] = useState(false)
  const [tableModalOpen, setTableModalOpen] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    window.onkeydown = function (e) {
      return e.keyCode !== 32
    }
  }, [])

  const openModal = (): void => {
    setFormModalOpen(true)
  }

  const restartHandler = () => {
    setFormModalOpen(false)
    setTableModalOpen(false)
    setIsRestart(true)
  }

  const leaderBoardHandler = () => {
    console.log('dsa')
    setFormModalOpen(false)
    setTableModalOpen(true)
  }

  const onGameOver = (score: number) => {
    setFormModalOpen(true)
    setIsRestart(false)
    setScore(score)
  }

  return (
    <Root>
      <Loader isOpen={true} />
      <ModalTemplate
        active={formModalOpen}
        onClose={() => setFormModalOpen(false)}
      >
        <PlayerForm
          onClose={() => setFormModalOpen(false)}
          restartHandler={restartHandler}
          leaderBoardHandler={leaderBoardHandler}
          score={score}
        />
      </ModalTemplate>
      <ModalTemplate
        active={tableModalOpen}
        onClose={() => setTableModalOpen(false)}
      >
        <TableForm
          onClose={() => setTableModalOpen(false)}
          restartHandler={restartHandler}
        />
      </ModalTemplate>
      <Main isRestart={isRestart} onEnd={onGameOver} />
      {width > 960 && <Incubator />}
      {width <= 960 && <MobIncubator />}
      <News />
      <Contact />
      <Footer />
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #f4f3eb;
  ${({ theme }) => theme.adaptive.sm} {
    background: #090909;
  }
`
