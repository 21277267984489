import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import bg from 'img/dao/board/bg.svg'
import bgSm from 'img/dao/board/bg-sm.svg'
import bgSmm from 'img/dao/board/bg-smm.svg'
import bgXs from 'img/dao/board/bg-xs.svg'
import sharksImg from 'img/dao/board/sharks.png'
import sharksImgSm from 'img/dao/board/sharks-sm.png'
import handImg from 'img/dao/board/hand.svg'
import lines from 'img/dao/board/lines.svg'
import linesSm from 'img/dao/board/lines-sm.svg'

function Board() {
  return (
    <Root>
      <Container>
        <TitleWrap id={'board'}>
          <BgRight />
          <Sharks />
          <Title>
            <span>NS</span> DAO Board
          </Title>
          <SubTitle>
            Expanding the NEAR ecosystem through decentralized Governance.
          </SubTitle>
        </TitleWrap>
        <Desc>
          We are rewarding our most loyal stakers with exclusive access to the
          NS incubator pipeline, through our
          <span>fully tokenized business model.</span>
        </Desc>
      </Container>
    </Root>
  )
}

export default Board

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 534px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 445px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 399px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 348px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 276px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 188px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 104px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 91px;
  }
`

const TitleWrap = styled.div`
  position: relative;
`

const Title = styled.div`
  position: relative;
  width: 429px;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 234px;
  line-height: 56%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #090909;
  margin-bottom: 27px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 358px;
    font-size: 195px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 322px;
    font-size: 175px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 286px;
    font-size: 156px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 229px;
    font-size: 124px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 116px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 229px;
    font-size: 116px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 186px;
    font-size: 90px;
    margin-bottom: 6px;
  }
  span {
    color: #74e7bf;
  }
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  width: 373px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 30px;
    width: 311px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 27px;
    width: 280px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 24px;
    width: 249px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
    width: 199px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 27px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 16px;
  }
`

const BgRight = styled.div`
  position: absolute;
  top: -236px;
  left: 295px;
  width: 2887px;
  height: 1478px;
  background: url('${bg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: -197px;
    left: 248px;
    width: 2405px;
    height: 1232px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -177px;
    left: 223px;
    width: 2164px;
    height: 1109px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -157px;
    left: 198px;
    width: 1923px;
    height: 985px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -126px;
    left: 158px;
    width: 1539px;
    height: 788px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 146px;
    left: -162px;
    width: 1638px;
    height: 960px;
    background: url('${bgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 242px;
    left: -150px;
    width: 1582px;
    height: 1010px;
    background: url('${bgSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 190px;
    left: -86px;
    width: 1255px;
    height: 821px;
    background: url('${bgXs}') center no-repeat;
    background-size: cover;
  }
`

const Sharks = styled.div`
  position: absolute;
  top: -102px;
  left: 295px;
  width: 1321px;
  height: 711px;
  background: url('${sharksImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: -79px;
    left: 258px;
    width: 1095px;
    height: 587px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -59px;
    left: 228px;
    width: 984px;
    height: 515px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -60px;
    left: 199px;
    width: 878px;
    height: 466px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -42px;
    left: 166px;
    width: 692px;
    height: 367px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 171px;
    left: 23px;
    width: 499px;
    height: 656px;
    background: url('${sharksImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 338px;
    left: -57px;
    width: 441px;
    height: 580px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 300px;
    left: -34px;
    width: 334px;
    height: 439px;
  }
`

const Desc = styled.div`
  position: relative;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 48px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  width: 1101px;
  margin-top: 99px;
  margin-left: 461px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 40px;
    width: 910px;
    margin-top: 85px;
    margin-left: 384px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 36px;
    width: 811px;
    margin-top: 75px;
    margin-left: 348px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 32px;
    width: 741px;
    margin-top: 67px;
    margin-left: 308px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 24px;
    width: 551px;
    margin-top: 51px;
    margin-left: 246px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 37px;
    width: 491px;
    margin-top: 741px;
    margin-left: 46px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 37px;
    width: 312px;
    margin-top: 715px;
    margin-left: 44px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 27px;
    width: 246px;
    margin-top: 584px;
    margin-left: 37px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -61px;
    width: 34px;
    height: 27px;
    background: url('${handImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      top: 13px;
      left: -54px;
      width: 27px;
      height: 22px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 16px;
      left: -50px;
      width: 23px;
      height: 19px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 10px;
      left: -48px;
      width: 23px;
      height: 19px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 12px;
      left: -34px;
      width: 17px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 14px;
      left: -39px;
      width: 30px;
      height: 24px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      top: 15px;
      left: -43px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: 11px;
      left: -35px;
      width: 24px;
      height: 19px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -563px;
    width: 450px;
    height: 185px;
    background: url('${lines}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      left: -469px;
      width: 375px;
      height: 154px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      left: -425px;
      width: 338px;
      height: 139px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      left: -375px;
      width: 300px;
      height: 123px;
    }
    ${({ theme }) => theme.adaptive.md} {
      left: -300px;
      width: 240px;
      height: 99px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      left: calc(50% - 23px);
      top: 392px;
      transform: translateX(-50%);
      width: 634px;
      height: 120px;
      background: url('${linesSm}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.smm} {
      left: calc(50% - 22px);
      top: 556px;
      width: 410px;
      height: 115px;
      transform: translateX(-50%);
    }
    ${({ theme }) => theme.adaptive.xs} {
      left: calc(50% - 18px);
      top: 369px;
      width: 333px;
      height: 94px;
      transform: translateX(-50%);
    }
  }
  span {
    font-weight: 700;
  }
`
