import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import nearLogo from 'img/index/main/near-logo.svg'
import { ReactComponent as ApplyImg } from 'img/index/main/apply-icon.svg'
import { ReactComponent as ContactImg } from 'img/index/main/contact-icon.svg'
import plusIcon from 'img/index/main/plus.svg'
import dots from 'img/contact/dots.svg'
import TextBorder from 'img/contact/text-border.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import { APPLY_FOR_IDO_LINK, CONTACT_LINK } from 'config/app'
import fish from 'img/game/fish.svg'
import anchorImg from 'img/game/anchor.svg'
import { Background } from '../index/main/bg/Bg'
import { Container } from 'theme/theme'
import shipImg from 'img/contact/ship-img.png'
import waleImg from 'img/contact/wale-img.png'

function Contact() {
  const canvasRef = useRef(null)
  const fishRef = useRef(null)
  const anchorRef = useRef(null)

  useEffect(() => {
    if (!canvasRef.current) return
    const canvas: HTMLCanvasElement = canvasRef.current
    const ctx = canvas.getContext('2d')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    if (ctx) {
      const background = new Background(
        window.innerWidth,
        window.innerHeight,
        canvas,
        ctx,
        fishRef.current,
        anchorRef.current
      )
      background.animate(0)
    }
  }, [])

  return (
    <Root>
      <Container>
        <TitleWrap>
          <Ship />
          <MobileBg />
          <Wale />
          <Plus />
          <SecondPlus />
          <Title>Contact</Title>
          <SubTitle>
            Bear market is the time and place to ape to build.
          </SubTitle>
          <SecondTitle>
            <Dots />
            NEARStarter is opening its doors for new aspiring projects launching
            on
            <NearLogo />
            <Text>Apply for the acceleration program</Text>
          </SecondTitle>
          <Buttons>
            <ApplyBtn href={APPLY_FOR_IDO_LINK} target="_blank">
              <ApplyText>Apply</ApplyText>
              <ApplyIcon>
                <ApplyImg />
              </ApplyIcon>
            </ApplyBtn>
            <ContactBtn href={CONTACT_LINK} target="_blank">
              <ContactText>Contact</ContactText>
              <ContactIcon>
                <ContactImg />
              </ContactIcon>
            </ContactBtn>
          </Buttons>
        </TitleWrap>
      </Container>
      <Canvas ref={canvasRef} />
      <Fish ref={fishRef} src={fish} />
      <Anchor ref={anchorRef} src={anchorImg} />
    </Root>
  )
}

export default Contact

const Root = styled.div`
  position: relative;
  padding-top: 145px;
  padding-bottom: 132px;
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  background: #090909;
  z-index: 1;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 117px;
    padding-bottom: 110px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 117px;
    padding-bottom: 99px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 78px;
    padding-bottom: 88px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 84px;
    padding-bottom: 70px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 436px;
    padding-bottom: 112px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 526px;
    padding-bottom: 102px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 417px;
    padding-bottom: 80px;
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 187px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 151px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 136px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 121px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 97px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 92px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 64px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 52px;
    margin-bottom: 10px;
  }
`

const TitleWrap = styled.div`
  position: relative;
`

const SubTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 200;
  font-size: 28px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #848484;
  margin-top: -34px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 15px;
    z-index: 2;
    color: #848484;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 12px;
    margin-bottom: 10px;
  }
`

const SecondTitle = styled.div`
  position: relative;
  font-family: 'Manrope';
  width: 787px;
  font-weight: 200;
  font-size: 60px;
  line-height: 117%;
  letter-spacing: -0.02em;
  color: #ffffff;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 50px;
    width: 656px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 45px;
    width: 590px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 40px;
    width: 524px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 32px;
    width: 420px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 34px;
    width: 461px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 100%;
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 27px;
  }
`

const NearLogo = styled.div`
  position: absolute;
  top: 152px;
  left: 351px;
  width: 209px;
  height: 55px;
  background: url('${nearLogo}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 127px;
    left: 293px;
    width: 174px;
    height: 46px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 114px;
    left: 263px;
    width: 157px;
    height: 41px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 101px;
    left: 234px;
    width: 139px;
    height: 37px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 81px;
    left: 187px;
    width: 111px;
    height: 29px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 87px;
    left: 202px;
    width: 126px;
    height: 33px;
    z-index: 2;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 125px;
    left: 199px;
    width: 126px;
    height: 33px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 99px;
    left: 160px;
    width: 98px;
    height: 26px;
  }
`

const Buttons = styled.div`
  position: relative;
  display: flex;
  margin-top: 89px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 76px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 69px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 62px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 49px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin-top: 44px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 41px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 35px;
  }
`

const ApplyText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 31px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 16px;
  }
`

const ApplyIcon = styled.div`
  width: 31px;
  height: 31px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 26px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 23px;
    height: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 21px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 17px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 13px;
    height: 13px;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: #000;
    transition: 0.4s;
  }
`

const ApplyBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 23px 0 37px;
  width: 404px;
  height: 62px;
  background: #ff85fb;
  border: 1px solid #f4f3eb;
  box-shadow: 6px 6px 0px rgba(255, 255, 255, 0.16);
  border-radius: 5px;
  transition: 0.4s;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 0 25px 0 31px;
    width: 342px;
    height: 52px;
    box-shadow: 5px 5px 0px rgba(255, 255, 255, 0.16);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 0 23px 0 29px;
    width: 308px;
    height: 47px;
    box-shadow: 4.5px 4.5px 0px rgba(255, 255, 255, 0.16);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 0 20px 0 23px;
    width: 273px;
    height: 41px;
    box-shadow: 4px 4px 0px rgba(255, 255, 255, 0.16);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 0 16px 0 18px;
    width: 219px;
    height: 33px;
    box-shadow: 3px 3px 0px rgba(255, 255, 255, 0.16);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 0 14px 0 16px;
    width: 100%;
    height: 45px;
    z-index: 2;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 0 15px 0 15px;
    height: 45px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 0 11px 0 12px;
    height: 35px;
    margin-bottom: 20px;
  }
  &:hover {
    background: #ff65fa;
    ${ApplyText} {
      color: #fff;
    }
    ${ApplyIcon} svg {
      fill: #fff;
    }
  }
`

const ContactText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #f4f3eb;
  opacity: 0.3;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 16px;
  }
`

const ContactIcon = styled.div`
  width: 26px;
  height: 26px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 22px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 17px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 16px;
    height: 16px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 12px;
    height: 12px;
  }
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.2;
    transition: 0.4s;
  }
`

const ContactBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  width: 404px;
  height: 62px;
  border: 1px solid rgba(244, 243, 235, 0.3);
  border-radius: 5px;
  margin-left: 46px;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 0 23px 0 30px;
    width: 342px;
    height: 52px;
    border-radius: 4px;
    margin-left: 33px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 0 20px 0 28px;
    width: 308px;
    height: 47px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 0 20px 0 22px;
    width: 273px;
    height: 41px;
    margin-left: 26px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 0 16px 0 15px;
    width: 219px;
    height: 33px;
    margin-left: 21px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 0 14px 0 16px;
    width: 100%;
    height: 45px;
    z-index: 2;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 0 15px 0 15px;
    height: 45px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 0 11px 0 12px;
    height: 35px;
    margin-bottom: 20px;
  }
  &:hover {
    ${ContactText}, ${ContactIcon} svg {
      opacity: 1;
    }
    border: 1px solid rgba(244, 243, 235, 1);
  }
`

const Plus = styled.div`
  position: absolute;
  left: -38px;
  top: -48px;
  width: 32px;
  height: 32px;
  background: url('${plusIcon}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    left: -33px;
    top: -43px;
    width: 26px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: -30px;
    top: -39px;
    width: 23px;
    height: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: -27px;
    top: -35px;
    width: 21px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: -22px;
    top: -28px;
    width: 17px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const SecondPlus = styled.div`
  position: absolute;
  right: -41px;
  bottom: 82px;
  width: 32px;
  height: 32px;
  background: url('${plusIcon}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    right: -126px;
    bottom: 56px;
    width: 26px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    right: -83px;
    bottom: 25px;
    width: 23px;
    height: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    right: -101px;
    bottom: 68px;
    width: 21px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    right: -65px;
    bottom: 29px;
    width: 21px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Ship = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    display: block;
    top: -308px;
    left: 51px;
    width: 474px;
    height: 415px;
    background: url('${shipImg}') center no-repeat;
    background-size: contain;
    z-index: -1;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -359px;
    left: -4px;
    width: 359px;
    height: 315px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 289px;
    height: 253px;
    top: -293px;
    left: 0;
  }
`

const Dots = styled.div`
  position: absolute;
  top: -250px;
  right: -1400px;
  width: 1353px;
  height: 624px;
  background: url('${dots}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: -213px;
    right: -1150px;
    width: 1185px;
    height: 525px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -192px;
    right: -1055px;
    width: 1067px;
    height: 472px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -170px;
    right: -940px;
    width: 948px;
    height: 420px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -137px;
    right: -750px;
    width: 759px;
    height: 336px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const MobileBg = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    display: block;
    top: -93px;
    left: 50%;
    height: 312px;
    width: 100vw;
    background: linear-gradient(
      180deg,
      rgba(9, 9, 9, 0.96) 0%,
      rgba(9, 9, 9, 0.87) 57.39%,
      rgba(9, 9, 9, 0) 100%
    );
    transform: translateX(-50%) matrix(1, 0, 0, -1, 0, 0);
    z-index: -1;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -303px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -232px;
    height: 411px;
  }
`

const ArrowIcon = styled.div`
  position: absolute;
  top: 40px;
  right: 37px;
  width: 17px;
  height: 16px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 33px;
    right: 31px;
    width: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 30px;
    right: 28px;
    width: 12px;
    height: 12px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 27px;
    right: 25px;
    width: 11px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 21px;
    right: 20px;
    width: 9px;
    height: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 27px;
    right: 25px;
    width: 11px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 21px;
    right: 20px;
    width: 9px;
    height: 9px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 17px;
    right: 15px;
    width: 7px;
    height: 7px;
  }
  svg {
    transition: 0.4s;
    fill: #090909;
    display: block;
    width: 100%;
    height: 100%;
  }
`

const Text = styled.div`
  position: absolute;
  top: -109px;
  right: -234px;
  width: 162px;
  height: 96px;
  font-family: 'Coders Crux';
  text-align: right;
  font-weight: 400;
  font-size: 33px;
  line-height: 96%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  transform: rotate(-10deg);
  ${({ theme }) => theme.adaptive.xl} {
    top: -83px;
    right: -192px;
    width: 135px;
    height: 81px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -68px;
    right: -174px;
    width: 122px;
    height: 73px;
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -60px;
    right: -139px;
    width: 108px;
    height: 65px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -50px;
    right: -119px;
    width: 87px;
    height: 52px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: -392px;
    right: unset;
    left: 24px;
    width: 111px;
    height: 66px;
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -506px;
    left: 24px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -391px;
    left: 27px;
    width: 86px;
    height: 52px;
    font-size: 18px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -19px;
    left: -28px;
    width: 225px;
    height: 156px;
    background: url('${TextBorder}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      top: -16px;
      left: -23px;
      width: 187px;
      height: 130px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: -14px;
      left: -21px;
      width: 169px;
      height: 117px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: -13px;
      left: -19px;
      width: 150px;
      height: 104px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: -10px;
      left: -15px;
      width: 120px;
      height: 83px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: -13px;
      left: -24px;
      width: 155px;
      height: 107px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: -11px;
      left: -19px;
      width: 121px;
      height: 84px;
    }
  }
`

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  transform: translate3d(0, 0, 0);
`

const Fish = styled.img`
  display: none;
`

const Anchor = styled.img`
  display: none;
`

const Wale = styled.div`
  display: none;
  background: url('${waleImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    position: absolute;
    width: 243px;
    height: 242px;
    top: -79px;
    right: -10px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 186px;
    height: 186px;
    top: -121px;
    right: -11px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 164px;
    height: 164px;
    top: -115px;
    right: -18px;
  }
`
