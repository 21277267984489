import { Game } from './Game'
import {
  Collision,
  Eating,
  Happy,
  Running,
  TrashEating,
  Vomit,
} from './playerState'
import { states } from './playerState'
export class Player {
  private game: Game
  width: number
  height: number
  x: number
  y: number
  private maxSpeed: number
  private speedX: number
  private vy: number
  private scaleVal: number
  private states: any[]
  currentState: any
  public frameX: number
  public frameY: number
  maxFrame: number
  private fps: number
  private frameInterval: number
  private frameTimer: number
  private imgW: number
  private imgH: number
  public mouthY: number
  public currRotation: number
  public hitY: number
  public hitX: number
  isEating: boolean
  private currentImg: any

  constructor(game: Game) {
    this.game = game
    this.width = 144 * this.game.ratio - 15 * this.game.ratio
    this.height = 144 * this.game.ratio - 65 * this.game.ratio
    this.imgW = 144
    this.imgH = 144
    this.x = 150 * this.game.ratio
    this.y = this.game.height - this.height - 200 * this.game.ratio
    this.hitX = this.x + 5 * this.game.ratio
    this.hitY = this.y + 40 * this.game.ratio
    this.mouthY = this.y + this.height / 2
    this.speedX = 0
    this.maxSpeed = 0.5
    this.vy = 0
    this.scaleVal = 1
    this.frameX = 0
    this.frameY = 0
    this.maxFrame = 0
    this.fps = 10
    this.frameInterval = 1000 / this.fps
    this.frameTimer = 0
    this.isEating = false
    this.states = [
      new Running(this),
      new Collision(this),
      new Eating(this),
      new Happy(this),
      new TrashEating(this),
      new Vomit(this),
    ]
    this.currentState = this.states[0]
    this.currentState.enter()
    this.currRotation = 0
    this.currentImg = null
  }
  update(
    input: string[],
    deltaTime: number,
    context: CanvasRenderingContext2D
  ) {
    this.hitX = this.x + 5 * this.game.ratio
    this.hitY = this.y + 40 * this.game.ratio

    if (this.x > this.game.width - this.width)
      this.x = this.game.width - this.width
    if (this.x < 0) this.x = 0

    // this.speed = 0
    //this.vy = 0

    if (input.includes('ArrowLeft') || input.includes('ArrowRight')) {
      if (input.includes('ArrowLeft')) {
        this.currRotation = 0
        if (this.speedX > -this.maxSpeed - this.game.groundSpeed) {
          this.speedX -= 0.02
        }
      } else if (input.includes('ArrowRight')) {
        this.currRotation = 0
        if (this.speedX < this.maxSpeed) {
          this.speedX += 0.02
        }
      }
    } else {
      this.speedX = 0
    }

    if (input.includes('ArrowDown') || input.includes('ArrowUp')) {
      if (input.includes('ArrowDown')) {
        this.currRotation = 60
        if (this.vy < this.maxSpeed) {
          this.vy += 0.02
        }
      } else if (input.includes('ArrowUp')) {
        this.currRotation = -45
        if (this.vy > -this.maxSpeed) {
          this.vy -= 0.02
        }
      }
    } else {
      this.vy = 0
    }

    if (
      !input.includes('ArrowDown') &&
      !input.includes('ArrowUp') &&
      !input.includes('ArrowLeft') &&
      !input.includes('ArrowRight')
    ) {
      this.currRotation = 0
    }
    this.x += this.speedX * deltaTime

    this.y += this.vy * deltaTime
    this.mouthY = this.y + (this.imgH * this.game.ratio) / 2

    if (this.y < this.game.maxHeight) this.y = this.game.maxHeight
    if (
      this.y >
      this.game.height - this.imgH * this.game.ratio - this.game.groundBg.height
    )
      this.y =
        this.game.height -
        this.imgH * this.game.ratio -
        this.game.groundBg.height

    if (this.frameTimer > this.frameInterval) {
      this.frameTimer = 0
      if (this.frameX < this.maxFrame) {
        if (
          (this.currentState.state === 'EATING' && this.frameX === 1) ||
          (this.currentState.state === 'TRASHEATING' && this.frameX === 1)
        ) {
          this.game.playerOverlay.draw(
            context,
            this.x,
            this.y,
            this.width,
            this.height
          )
        }
        this.frameX++
      } else {
        this.frameX = 0
        if (
          this.currentState.state === 'COLLISION' ||
          this.currentState.state === 'HAPPY' ||
          this.currentState.state === 'VOMIT' ||
          this.currentState.state === 'TRASHEATING' ||
          this.currentState.state === 'EATING'
        )
          this.setState(states.RUNNING)
        // if (this.currentState.state === 'EATING') this.setState(states.HAPPY)
        // if (this.currentState.state === 'TRASHEATING')
        //   this.setState(states.VOMIT)
      }
    } else {
      this.frameTimer += deltaTime
    }
  }

  setState = (state: number) => {
    this.currentState = this.states[state]
    this.currentState.enter()
  }
  draw(context: CanvasRenderingContext2D) {
    this.currentImg = this.game.playerImgs.find(
      (currentValue) =>
        currentValue.name === `${this.currentState.state}-${this.frameX}`
    )
    context.save()
    context.translate(this.x + this.imgW / 2, this.y + this.imgH / 2)
    context.rotate((this.currRotation * Math.PI) / 180)
    context.translate(-(this.x + this.imgW / 2), -(this.y + this.imgH / 2))
    // context.scale(this.game.ratio, this.game.ratio)
    if (this.currentImg)
      context.drawImage(
        this.currentImg,
        this.x,
        this.y,
        this.imgW * this.game.ratio,
        this.imgH * this.game.ratio
      )

    // context.fillStyle = 'red'
    // context.rect(this.hitX, this.hitY, this.width, this.height)
    // context.fill()
    context.setTransform(1, 0, 0, 1, 0, 0)
    context.restore()
  }
}
