import { Game } from './Game'
import { Player } from './Player'
import { detectRectangleCollision } from './ColisionDetection'
import { states } from './playerState'
import { Sound } from './Sound'

export class Plastic {
  private game: Game
  private width: number
  private height: number
  public x: number
  public y: number
  markedForDelete: boolean
  private img: any
  private isColision: boolean
  private coinVal: number
  private currRotation: number
  private angle: number
  private angleSpeed: number
  private curve: number
  private isSound: boolean

  constructor(game: Game, img: any, coinVal: number, x?: number) {
    this.game = game
    this.width = 48 * this.game.ratio
    this.height = 48 * this.game.ratio
    this.x = x ? x : this.game.width + 100 * this.game.ratio
    this.y =
      this.game.maxHeight +
      Math.random() * (this.game.height - this.game.maxHeight) * 0.7
    // this.speedX = this.game.RandomIntInRange(1, 3) / 10
    this.markedForDelete = false
    this.img = img
    this.isColision = false
    this.coinVal = coinVal
    this.currRotation = game.RandomIntInRange(0, 360)
    this.angle = 0
    this.angleSpeed = game.RandomIntInRange(1, 2) * 0.02
    this.curve = game.RandomIntInRange(1, 3) * 0.2
    this.isSound = true
  }
  update(deltaTime: number, player: Player) {
    this.x -= this.game.groundSpeed * deltaTime
    this.y += this.curve * Math.sin(this.angle)
    this.angle += this.angleSpeed
    if (this.x + this.width < 0) this.markedForDelete = true

    if (!this.isColision) {
      let playerRect = {
        x: player.hitX,
        width: player.width,
        y: player.hitY,
        height: player.height,
        currRotation: player.currRotation,
      }
      let areaRect = {
        x: this.x - 20,
        width: this.width + 40,
        y: this.y - 20,
        height: this.height + 40,
        currRotation: 0,
      }
      let firstRect = {
        x: this.x,
        width: this.width,
        y: this.y,
        height: this.height,
        currRotation: this.currRotation,
      }
      if (detectRectangleCollision(firstRect, playerRect)) {
        this.isColision = true
      }

      if (detectRectangleCollision(areaRect, playerRect)) {
        player.setState(states.TRASHEATING)
      }
    } else {
      if (this.isSound && !this.game.isMute) {
        if (this.coinVal === 7) {
          this.isSound = false
          let sound = new Sound(
            this.game.audioCtx,
            this.game.buffer.getSoundByIndex(3)
          )
          sound.play()
        } else {
          this.isSound = false
          let sound = new Sound(
            this.game.audioCtx,
            this.game.buffer.getSoundByIndex(2)
          )
          sound.play()
        }
      }
      let centerY = this.y + this.height / 2
      if (centerY !== player.mouthY) {
        if (centerY < player.mouthY) {
          this.y += this.game.groundSpeed * deltaTime * 3
        } else {
          this.y -= this.game.groundSpeed * deltaTime * 3
        }
      }
      if (this.x < player.x + player.width * 0.7) {
        this.game.plasticColisionsDetect(this.coinVal)
        this.markedForDelete = true
        player.setState(states.VOMIT)
        if (!this.game.isMute) {
          let sound = new Sound(
            this.game.audioCtx,
            this.game.buffer.getSoundByIndex(4)
          )
          sound.play()
        }
      }
      if (player.x + player.width < this.x) {
        this.isColision = false
      }
    }
  }

  draw(context: CanvasRenderingContext2D) {
    context.save()
    context.translate(this.x + this.width / 2, this.y + this.height / 2)
    context.rotate((this.currRotation * Math.PI) / 180)
    context.translate(-(this.x + this.width / 2), -(this.y + this.height / 2))
    // context.fillStyle = 'red'
    // context.rect(this.x, this.y, this.width, this.height)
    // context.fill()
    context.drawImage(this.img, this.x, this.y, this.width, this.height)
    context.restore()
  }
}
