import { Game } from './Game'
import { Player } from './Player'
import { detectRectangleCollision } from './ColisionDetection'
import { states } from './playerState'
import { Sound } from './Sound'

export class Coin {
  private game: Game
  private width: number
  private height: number
  private x: number
  private y: number
  markedForDelete: boolean
  private img: any
  private isColision: Boolean
  private dx: number
  private dy: number
  private val: number

  constructor(
    game: Game,
    y: number,
    dx: number,
    dy: number,
    img: any,
    val: number,
    x?: number
  ) {
    this.game = game
    this.width = 48 * this.game.ratio
    this.height = 48 * this.game.ratio
    this.dx = dx
    this.dy = dy
    this.x = x ? x : this.game.width + this.dx
    this.y = y + this.dy
    this.markedForDelete = false
    this.img = img
    this.isColision = false
    this.val = val
  }
  update(deltaTime: number, player: Player) {
    this.x -= this.game.groundSpeed * deltaTime

    if (this.x + this.width < 0) this.markedForDelete = true

    let coinRect = {
      x: this.x,
      width: this.width,
      y: this.y,
      height: this.height,
      currRotation: 0,
    }
    let areaRect = {
      x: this.x - 20,
      width: this.width + 40,
      y: this.y - 20,
      height: this.height + 40,
      currRotation: 0,
    }
    let playerRect = {
      x: player.hitX,
      width: player.width,
      y: player.hitY,
      height: player.height,
      currRotation: player.currRotation,
    }

    if (detectRectangleCollision(coinRect, playerRect)) {
      this.isColision = true
    }

    if (detectRectangleCollision(areaRect, playerRect)) {
      if (player.currentState.state !== 'EATING') {
        player.setState(states.EATING)
      }
    }

    if (this.isColision) {
      let centerY = this.y + this.height / 2
      if (centerY !== player.mouthY) {
        if (centerY < player.mouthY) {
          this.y += 0.1 * deltaTime
        } else {
          this.y -= 0.1 * deltaTime
        }
      }
      if (this.x < player.x + player.width * 0.7) {
        this.game.coinColisionsDetect(this.val)
        this.markedForDelete = true
        player.setState(states.HAPPY)
        if (!this.game.isMute) {
          // this.game.coinSound.currentTime = 0
          // this.game.coinSound.play()
          let sound = new Sound(
            this.game.audioCtx,
            this.game.buffer.getSoundByIndex(0)
          )
          sound.play()
        }
      }
      if (!detectRectangleCollision(coinRect, playerRect)) {
        this.isColision = false
      }
    }
  }
  draw(context: CanvasRenderingContext2D) {
    context.drawImage(this.img, this.x, this.y, this.width, this.height)
  }
}
