import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import cloudsImg from 'img/dao/main/clouds.svg'
import cloudsSm from 'img/dao/main/clouds-sm.svg'
import fishesImg from 'img/dao/main/fishes.svg'
import fishesImgSm from 'img/dao/main/fishes-sm.svg'
import sunImg from 'img/dao/main/sun.png'
import { ReactComponent as Arrow } from 'img/dao/main/explore-icon.svg'
import { ReactComponent as ContactIcon } from 'img/dao/main/contact-icon.svg'
import { DISCORD_LINK } from 'config/app'
import { HashLink as Link } from 'react-router-hash-link'

function Main() {
  return (
    <Root>
      <Container>
        <TitleWrap>
          <Sun />
          <SubTitle>Tokenized Incubator &</SubTitle>
          <Title>NSDAO</Title>
          <Buttons>
            <Explore>
              <Link to={'/dao#board'}>
                EXPLORE
                <Arrow />
              </Link>
            </Explore>
            <Contact href={DISCORD_LINK} target={'_blank'}>
              Contact us
              <ContactIcon />
            </Contact>
          </Buttons>
          <Clouds />
          <Fishes />
        </TitleWrap>
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 183px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 152px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 137px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 120px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 97px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 0;
  }
`

const TitleWrap = styled.div`
  position: relative;
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 90px;
  line-height: 70%;
  letter-spacing: -0.02em;
  color: #090909;
  margin-bottom: 21px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 75px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 67px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 60px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 48px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 48px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 32px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'PressStart2P';
  font-weight: 400;
  font-size: 134px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #74e7bf;
  margin-bottom: 44px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 112px;
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 100px;
    margin-bottom: 36px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 90px;
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 72px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 66px;
    margin-bottom: 464px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 56px;
    margin-bottom: 392px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 45px;
    margin-bottom: 319px;
  }
`

const Buttons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
  }
`

const ButtonCss = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
  height: 50px;
  border-radius: 4px;
  box-shadow: 4.78125px 4.78125px 0px rgba(0, 0, 0, 0.88);
  margin-right: 30px;
  padding: 14px 23px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    width: 240px;
    height: 42px;
    border-radius: 5px;
    box-shadow: 3.98513px 3.98513px 0px rgba(0, 0, 0, 0.88);
    margin-right: 25px;
    padding: 12px 12px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 202px;
    height: 35px;
    border-radius: 3px;
    box-shadow: 3.3546px 3.3546px 0px rgba(0, 0, 0, 0.88);
    margin-right: 21px;
    padding: 9px 11px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    box-shadow: 4.78125px 4.78125px 0px rgba(0, 0, 0, 0.88);
    margin-right: 0;
    margin-bottom: 26px;
    padding: 14px 15px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 41px;
    border-radius: 3px;
    box-shadow: 3.88658px 3.88658px 0px rgba(0, 0, 0, 0.88);
    margin-bottom: 21px;
    padding: 9px 12px;
  }
`

const Explore = styled.div`
  a {
    ${ButtonCss};
    background: #ff85fb;
    border: 1px solid #f4f3eb;
    cursor: pointer;
    color: #000000;
    transition: 0.4s;
    svg {
      display: block;
      width: 14px;
      height: 17px;
      fill: #0d0d0c;
      opacity: 0.8;
      transition: 0.4s;
      ${({ theme }) => theme.adaptive.xl} {
        width: 11px;
        height: 14px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 10px;
        height: 12px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 13px;
        height: 16px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 11px;
        height: 13px;
      }
    }
    &:hover {
      border: 1px solid #090909;
      background: #ff65fa;
      color: #f4f3eb;
      svg {
        fill: #f4f3eb;
      }
    }
  }
`

const Contact = styled.a`
  ${ButtonCss};
  background: #f4f3eb;
  border: 1px solid #090909;
  cursor: pointer;
  color: #090909;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 0;
  }
  svg {
    display: block;
    width: 26px;
    height: 21px;
    fill: #090909;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      width: 21px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 18px;
      height: 15px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 26px;
      height: 21px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 21px;
      height: 17px;
    }
  }
  &:hover {
    background: #ff65fa;
    color: #f4f3eb;
    svg {
      fill: #f4f3eb;
    }
  }
`

const Clouds = styled.div`
  position: absolute;
  left: -84px;
  top: -171px;
  width: 1935px;
  height: 754px;
  background: url('${cloudsImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    left: -70px;
    top: -142px;
    width: 1612px;
    height: 628px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: -63px;
    top: -128px;
    width: 1452px;
    height: 565px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: -56px;
    top: -112px;
    width: 1290px;
    height: 503px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: -45px;
    top: -91px;
    width: 1032px;
    height: 402px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    left: -57px;
    top: 127px;
    width: 580px;
    height: 523px;
    background: url('${cloudsSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    left: -100px;
    top: 109px;
    width: 456px;
    height: 411px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    left: -81px;
    top: 83px;
    width: 371px;
    height: 334px;
  }
`

const Fishes = styled.div`
  position: absolute;
  left: -20px;
  top: 444px;
  width: 1413px;
  height: 493px;
  background: url('${fishesImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    left: -18px;
    top: 370px;
    width: 1178px;
    height: 411px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: -16px;
    top: 333px;
    width: 1060px;
    height: 370px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: -15px;
    top: 298px;
    width: 942px;
    height: 329px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: -12px;
    top: 237px;
    width: 754px;
    height: 263px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    left: -162px;
    top: 189px;
    width: 633px;
    height: 1125px;
    background: url('${fishesImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    left: -157px;
    top: 113px;
    width: 508px;
    height: 949px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    left: -84px;
    top: 113px;
    width: 413px;
    height: 771px;
  }
`

const Sun = styled.div`
  position: absolute;
  left: 471px;
  top: -580px;
  width: 1693px;
  height: 1693px;
  background: url('${sunImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    left: 393px;
    top: -483px;
    width: 1411px;
    height: 1411px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: 353px;
    top: -435px;
    width: 1270px;
    height: 1270px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: 314px;
    top: -385px;
    width: 1129px;
    height: 1129px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: 251px;
    top: -309px;
    width: 903px;
    height: 903px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    left: -95px;
    top: -171px;
    width: 1024px;
    height: 1024px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    left: -159px;
    top: -144px;
    width: 863px;
    height: 863px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    left: -129px;
    top: -117px;
    width: 702px;
    height: 702px;
  }
`
