export class Sound {
  private context: any
  private buffer: any
  private gainNode: GainNode
  private source: AudioBufferSourceNode
  constructor(context: any, buffer: any) {
    this.context = context
    this.buffer = buffer
    this.gainNode = this.context.createGain()
    this.source = this.context.createBufferSource()
  }
  init() {
    this.source.buffer = this.buffer
    this.source.connect(this.gainNode)
    this.gainNode.connect(this.context.destination)
  }

  play() {
    this.init()
    this.source.start(0.01)
  }

  stop() {
    this.gainNode.gain.exponentialRampToValueAtTime(
      0.001,
      this.context.currentTime + 0.5
    )
    this.source.stop(this.context.currentTime + 0.5)
  }
}
