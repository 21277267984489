import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Game } from './Game'
import playerOverlay from 'img/game/player-overlay.svg'
import coinImg from 'img/game/coin.svg'
import heartImg from 'img/game/heart.svg'
import diamondImg from 'img/game/diamond.svg'
import cdTrash from 'img/game/cd-trash.svg'
import binTrash from 'img/game/bin-trash.svg'
import anchorImg from 'img/game/game-anchor.svg'
import borderImg from 'img/game/border.svg'
import { ReactComponent as LiveIcon } from 'img/game/live.svg'
import buttonUp from 'img/game/button-up.svg'
import { ReactComponent as PlayButton } from 'img/game/play-btn.svg'
import { ReactComponent as ScrollButton } from 'img/game/scroll-btn.svg'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import useWindowSize from 'helpers/utils/useWindowSize'
import sound from 'components/game/audio/play.mp3'
import { ReactComponent as MuteIcon } from 'img/game/mute-img.svg'
import { ReactComponent as SoundIcon } from 'img/game/sound.svg'
import { HashLink as Link } from 'react-router-hash-link'

interface Props {
  isRestart: boolean
  onEnd(val: number): void
  onSlow(val: boolean): void
}

function GameIndex(props: Props) {
  const canvasRef = useRef(null)
  const rootRef = useRef(null)
  const playerOverlayRef = useRef(null)
  const coinRef = useRef(null)
  const anchorRef = useRef(null)
  const cdRef = useRef(null)
  const binRef = useRef(null)
  const diamondRef = useRef(null)
  const heartRef = useRef(null)
  const [lives, setLives] = useState(3)
  const [coins, setCoins] = useState(0)
  const [isChange, setIsChange] = useState(false)
  const [game, setGame] = useState<Game | null>(null)
  const [isStart, setIsStart] = useState(false)
  const [isMute, setIsMute] = useState(false)
  const [changeTimeout, setChangeTimeout] = useState<any>(null)
  const [gameOverTimeout, setGameOverTimeout] = useState<any>(null)
  const { width } = useWindowSize()
  const body = document.body
  const playSound = new Audio(sound)

  useEffect(() => {
    if (!canvasRef.current) return
    const canvas: HTMLCanvasElement = canvasRef.current
    const ctx = canvas.getContext('2d')
    if (ctx) {
      const game = new Game(
        canvas,
        ctx,
        playerOverlayRef.current,
        cdRef.current,
        binRef.current,
        coinRef.current,
        diamondRef.current,
        anchorRef.current,
        heartRef.current,
        onLiveHandler,
        onCoinsHandler
      )
      setGame(game)
    }
  }, [width])

  useEffect(() => {
    if (game) {
      game.init()
    }
  }, [game])

  useEffect(() => {
    if (lives < 1) {
      clearTimeout(gameOverTimeout)
      setGameOverTimeout(
        setTimeout(() => {
          props.onEnd(coins)
          enableBodyScroll(body)
        }, 1000)
      )
    }
  }, [lives])

  useEffect(() => {
    if (props.isRestart) {
      if (game) {
        game.init()
        setLives(3)
        setCoins(0)
        setIsStart(false)
      }
    }
  }, [props.isRestart])

  const onLiveHandler = (val: number) => {
    props.onSlow(true)
    setLives(val)
  }

  const onCoinsHandler = (val: number) => {
    setCoins(val)
    setIsChange(true)

    clearTimeout(changeTimeout)
    setChangeTimeout(setTimeout(() => setIsChange(false), 1000))
  }

  const onTouch = (key: string) => {
    if (game) {
      game.onTouch(key)
    }
  }

  const onTouchEnd = (key: string) => {
    if (game) {
      game.deleteKey(key)
    }
  }

  const muteHandler = () => {
    if (game) {
      game.muteHandler()
      setIsMute(!isMute)
    }
  }

  const startGame = () => {
    if (game) {
      window.scrollTo(0, 0)
      playSound.play()
      game.animate(0)
      setIsStart(true)
      disableBodyScroll(body)
    }
  }

  return (
    <Root ref={rootRef}>
      <GameBorder isGame={isStart} />
      <GameOverBg isOver={lives < 1} />
      <Canvas ref={canvasRef} />
      <PlayerOverlay ref={playerOverlayRef} src={playerOverlay} />
      <Cd ref={cdRef} src={cdTrash} />
      <Bin ref={binRef} src={binTrash} />
      <Coin ref={coinRef} src={coinImg} />
      <Diamond ref={diamondRef} src={diamondImg} />
      <Anchor ref={anchorRef} src={anchorImg} />
      <Heart ref={heartRef} src={heartImg} />
      <MoneyWrap>
        <Lives>
          <LiveWrap>
            <Live isActive={lives === 3} noLive={false}>
              <LiveIcon />
            </Live>
            <Live isActive={lives < 3} noLive={true}>
              <LiveIcon />
            </Live>
          </LiveWrap>
          <LiveWrap>
            <Live isActive={lives >= 2} noLive={false}>
              <LiveIcon />
            </Live>
            <Live isActive={lives < 2} noLive={true}>
              <LiveIcon />
            </Live>
          </LiveWrap>
          <LiveWrap>
            <Live isActive={lives >= 1} noLive={false}>
              <LiveIcon />
            </Live>
            <Live isActive={lives < 1} noLive={true}>
              <LiveIcon />
            </Live>
          </LiveWrap>
        </Lives>
        <Money isChange={isChange}>
          <MoneyValue>{`$ ${coins}`}</MoneyValue>
        </Money>
        <MoneyText>Your Money</MoneyText>
      </MoneyWrap>
      <Buttons>
        <ButtonRow>
          <Button
            onTouchStart={() => onTouch('ArrowUp')}
            onTouchEnd={() => onTouchEnd('ArrowUp')}
          />
        </ButtonRow>
        <ButtonRow>
          <Button
            onTouchStart={() => onTouch('ArrowLeft')}
            onTouchEnd={() => onTouchEnd('ArrowLeft')}
          />
          <Button
            onTouchStart={() => onTouch('ArrowDown')}
            onTouchEnd={() => onTouchEnd('ArrowDown')}
          />
          <Button
            onTouchStart={() => onTouch('ArrowRight')}
            onTouchEnd={() => onTouchEnd('ArrowRight')}
          />
          <Mute onClick={muteHandler}>
            {isMute ? <MuteIcon /> : <SoundIcon />}
          </Mute>
        </ButtonRow>
        <ButtonRow>
          <ButtonsText>Use it!</ButtonsText>
        </ButtonRow>
      </Buttons>
      <GameOver isOver={lives < 1}>GameOver</GameOver>
      <Controls isStart={isStart}>
        <BtnWrap>
          <Btn>
            <button onClick={startGame}>
              <PlayButton />
            </button>
          </Btn>
          <BtnText>PLAY</BtnText>
        </BtnWrap>
        <BtnWrap>
          <Btn>
            <Link to={'#incubator'}>
              <ScrollButton />
            </Link>
          </Btn>
          <BtnText>SCROLL DOWN</BtnText>
        </BtnWrap>
      </Controls>
    </Root>
  )
}

export default GameIndex

const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const Canvas = styled.canvas`
  display: block;
  transform: translate3d(0, 0, 0);
`

const PlayerOverlay = styled.img`
  display: none;
`

const Bin = styled.img`
  display: none;
`

const Cd = styled.img`
  display: none;
`

const Coin = styled.img`
  display: none;
`

const Diamond = styled.img`
  display: none;
`

const Anchor = styled.img`
  display: none;
`

const Heart = styled.img`
  display: none;
`

const MoneyWrap = styled.div`
  position: absolute;
  left: 105px;
  bottom: 66px;
  width: 85px;
  ${({ theme }) => theme.adaptive.xl} {
    left: 88px;
    bottom: 55px;
    width: 71px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: 79px;
    bottom: 50px;
    width: 64px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: 70px;
    bottom: 44px;
    width: 57px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: 56px;
    bottom: 35px;
    width: 45px;
  }
`

const MoneyValue = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f4f3eb;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 29px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 18px;
  }
`

const Money = styled.div<{ isChange: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 31px;
  border: ${({ isChange }) =>
    isChange ? '1px solid #74E7BF' : '1px solid #f4f3eb'};
  border-radius: 3px;
  margin-bottom: 4px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    height: 26px;
    border-radius: 3px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 23px;
    border-radius: 2px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 17px;
    margin-bottom: 2px;
  }
  ${MoneyValue} {
    color: ${({ isChange }) => (isChange ? '#74E7BF' : '#f4f3eb')};
  }
`

const MoneyText = styled.div`
  font-family: 'Coders Crux';
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 132.3%;
  letter-spacing: -0.03em;
  text-align: center;
  text-transform: uppercase;
  color: #f4f3eb;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 10px;
  }
`

const Lives = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 9px;
  }
`

const LiveWrap = styled.div`
  position: relative;
  width: 18px;
  height: 15px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 15px;
    height: 12px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 14px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 12px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 10px;
    height: 8px;
  }
`

const fadeOut = keyframes`
  from {
    top: 0;
  }

  to {
    top: -30px;
  }
`

const Live = styled.div<{ noLive?: boolean; isActive?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ noLive, isActive }) => {
    if (!noLive && !isActive)
      return css`
        animation: ${fadeOut} 0.4s linear;
      `
  }}
  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    .border {
      fill: ${({ noLive }) => (noLive ? '#B8B8B8' : '#f4f3eb')};
    }
    .blick {
      fill: ${({ noLive }) => (noLive ? '#9B9B9B' : '#e7dcc0')};
    }
    .purple {
      fill: ${({ noLive }) => (noLive ? '#8A8A8A' : '#ff85fb')};
    }
  }
`

const Buttons = styled.div`
  position: absolute;
  right: 105px;
  bottom: 66px;
  width: 79px;
  height: 79px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    right: 83px;
    bottom: 55px;
    width: 66px;
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    right: 75px;
    bottom: 50px;
    width: 59px;
    height: 59px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    right: 67px;
    bottom: 44px;
    width: 53px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.md} {
    right: 53px;
    bottom: 35px;
    width: 42px;
    height: 42px;
  }
`

const ButtonsText = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 18px;
  line-height: 132.3%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f4f3eb;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 10px;
  }
`

const Button = styled.div`
  position: relative;
  width: 23px;
  height: 23px;
  background: url('${buttonUp}') center no-repeat;
  background-size: cover;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.xl} {
    width: 19px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 17px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 12px;
    height: 12px;
  }
`

const Mute = styled.div`
  position: absolute;
  left: -56px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  svg {
    display: block;
    max-width: 25px;
    height: 19px;
    fill: #f4f3eb;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      max-width: 21px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      max-width: 19px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      max-width: 17px;
      height: 12px;
    }
    ${({ theme }) => theme.adaptive.md} {
      max-width: 14px;
      height: 10px;
    }
  }
  &:hover {
    svg {
      fill: #ff85fb;
    }
  }
`

const ButtonRow = styled.div`
  position: relative;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  &:nth-child(2) {
    justify-content: space-between;
    ${Button} {
      &:nth-child(1) {
        transform: rotate(-90deg);
      }
      &:nth-child(2) {
        transform: rotate(180deg);
      }
      &:nth-child(3) {
        transform: rotate(90deg);
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const GameOver = styled.div<{ isOver: boolean }>`
  position: absolute;
  width: 422px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 245px;
  font-family: 'Coders Crux';
  font-style: normal;
  font-weight: 400;
  font-size: 145px;
  line-height: 70%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f26273;
  text-shadow: 6px 6px 0px #232020;
  opacity: ${({ isOver }) => (isOver ? 1 : 0)};
  transition: 0.4s;
`

const GameOverBg = styled.div<{ isOver: boolean }>`
  position: absolute;
  width: 100%;
  height: 400px;
  bottom: 0;
  background: linear-gradient(180deg, #f26273 0%, rgba(242, 98, 115, 0) 100%);
  transition: 0.4s;
  opacity: ${({ isOver }) => (isOver ? 0.2 : 0)};
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: 2;
`

const Controls = styled.div<{ isStart: boolean }>`
  position: absolute;
  bottom: 159px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  transition: 0.4s;
  opacity: ${({ isStart }) => (isStart ? 0 : 1)};
  pointer-events: ${({ isStart }) => (isStart ? 'none' : 'auto')};
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    bottom: 133px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: 118px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: 105px;
  }
  ${({ theme }) => theme.adaptive.md} {
    bottom: 85px;
  }
`

const BtnText = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 18px;
  line-height: 132%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f4f3eb;
  margin-top: 7px;
  text-align: center;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 15px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 14px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 13px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 10px;
    margin-top: 4px;
  }
`

const Btn = styled.div`
  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    border: 2px solid #f4f3eb;
    border-radius: 7px;
    z-index: 2;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      width: 68px;
      height: 68px;
      border-radius: 6px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 62px;
      height: 62px;
      border-radius: 5px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 55px;
      height: 55px;
      border-radius: 5px;
      border: 1px solid #f4f3eb;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 44px;
      height: 44px;
      border-radius: 4px;
    }
    svg {
      display: block;
      width: 30px;
      height: 45px;
      fill: #f4f3eb;
      transition: 0.4s;
      ${({ theme }) => theme.adaptive.xl} {
        width: 25px;
        height: 38px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 23px;
        height: 34px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        width: 20px;
        height: 30px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 16px;
        height: 24px;
      }
    }
  }
`

const BtnWrap = styled.div`
  margin: 0 12px;
  ${({ theme }) => theme.adaptive.xl} {
    margin: 0 10px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin: 0 9px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin: 0 8px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin: 0 6px;
  }
  &:nth-child(2) {
    ${BtnText} {
      color: #ff85fb;
    }
    ${Btn} {
      a,
      button {
        border: 2px solid #ff85fb;
        ${({ theme }) => theme.adaptive.slg} {
          border: 1px solid #ff85fb;
        }
        svg {
          width: 27px;
          height: 35px;
          fill: #ff85fb;
          ${({ theme }) => theme.adaptive.xl} {
            width: 23px;
            height: 29px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            width: 20px;
            height: 26px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            width: 18px;
            height: 23px;
          }
          ${({ theme }) => theme.adaptive.md} {
            width: 15px;
            height: 19px;
          }
        }
      }
    }
  }
  &:hover {
    ${BtnText} {
      color: #74e7bf;
    }
    ${Btn} {
      a,
      button {
        border: 2px solid #74e7bf;
        ${({ theme }) => theme.adaptive.slg} {
          border: 1px solid #74e7bf;
        }
        svg {
          fill: #74e7bf;
        }
      }
    }
  }
`

const GameBorder = styled.div<{ isGame: boolean }>`
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 12px;
  background: url('${borderImg}') repeat;
  background-size: cover;
  opacity: ${({ isGame }) => (isGame ? 1 : 1)};
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    top: 83px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 75px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 67px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 53px;
  }
`
