import { getPostOptions } from './options'
import { API_URL } from 'config/app'
import { SendParams } from 'types/sendTypes'

export const sendNickname = async ({ name, score }: SendParams) => {
  const response = await fetch(
    `${API_URL}/add-player`,
    getPostOptions({ name, score })
  );
  return response.json();
};
