import { Player } from './Player'
import RUNNING0 from 'img/game/player/RUNNING-0.svg'
import RUNNING1 from 'img/game/player/RUNNING-1.svg'
import RUNNING2 from 'img/game/player/RUNNING-2.svg'
import RUNNING3 from 'img/game/player/RUNNING-3.svg'
import RUNNING4 from 'img/game/player/RUNNING-4.svg'
import COLLISION0 from 'img/game/player/COLLISION-0.svg'
import COLLISION1 from 'img/game/player/COLLISION-1.svg'
import COLLISION2 from 'img/game/player/COLLISION-1.svg'
import COLLISION3 from 'img/game/player/COLLISION-3.svg'
import EATING0 from 'img/game/player/EATING-0.svg'
import EATING1 from 'img/game/player/EATING-1.svg'
import EATING2 from 'img/game/player/EATING-2.svg'
import HAPPY0 from 'img/game/player/HAPPY-0.svg'
import HAPPY1 from 'img/game/player/HAPPY-0.svg'
import HAPPY2 from 'img/game/player/HAPPY-2.svg'
import VOMIT0 from 'img/game/player/VOMMIT-0.svg'
import VOMIT1 from 'img/game/player/VOMMIT-0.svg'
import VOMIT2 from 'img/game/player/VOMMIT-2.svg'

export const states = {
  RUNNING: 0,
  COLLISION: 1,
  EATING: 2,
  HAPPY: 3,
  TRASHEATING: 4,
  VOMIT: 5,
}

export const statesList = [
  {
    name: 'RUNNING',
    frames: 5,
    images: [RUNNING0, RUNNING1, RUNNING2, RUNNING3, RUNNING4],
  },
  {
    name: 'COLLISION',
    frames: 4,
    images: [COLLISION0, COLLISION1, COLLISION2, COLLISION3],
  },
  {
    name: 'EATING',
    frames: 3,
    images: [EATING0, EATING1, EATING2],
  },
  {
    name: 'HAPPY',
    frames: 3,
    images: [HAPPY0, HAPPY1, HAPPY2],
  },
  {
    name: 'TRASHEATING',
    frames: 3,
    images: [EATING0, EATING1, EATING2],
  },
  {
    name: 'VOMIT',
    frames: 3,
    images: [VOMIT0, VOMIT1, VOMIT2],
  },
]

class State {
  private state: any
  constructor(state: any) {
    this.state = state
  }
}

export class Running extends State {
  private player: Player
  constructor(player: Player) {
    super('RUNNING')
    this.player = player
  }
  enter = () => {
    this.player.frameX = 0
    this.player.frameY = 0
    this.player.maxFrame = 4
  }
}

export class Collision extends State {
  private player: Player
  constructor(player: Player) {
    super('COLLISION')
    this.player = player
  }
  enter = () => {
    this.player.frameX = 0
    this.player.frameY = 4
    this.player.maxFrame = 3
  }
}

export class Eating extends State {
  private player: Player
  constructor(player: Player) {
    super('EATING')
    this.player = player
  }
  enter = () => {
    this.player.frameX = 0
    this.player.frameY = 1
    this.player.maxFrame = 2
  }
}

export class Happy extends State {
  private player: Player
  constructor(player: Player) {
    super('HAPPY')
    this.player = player
  }
  enter = () => {
    this.player.frameX = 0
    this.player.frameY = 2
    this.player.maxFrame = 2
  }
}

export class TrashEating extends State {
  private player: Player
  constructor(player: Player) {
    super('TRASHEATING')
    this.player = player
  }
  enter = () => {
    this.player.frameX = 0
    this.player.frameY = 1
    this.player.maxFrame = 2
  }
}

export class Vomit extends State {
  private player: Player
  constructor(player: Player) {
    super('VOMIT')
    this.player = player
  }
  enter = () => {
    this.player.frameX = 0
    this.player.frameY = 3
    this.player.maxFrame = 2
  }
}
