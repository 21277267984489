import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import iconImg from 'img/index/incubator/icon.svg'
import bgImg from 'img/index/incubator/bg.png'
import fishBg from 'img/index/incubator/fish-bg.png'
import knightImg from 'img/index/incubator/knight.png'
import waleImg from 'img/index/incubator/wale.png'
import daoShadow from 'img/index/incubator/dao-shadow.svg'
import daoImg from 'img/index/incubator/dao-img.png'
import daoInfoImg from 'img/index/incubator/dao-info-img.svg'
import dexImg from 'img/index/incubator/dex-img.png'
import dexShadow from 'img/index/incubator/dex-shadow.png'
import dexInfoImg from 'img/index/incubator/dex-info-img.svg'
import padShadow from 'img/index/incubator/pad-shadow.png'
import padImg from 'img/index/incubator/pad-img.png'
import padInfoImg from 'img/index/incubator/pad-info-img.svg'
import incubatorShadow from 'img/index/incubator/incubator-shadow.png'
import incubatorImg from 'img/index/incubator/incubator-img.png'
import incubatorInfoImg from 'img/index/incubator/incubator-info-img.svg'
import shipImg from 'img/index/incubator/ship-img.svg'
import bigWaleImg from 'img/index/incubator/big-wale.png'

function Incubator() {
  return (
    <Root id={'incubator'}>
      <Container>
        <Row>
          <TitleWrap>
            <Fishes />
            <Knight />
            <Wale />
            <Title>
              DAO-governed
              <span>incubator</span>
            </Title>
            <SubTitle>merged with an innovative DeFi layer.</SubTitle>
            <About>
              <Icon />
              <Text>
                <span>
                  {' '}
                  NEARStarter is a high tech community-owned ecosystem.{' '}
                </span>
                Expressly built for incubating, accelerating, and funding
                state-of-the-art projects deploying on the NEAR Protocol.
              </Text>
            </About>
          </TitleWrap>
        </Row>

        <List>
          <DAO>
            <ItemTitle>
              <span>NS</span>DAO
            </ItemTitle>
            <DaoShadow />
            <DaoImg />
            <ItemInfo>
              <InfoImg src={daoInfoImg} />
              <Border />
              <div>
                <InfoTitle>
                  <span>NS</span>DAO
                </InfoTitle>
                <InfoText>
                  DAO-governed accelerator designed to have decentralized
                  project access controls and a price-discovery engine for the
                  early-stage funding round.
                </InfoText>
              </div>
            </ItemInfo>
          </DAO>
          <DEX>
            <ItemTitle>DEX</ItemTitle>
            <DexShadow />
            <DexImg />
            <ItemInfo>
              <InfoImg src={dexInfoImg} />
              <Border />
              <div>
                <InfoTitle>DEX</InfoTitle>
                <InfoText>
                  A robust, built-in trading orderbook based platform that
                  provides a seamless transition to secondary markets for
                  recently launched projects.
                </InfoText>
              </div>
            </ItemInfo>
          </DEX>
          <PAD>
            <ItemTitle>
              <span>NS</span>PAD
            </ItemTitle>
            <PadShadow />
            <PadImg />
            <ItemInfo>
              <InfoImg src={padInfoImg} />
              <Border />
              <div>
                <InfoTitle>
                  <span>NS</span>PAD
                </InfoTitle>
                <InfoText>
                  Launchpad augmented with algorithmic tiers, deflationary
                  tokenomics and community-centric automated diversification
                  engine.
                </InfoText>
              </div>
            </ItemInfo>
          </PAD>
          <INCUBATOR>
            <ItemTitle>Incubator</ItemTitle>
            <IncubatorShadow />
            <IncubatorImg />
            <ItemInfo>
              <InfoImg src={incubatorInfoImg} />
              <Border />
              <div>
                <InfoTitle>Incubator</InfoTitle>
                <InfoText>
                  We onboard projects at every stage of development and deploy
                  our extensive network, carrying the projects all the way from
                  concept formulation to MVP and successful funding rounds.
                </InfoText>
              </div>
            </ItemInfo>
          </INCUBATOR>
          <Ship />
          <BigWale />
        </List>
      </Container>
    </Root>
  )
}

export default Incubator

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 116px;
  border-top: 1px solid rgba(9, 9, 9, 0.22);
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 105px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 87px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 78px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 62px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TitleWrap = styled.div`
  position: relative;
  width: 877px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 730px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 655px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 581px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 465px;
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 116px;
  line-height: 90%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #090909;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 96px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 86px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 76px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 61px;
  }
  span {
    color: #f349b5;
    margin-left: 15px;
    ${({ theme }) => theme.adaptive.md} {
      margin-left: 11px;
    }
  }
`

const SubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 67px;
  line-height: 90%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #090909;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 56px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 50px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 44px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 35px;
  }
`

const About = styled.div`
  display: flex;
  margin-top: 20px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 12px;
  }
`

const Icon = styled.div`
  width: 26px;
  height: 21px;
  background: url('${iconImg}') center no-repeat;
  background-size: cover;
  margin-right: 19px;
  margin-top: 7px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 22px;
    height: 17px;
    margin-right: 15px;
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 20px;
    height: 16px;
    margin-right: 13px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 17px;
    height: 14px;
    margin-right: 12px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 14px;
    height: 11px;
    margin-right: 9px;
    margin-top: 3px;
  }
`

const Text = styled.div`
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 30px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #090909;
  width: 803px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 25px;
    width: 669px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 23px;
    width: 604px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 20px;
    width: 537px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 16px;
    width: 430px;
  }
  span {
    font-weight: 600;
  }
`

const List = styled.div`
  position: relative;
  margin-top: 81px;
  height: 415px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 76px;
    height: 336px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 67px;
    height: 302px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 61px;
    height: 269px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 48px;
    height: 215px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1978px;
    height: 415px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    background: url('${bgImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      width: 1648px;
      height: 336px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 1484px;
      height: 311px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 1318px;
      height: 277px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 1055px;
      height: 221px;
    }
  }
`

const Fishes = styled.div`
  position: absolute;
  width: 2474px;
  height: 559px;
  left: -1386px;
  top: -126px;
  background: url('${fishBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 2062px;
    height: 466px;
    left: -1155px;
    top: -105px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 1856px;
    height: 419px;
    left: -1039px;
    top: -95px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 1650px;
    height: 372px;
    left: -924px;
    top: -84px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 1320px;
    height: 298px;
    left: -739px;
    top: -67px;
  }
`

const Knight = styled.div`
  position: absolute;
  top: 11px;
  left: -587px;
  width: 704px;
  height: 647px;
  background: url('${knightImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 9px;
    left: -489px;
    width: 586px;
    height: 539px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 8px;
    left: -440px;
    width: 528px;
    height: 486px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 7px;
    left: -391px;
    width: 469px;
    height: 431px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 6px;
    left: -313px;
    width: 376px;
    height: 345px;
  }
`

const Wale = styled.div`
  position: absolute;
  left: -840px;
  top: -20px;
  width: 113px;
  height: 118px;
  background: url('${waleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    left: -700px;
    top: -17px;
    width: 95px;
    height: 99px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: -630px;
    top: -7px;
    width: 85px;
    height: 89px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: -560px;
    top: -14px;
    width: 76px;
    height: 79px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: -448px;
    top: -12px;
    width: 61px;
    height: 64px;
  }
`

const ItemInfo = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 548px;
  height: 110px;
  background: #e5e4dd;
  box-shadow: 3px 3px 0px #b9b8b4;
  border-radius: 4px;
  padding: 0 11px;
  opacity: 0;
  transition: 0.4s;
  top: -35px;
  left: 40px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 456px;
    height: 92px;
    box-shadow: 3px 3px 0px #b9b8b4;
    border-radius: 3px;
    padding: 0 9px;
    top: -40px;
    left: 33px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 416px;
    height: 85px;
    box-shadow: 2px 2px 0px #b9b8b4;
    border-radius: 3px;
    padding: 0 8px;
    top: -30px;
    left: 25px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 380px;
    height: 76px;
    box-shadow: 2px 2px 0px #b9b8b4;
    border-radius: 3px;
    padding: 0 7px;
    top: -25px;
    left: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 312px;
    height: auto;
    box-shadow: 2px 2px 0px #b9b8b4;
    border-radius: 2px;
    padding: 7px 6px 13px;
    top: -20px;
    left: 15px;
    align-items: flex-start;
  }
  &:hover {
    box-shadow: 6px 6px 0px rgba(74, 74, 71, 0.7);
    ${({ theme }) => theme.adaptive.lg} {
      box-shadow: 4px 4px 0px rgba(74, 74, 71, 0.7);
    }
  }
`

const InfoImg = styled.img`
  display: block;
  width: 90px;
  height: 90px;
  margin-right: 16px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 75px;
    height: 75px;
    margin-right: 13px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 68px;
    height: 68px;
    margin-right: 12px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
`

const Border = styled.div`
  width: 1px;
  height: 77px;
  opacity: 0.1;
  background: #000000;
  margin-right: 22px;
  ${({ theme }) => theme.adaptive.xl} {
    height: 64px;
    margin-right: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 58px;
    margin-right: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 51px;
    margin-right: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 64px;
    margin-right: 12px;
    margin-top: 3px;
  }
`

const InfoTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 27px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 17px;
  }
  span {
    color: #8c8c8c;
  }
`

const InfoText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 10px;
  }
`

const ItemTitle = styled.div`
  font-family: 'Coders Crux';
  position: absolute;
  font-weight: 400;
  font-size: 46px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #090909;
  transition: 0.2s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 38px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 24px;
  }
  span {
    color: #8c8c8c;
  }
`

const ShadowCss = css`
  bottom: -40px;
  left: 100px;
  opacity: 0;
  transform: scale(0.7);
  ${({ theme }) => theme.adaptive.xl} {
    bottom: -35px;
    left: 80px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: -30px;
    left: 70px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: -25px;
    left: 60px;
  }
  ${({ theme }) => theme.adaptive.md} {
    bottom: -20px;
    left: 50px;
  }
`

const DaoShadow = styled.div`
  position: absolute;
  bottom: 10px;
  left: 56px;
  width: 97px;
  height: 51px;
  background: url('${daoShadow}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    bottom: 9px;
    left: 46px;
    width: 81px;
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: 8px;
    left: 42px;
    width: 73px;
    height: 39px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: 7px;
    left: 37px;
    width: 65px;
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.md} {
    bottom: 6px;
    left: 30px;
    width: 52px;
    height: 27px;
  }
`

const DaoImg = styled.div`
  position: absolute;
  width: 120px;
  height: 97px;
  top: 96px;
  left: 40px;
  background: url('${daoImg}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 100px;
    height: 81px;
    top: 80px;
    left: 33px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 90px;
    height: 74px;
    top: 72px;
    left: 28px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 81px;
    height: 65px;
    top: 64px;
    left: 25px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 64px;
    height: 52px;
    top: 51px;
    left: 21px;
  }
`

const DAO = styled.div`
  position: absolute;
  top: 134px;
  left: 75px;
  width: 214px;
  height: 240px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 112px;
    left: 61px;
    width: 179px;
    height: 200px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 101px;
    left: 56px;
    width: 161px;
    height: 180px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 89px;
    left: 50px;
    width: 143px;
    height: 160px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 71px;
    left: 38px;
    width: 114px;
    height: 128px;
  }
  ${ItemTitle} {
    top: 68px;
    left: 60px;
    ${({ theme }) => theme.adaptive.xl} {
      top: 56px;
      left: 50px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 51px;
      left: 45px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 45px;
      left: 40px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 36px;
      left: 32px;
    }
  }
  &:hover {
    z-index: 2;
    ${DaoImg} {
      top: 0;
      opacity: 0;
    }
    ${DaoShadow} {
      ${ShadowCss};
    }
    ${ItemTitle} {
      opacity: 0;
    }
    ${ItemInfo} {
      opacity: 1;
    }
  }
`

const DexImg = styled.div`
  position: absolute;
  width: 113px;
  height: 66px;
  top: 101px;
  left: 43px;
  background: url('${dexImg}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 95px;
    height: 55px;
    top: 84px;
    left: 38px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 84px;
    height: 50px;
    top: 75px;
    left: 36px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 75px;
    height: 45px;
    top: 67px;
    left: 33px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 61px;
    height: 36px;
    top: 54px;
    left: 23px;
  }
`

const DexShadow = styled.div`
  position: absolute;
  bottom: 15px;
  left: 48px;
  width: 109px;
  height: 30px;
  background: url('${dexShadow}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    bottom: 12px;
    left: 40px;
    width: 91px;
    height: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: 11px;
    left: 36px;
    width: 82px;
    height: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: 10px;
    left: 32px;
    width: 73px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    bottom: 6px;
    left: 25px;
    width: 58px;
    height: 16px;
  }
`

const DEX = styled.div`
  position: absolute;
  top: 75px;
  left: 407px;
  width: 202px;
  height: 209px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 62px;
    left: 338px;
    width: 168px;
    height: 175px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 56px;
    left: 305px;
    width: 151px;
    height: 157px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 50px;
    left: 271px;
    width: 134px;
    height: 140px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 40px;
    left: 216px;
    width: 107px;
    height: 112px;
  }
  ${ItemTitle} {
    top: 61px;
    left: 76px;
    ${({ theme }) => theme.adaptive.xl} {
      top: 51px;
      left: 63px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 46px;
      left: 57px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 41px;
      left: 51px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 33px;
      left: 41px;
    }
  }
  &:hover {
    z-index: 2;
    ${DexImg} {
      top: 0;
      opacity: 0;
    }
    ${DexShadow} {
      ${ShadowCss};
    }
    ${ItemTitle} {
      opacity: 0;
    }
    ${ItemInfo} {
      opacity: 1;
    }
  }
`

const PadImg = styled.div`
  position: absolute;
  width: 114px;
  height: 94px;
  top: 93px;
  left: 40px;
  background: url('${padImg}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 95px;
    height: 78px;
    top: 77px;
    left: 36px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 86px;
    height: 71px;
    top: 69px;
    left: 31px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 76px;
    height: 63px;
    top: 62px;
    left: 28px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 61px;
    height: 50px;
    top: 49px;
    left: 21px;
  }
`

const PadShadow = styled.div`
  position: absolute;
  bottom: 0px;
  left: 61px;
  width: 99px;
  height: 50px;
  background: url('${padShadow}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    left: 51px;
    width: 83px;
    height: 42px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: 46px;
    width: 75px;
    height: 37px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: 41px;
    width: 67px;
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: 33px;
    width: 53px;
    height: 28px;
  }
`

const PAD = styled.div`
  position: absolute;
  top: 3px;
  left: 818px;
  width: 222px;
  height: 220px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 2px;
    left: 680px;
    width: 185px;
    height: 183px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 2px;
    left: 614px;
    width: 166px;
    height: 165px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 2px;
    left: 545px;
    width: 148px;
    height: 146px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 1px;
    left: 435px;
    width: 118px;
    height: 117px;
  }
  ${ItemTitle} {
    top: 71px;
    left: 59px;
    ${({ theme }) => theme.adaptive.xl} {
      top: 59px;
      left: 49px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 53px;
      left: 44px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 48px;
      left: 39px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 38px;
      left: 31px;
    }
  }
  &:hover {
    z-index: 2;
    ${PadImg} {
      top: 0;
      opacity: 0;
    }
    ${PadShadow} {
      ${ShadowCss};
    }
    ${ItemTitle} {
      opacity: 0;
    }
    ${ItemInfo} {
      opacity: 1;
    }
  }
`

const IncubatorImg = styled.div`
  position: absolute;
  width: 132px;
  height: 87px;
  top: 120px;
  left: 52px;
  background: url('${incubatorImg}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 110px;
    height: 73px;
    top: 95px;
    left: 35px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 99px;
    height: 65px;
    top: 90px;
    left: 39px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 89px;
    height: 58px;
    top: 80px;
    left: 35px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 71px;
    height: 46px;
    top: 64px;
    left: 28px;
  }
`

const IncubatorShadow = styled.div`
  position: absolute;
  bottom: -13px;
  left: 50px;
  width: 121px;
  height: 83px;
  background: url('${incubatorShadow}') center no-repeat;
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    bottom: -7px;
    left: 38px;
    width: 102px;
    height: 70px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: -9px;
    left: 43px;
    width: 92px;
    height: 63px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: -10px;
    left: 38px;
    width: 81px;
    height: 56px;
  }
  ${({ theme }) => theme.adaptive.md} {
    bottom: -8px;
    left: 31px;
    width: 65px;
    height: 46px;
  }
`

const INCUBATOR = styled.div`
  position: absolute;
  top: 89px;
  left: 1217px;
  width: 228px;
  height: 235px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 74px;
    left: 930px;
    width: 190px;
    height: 196px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 67px;
    left: 838px;
    width: 171px;
    height: 176px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 60px;
    left: 744px;
    width: 152px;
    height: 156px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 48px;
    left: 595px;
    width: 122px;
    height: 125px;
  }

  ${ItemTitle} {
    top: 84px;
    left: 45px;
    ${({ theme }) => theme.adaptive.xl} {
      top: 70px;
      left: 38px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 63px;
      left: 34px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 56px;
      left: 30px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 45px;
      left: 24px;
    }
  }
  &:hover {
    z-index: 2;
    ${IncubatorImg} {
      top: 0;
      opacity: 0;
    }
    ${IncubatorShadow} {
      ${ShadowCss};
    }
    ${ItemTitle} {
      opacity: 0;
    }
    ${ItemInfo} {
      opacity: 1;
    }
  }
`

const Ship = styled.div`
  position: absolute;
  top: 313px;
  right: 28px;
  width: 82px;
  height: 48px;
  background: url('${shipImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 260px;
    right: 23px;
    width: 68px;
    height: 40px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 238px;
    right: 21px;
    width: 61px;
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 211px;
    right: 19px;
    width: 54px;
    height: 32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 169px;
    right: 13px;
    width: 44px;
    height: 25px;
  }
`

const BigWale = styled.div`
  position: absolute;
  top: 77px;
  left: -60px;
  width: 254px;
  height: 147px;
  background: url('${bigWaleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 60px;
    left: -50px;
    width: 212px;
    height: 123px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 58px;
    left: -45px;
    width: 191px;
    height: 110px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 51px;
    left: -40px;
    width: 169px;
    height: 98px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 41px;
    left: -34px;
    width: 135px;
    height: 78px;
  }
`
