export class Buffer {
  private urls: any
  buffer: never[]
  private context: any
  constructor(context: any, urls: any) {
    this.context = context
    this.urls = urls
    this.buffer = []
  }

  loadSound(url: string | URL, index: number) {
    let request = new XMLHttpRequest()
    request.open('get', url, true)
    request.responseType = 'arraybuffer'
    let thisBuffer = this
    request.onload = function () {
      thisBuffer.context.decodeAudioData(
        request.response,
        function (buffer: AudioBuffer) {
          // @ts-ignore
          thisBuffer.buffer[index] = buffer
          if (index === thisBuffer.urls.length - 1) {
            thisBuffer.loaded()
          }
        }
      )
    }
    request.send()
  }

  loadAll() {
    this.urls.forEach((url: string | URL, index: number) => {
      this.loadSound(url, index)
    })
  }

  loaded() {
    // what happens when all the files are loaded
  }

  getSoundByIndex(index: number) {
    return this.buffer[index]
  }
}
