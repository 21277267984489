import { Game } from './Game'

export class Ground {
  private game: Game
  private width: number
  height: number
  private x: number
  private y: number

  constructor(game: Game) {
    this.game = game
    this.width = this.game.width
    this.height = 50 * this.game.ratio
    this.x = 0
    this.y = this.game.height - this.height
  }

  update(deltaTime: number) {
    if (this.x < -this.width) this.x = 0
    else this.x -= this.game.groundSpeed * deltaTime
  }

  draw(context: CanvasRenderingContext2D) {
    context.fillStyle = '#F4F3EB'
    context.beginPath()
    context.rect(this.x, this.y, this.width, this.height)
    context.fill()
    context.rect(this.x + this.width, this.y, this.width, this.height)
    context.fill()
    context.closePath()
  }
}
