export const API_URL = process.env.REACT_APP_API_URL
export const API_BASIC_AUTH_LOGIN = process.env.REACT_APP_AUTH_LOGIN
export const API_BASIC_AUTH_PASS = process.env.REACT_APP_AUTH_PASS

export const DECK_LINK = 'https://docsend.com/view/2snj5japeg2xhthb'
export const INCUBATOR_DECK_LINK = 'https://docsend.com/view/c6f2eh5ui26jqw9v'
export const DISCORD_LINK = 'https://discord.gg/CayJU22phq'
export const MEDIUM_LINK = 'https://medium.com/near-starter'
export const TWITTER_LINK = 'https://twitter.com/NEARStarter_fi'
export const TELEGRAM_LINK = 'https://t.me/NEARStarterCommunity'
export const APPLY_FOR_IDO_LINK = 'https://forms.gle/6KZL8L83x1CQvyVQ9'
export const CONTACT_LINK = 'mailto:contact@nearstarter.fi'
export const DEX_LINK = 'https://dex.nearstarter.fi/'
export const FAQ_LINK = 'https://hackmd.io/@NEARStarter/HJUVLyJ3q'
export const APP_LINK = 'https://app.nearstarter.fi/'
