import { Game } from './Game'
import { Player } from './Player'
import { detectRectangleCollision } from './ColisionDetection'
import { states } from './playerState'

export class Live {
  private game: Game
  private width: number
  private height: number
  public x: number
  public y: number
  markedForDelete: boolean
  private img: any
  private isColision: boolean
  constructor(game: Game, img: any) {
    this.game = game
    this.width = 48 * this.game.ratio
    this.height = 48 * this.game.ratio
    this.x = this.game.width + 100 * this.game.ratio
    this.y =
      this.game.maxHeight +
      Math.random() * (this.game.height - this.game.maxHeight) * 0.7
    // this.speedX = this.game.RandomIntInRange(1, 3) / 10
    this.markedForDelete = false
    this.img = img
    this.isColision = false
  }
  update(deltaTime: number, player: Player) {
    this.x -= this.game.groundSpeed * deltaTime
    if (this.x + this.width < 0) this.markedForDelete = true

    if (!this.isColision) {
      let playerRect = {
        x: player.hitX,
        width: player.width,
        y: player.hitY,
        height: player.height,
        currRotation: player.currRotation,
      }
      let liveRect = {
        x: this.x,
        width: this.width,
        y: this.y,
        height: this.height,
        currRotation: 0,
      }
      let areaRect = {
        x: this.x - 20,
        width: this.width + 40,
        y: this.y - 20,
        height: this.height + 40,
        currRotation: 0,
      }
      if (detectRectangleCollision(liveRect, playerRect)) {
        this.isColision = true
      }
      if (detectRectangleCollision(areaRect, playerRect)) {
        player.setState(states.EATING)
      }
    } else {
      let centerY = this.y + this.height / 2
      if (centerY !== player.mouthY) {
        if (centerY < player.mouthY) {
          this.y += this.game.groundSpeed * deltaTime * 3
        } else {
          this.y -= this.game.groundSpeed * deltaTime * 3
        }
      }
      if (this.x < player.x + player.width * 0.7) {
        this.game.heartColisionsDetect()
        this.markedForDelete = true
        player.setState(states.HAPPY)
      }
      if (player.x + player.width < this.x) {
        this.isColision = false
      }
    }
  }

  draw(context: CanvasRenderingContext2D) {
    context.save()
    // context.fillStyle = 'red'
    // context.rect(this.x, this.y, this.width, this.height)
    // context.fill()
    context.drawImage(this.img, this.x, this.y, this.width, this.height)
    context.restore()
  }
}
