import { createGlobalStyle } from 'styled-components'
import CodersCruxRegularWoff from './fonts/CodersCruxRegular.woff'
import CodersCruxRegularWoff2 from './fonts/CodersCruxRegular.woff2'
import ManropeRegularWoff from './fonts/ManropeRegular.woff'
import ManropeRegularWoff2 from './fonts/ManropeRegular.woff2'
import ManropeLightWoff from './fonts/ManropeLight.woff'
import ManropeLightWoff2 from './fonts/ManropeLight.woff2'
import ManropeExtraLightWoff from './fonts/ManropeExtraLight.woff'
import ManropeExtraLightWoff2 from './fonts/ManropeExtraLight.woff2'
import ManropeBoldWoff from './fonts/ManropeBold.woff'
import ManropeBoldWoff2 from './fonts/ManropeBold.woff2'
import MMCWoff from './fonts/MMC_Regular.woff'
import MMCWoff2 from './fonts/MMC_Regular.woff2'
import PressStart2PWoff from './fonts/PressStart2P.woff'
import PressStart2PWoff2 from './fonts/PressStart2P.woff2'

const MMC = {
  regular: {
    woff2: MMCWoff2,
    woff: MMCWoff,
  },
}

const PressStart2P = {
  regular: {
    woff2: PressStart2PWoff2,
    woff: PressStart2PWoff,
  },
}

const CodersCrux = {
  regular: {
    woff: CodersCruxRegularWoff,
    woff2: CodersCruxRegularWoff2,
  },
}

const Manrope = {
  light: {
    woff: ManropeLightWoff,
    woff2: ManropeLightWoff2,
  },
  extraLight: {
    woff: ManropeExtraLightWoff,
    woff2: ManropeExtraLightWoff2,
  },
  regular: {
    woff: ManropeRegularWoff,
    woff2: ManropeRegularWoff2,
  },
  bold: {
    woff: ManropeBoldWoff,
    woff2: ManropeBoldWoff2,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Coders Crux';
    src: local('Coders Crux'),
    url(${CodersCrux.regular.woff2}) format('woff2'),
    url(${CodersCrux.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
    url(${Manrope.regular.woff2}) format('woff2'),
    url(${Manrope.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
    url(${Manrope.bold.woff2}) format('woff2'),
    url(${Manrope.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
    url(${Manrope.light.woff2}) format('woff2'),
    url(${Manrope.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
    url(${Manrope.extraLight.woff2}) format('woff2'),
    url(${Manrope.extraLight.woff}) format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'MMC';
    src: local('MMC'),
    url(${MMC.regular.woff2}) format('woff2'),
    url(${MMC.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'PressStart2P';
    src: local('PressStart2P'),
    url(${PressStart2P.regular.woff2}) format('woff2'),
    url(${PressStart2P.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Fonts
