import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import nearLogo from 'img/header/logo.svg'
import gsap from 'gsap'
import NLetter from 'img/header/letters/N.svg'
import ELetter from 'img/header/letters/E.svg'
import ALetter from 'img/header/letters/A.svg'
import RLetter from 'img/header/letters/R.svg'
import ShipImg from 'img/header/icons/ship.svg'
import ArrowImg from 'img/header/icons/arrow.svg'
import XImg from 'img/header/icons/X.svg'
import FireImg from 'img/header/icons/fire.svg'

interface Props {
  isOpen: boolean
}

function Loader(props: Props) {
  const [isOpen, setIsOpen] = useState(props.isOpen)
  const LetterRef = useRef([])
  const IconRef = useRef([])
  const GreenRef = useRef(null)
  const PurpleRef = useRef(null)
  const FireRef = useRef(null)
  const rootRef = useRef(null)
  const [isEnd, setIsEnd] = useState(false)
  LetterRef.current = []
  IconRef.current = []

  useEffect(() => {
    if (LetterRef.current) {
      let delay = 0
      let duration = 0.2
      LetterRef.current.forEach((el, idx) => {
        delay += 0.4
        FadeIn(el, delay, duration, idx === 3)
      })
    }
    if (IconRef.current) {
      let delay = 0
      let duration = 1.6 / 6
      IconRef.current.forEach((el, idx) => {
        delay += 1.6 / 3
        FadeIn(el, delay, duration, idx === 2)
      })
    }
    if (GreenRef.current) {
      FadeOut(GreenRef.current, 0.2, 1.6)
    }
    if (PurpleRef.current) {
      MooveAnim(PurpleRef.current, 1.6)
    }
    if (FireRef.current && PurpleRef.current) {
      FireAnim(FireRef.current, 2, PurpleRef.current)
    }
  }, [])

  useEffect(() => {
    if (isEnd) {
      if (rootRef.current) {
        FadeOut(rootRef.current, 0.3, 0)
      }
    }
  }, [isEnd])

  const FadeIn = (
    el: gsap.TweenTarget,
    delay: number,
    duration: number,
    last: boolean
  ) => {
    gsap.to(el, {
      delay: delay,
      opacity: 1,
      duration: duration,
      onComplete: () => {
        if (!last) FadeOut(el, duration, 0)
      },
    })
  }

  const FadeOut = (el: gsap.TweenTarget, duration: number, delay: number) => {
    gsap.to(el, {
      delay: delay,
      opacity: 0,
      duration: duration,
    })
  }

  const MooveAnim = (el: gsap.TweenTarget, delay: number) => {
    gsap.to(el, {
      delay: delay,
      left: `-25%`,
      duration: 0.4,
    })
  }

  const FireAnim = (
    el: gsap.TweenTarget,
    delay: number,
    parent: gsap.TweenTarget
  ) => {
    gsap.to(el, {
      delay: delay,
      bottom: '-55%',
      opacity: 1,
      duration: 0.3,
      onComplete: () => {
        FLyAnim(parent, 0)
        setIsEnd(true)
      },
    })
  }

  const FLyAnim = (el: gsap.TweenTarget, delay: number) => {
    gsap.to(el, {
      delay: delay,
      y: '-300%',
      opacity: 0,
      duration: 0.3,
      onComplete: () => {
        setIsOpen(false)
      },
    })
  }

  const addToLettersRefs = (el: any) => {
    // @ts-ignore
    if (el && !LetterRef.current.includes(el)) {
      // @ts-ignore
      LetterRef.current.push(el)
    }
  }

  const addToIconsRefs = (el: any) => {
    // @ts-ignore
    if (el && !IconRef.current.includes(el)) {
      // @ts-ignore
      IconRef.current.push(el)
    }
  }

  return (
    <Root ref={rootRef} isOpen={isOpen}>
      <Squares>
        <GreenSquare ref={GreenRef}>
          <Letters ref={addToLettersRefs} src={NLetter} />
          <Letters ref={addToLettersRefs} src={ELetter} />
          <Letters ref={addToLettersRefs} src={ALetter} />
          <Letters ref={addToLettersRefs} src={RLetter} />
        </GreenSquare>
        <PurpleSquare ref={PurpleRef}>
          <X ref={addToIconsRefs} src={XImg} />
          <Arrow ref={addToIconsRefs} src={ArrowImg} />
          <Ship ref={addToIconsRefs} src={ShipImg} />
          <Fire ref={FireRef} src={FireImg} />
        </PurpleSquare>
      </Squares>
      <Build>
        <BuildText>built on and for</BuildText>
        <Logo />
      </Build>
    </Root>
  )
}

export default Loader

const Root = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #090909;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: none;
  transition: 0.4s;
`

const Squares = styled.div`
  display: flex;
  margin: 0 auto;
`

const SquareCss = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 151px;
  border-radius: 13px;
  margin: 0 11px;
`

const GreenSquare = styled.div`
  ${SquareCss};
  background: #74e7bf;
`

const PurpleSquare = styled.div`
  ${SquareCss};
  border: 8px solid #ff85fb;
  border-radius: 16px;
`

const Build = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 29px;
  left: 50%;
  transform: translateX(-50%);
`

const BuildText = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  text-transform: uppercase;
  color: #f4f3eb;
  margin-right: 11px;
  margin-top: 5px;
`

const Logo = styled.div`
  width: 100px;
  height: 26px;
  background: url('${nearLogo}') center no-repeat;
  background-size: cover;
`

const ImgCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  opacity: 0;
`

const Letters = styled.img`
  ${ImgCss};
  width: 84px;
  height: 84px;
`

const Ship = styled.img`
  ${ImgCss};
  width: 91px;
  height: 104px;
`

const Arrow = styled.img`
  ${ImgCss};
  width: 77px;
  height: 77px;
`

const X = styled.img`
  ${ImgCss};
  width: 91px;
  height: 78px;
`

const Fire = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 91px;
  height: 52px;
  opacity: 0;
`
