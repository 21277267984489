import React from 'react'
import styled from 'styled-components'
import daoImg from 'img/index/incubator/dao-info-img.svg'
import dexImg from 'img/index/incubator/dex-info-img.svg'
import padImg from 'img/index/incubator/pad-info-img.svg'
import incubatorImg from 'img/index/incubator/incubator-info-img.svg'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Telegram } from 'img/socials/telegram.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'
import { ReactComponent as Discord } from 'img/socials/discord.svg'
import nearLogo from 'img/footer/logo.svg'
import nearLogoSm from 'img/index/main/near-logo.svg'
import shipImg from 'img/contact/ship-img.png'
import waleImg from 'img/contact/wale-img.png'

import {
  APPLY_FOR_IDO_LINK,
  CONTACT_LINK,
  DECK_LINK,
  DEX_LINK,
  DISCORD_LINK,
  FAQ_LINK,
  MEDIUM_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from 'config/app'
import useWindowSize from 'helpers/utils/useWindowSize'
import { Container } from 'theme/theme'
import { Link } from 'react-router-dom'

function Footer() {
  const { width } = useWindowSize()

  return (
    <Root>
      <Line />
      <Border />
      <Container>
        <ItemsWrap>
          <Items>
            <Item>
              <Link to={'/dao'}>
                <ItemImg src={daoImg} />
                <ItemName>
                  <span>NS</span>DAO
                </ItemName>
              </Link>
            </Item>
            <Item>
              <Link to={'/pad'}>
                <ItemImg src={padImg} />
                <ItemName>
                  <span>NS</span>PAD
                </ItemName>
              </Link>
            </Item>
            <Item>
              <Link to={'/incubator'}>
                <ItemImg src={incubatorImg} />
                <ItemName>Incubator</ItemName>
              </Link>
            </Item>
            <Item>
              <a href={DEX_LINK} target={'_blank'}>
                <ItemImg src={dexImg} />
                <ItemName>DEX</ItemName>
              </a>
            </Item>
          </Items>
        </ItemsWrap>
        <BottomRow>
          <Nav>
            {/*<NavColumn>*/}
            {/*  <NavLink href={'/'} target={'_blank'}>*/}
            {/*    About us*/}
            {/*  </NavLink>*/}
            {/*  <NavLink href={'/'} target={'_blank'}>*/}
            {/*    Whitepaper*/}
            {/*  </NavLink>*/}
            {/*</NavColumn>*/}
            <NavColumn>
              <NavLink href={CONTACT_LINK} target={'_blank'}>
                Contact us
              </NavLink>
              <NavLink href={APPLY_FOR_IDO_LINK} target={'_blank'}>
                Apply for IDO
              </NavLink>
            </NavColumn>
            <NavColumn>
              <NavLink href={DECK_LINK} target={'_blank'}>
                Deck
              </NavLink>
              <NavLink href={FAQ_LINK} target={'_blank'}>
                FAQ
              </NavLink>
            </NavColumn>
          </Nav>
          <Socials>
            <SocialsColumn>
              <SocialItem href={TELEGRAM_LINK} target={'_blank'}>
                <Telegram />
                <SocialText>Telegram</SocialText>
              </SocialItem>
              <SocialItem href={MEDIUM_LINK} target={'_blank'}>
                <Medium />
                <SocialText>Medium</SocialText>
              </SocialItem>
            </SocialsColumn>
            <SocialsColumn>
              <SocialItem href={TWITTER_LINK} target={'_blank'}>
                <Twitter />
                <SocialText>Twitter</SocialText>
              </SocialItem>
              <SocialItem href={DISCORD_LINK} target={'_blank'}>
                <Discord />
                <SocialText>Discord</SocialText>
              </SocialItem>
            </SocialsColumn>
            {width < 1024 && (
              <MobDeckBtn href={DECK_LINK} target={'_blank'}>
                Deck
              </MobDeckBtn>
            )}
          </Socials>
          <BuildRow>
            <BuildText>built on and for</BuildText>
            <Logo />
          </BuildRow>
        </BottomRow>
        <Wale />
        <Ship />
      </Container>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #f4f3eb;
  ${({ theme }) => theme.adaptive.sm} {
    padding-bottom: 67px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-bottom: 74px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-bottom: 57px;
  }
`

const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 8px;
  background: #000000;
  opacity: 0.15;
  ${({ theme }) => theme.adaptive.xl} {
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 6px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 5px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 4px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    height: 6px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 4px;
  }
`

const Border = styled.div`
  position: absolute;
  top: 68px;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(9, 9, 9, 0.1);
  ${({ theme }) => theme.adaptive.xl} {
    top: 63px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 57px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 51px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 40px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 86px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 58px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 54px;
  }
`

const ItemsWrap = styled.div`
  height: 76px;
  ${({ theme }) => theme.adaptive.xl} {
    height: 64px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 57px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 50px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 40px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 52px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 50px;
  }
`

const Items = styled.div`
  position: absolute;
  top: -42px;
  left: 0;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.xl} {
    top: -35px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -31px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -28px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -22px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    justify-content: space-between;
    top: -44px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -24px;
  }
`

const ItemName = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #090909;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 29px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
  }
  span {
    color: #adadad;
    transition: 0.4s;
  }
`

const ItemImg = styled.img`
  display: block;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 58px;
    height: 58px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 53px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 47px;
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 37px;
    height: 37px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 80px;
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 54px;
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 44px;
    height: 44px;
  }
`

const Item = styled.div`
  a {
    cursor: pointer;
    display: block;
    margin-right: 45px;
    position: relative;
    z-index: 2;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      margin-right: 42px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      margin-right: 38px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      margin-right: 34px;
    }
    ${({ theme }) => theme.adaptive.md} {
      margin-right: 28px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      margin-right: 0;
    }
  }
  &:hover {
    ${ItemImg} {
      transform: scale(1.2);
    }
    ${ItemName} {
      span {
        color: #74e7bf;
      }
    }
  }
`

const BottomRow = styled.div`
  position: relative;
  display: flex;
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
  }
`

const Nav = styled.div`
  display: flex;
  padding: 28px 52px 28px 0;
  border-right: 1px solid rgba(9, 9, 9, 0.1);
  ${({ theme }) => theme.adaptive.xl} {
    padding: 23px 42px 23px 0;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 21px 37px 21px 0;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 19px 31px 19px 0;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 15px 24px 15px 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 36px 0 46px 0;
    border: 0px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 30px 0 35px 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 17px 0 23px 0;
  }
`

const NavColumn = styled.div`
  width: 140px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 127px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 105px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 93px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 75px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 33%;
  }
  &:last-child {
    width: auto;
  }
`

const NavLink = styled.a`
  font-family: 'MMC';
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #272a32;
  margin-bottom: 19px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 20px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 14px;
    line-height: 12px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 16px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: #ff65fa;
  }
`

const Socials = styled.div`
  position: relative;
  display: flex;
  padding: 28px 52px;
  border-right: 1px solid rgba(9, 9, 9, 0.1);
  ${({ theme }) => theme.adaptive.xl} {
    padding: 23px 43px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 21px 37px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 19px 31px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 15px 28px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 24px 0;
    justify-content: center;
    border: 0px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 21px 0;
  }
  &:before {
    ${({ theme }) => theme.adaptive.sm} {
      content: '';
      position: absolute;
      top: -1px;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      opacity: 0.1;
      background: #090909;
    }
  }
`

const SocialsColumn = styled.div`
  width: 140px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 127px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 115px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 102px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 82px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: auto;
    display: flex;
    align-items: center;
  }
  &:nth-child(2) {
    width: auto;
  }
`

const SocialText = styled.div`
  font-family: 'MMC';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #272a32;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 14px;
    line-height: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 13px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 12px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 10px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const SocialItem = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin: 0 35px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin: 0 15px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin: 0 15px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    display: block;
    max-width: 19px;
    max-height: 14px;
    margin-right: 14px;
    transition: 0.4s;
    fill: #272a32;
    ${({ theme }) => theme.adaptive.xl} {
      max-width: 16px;
      max-height: 12px;
      margin-right: 11px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      max-width: 14px;
      max-height: 11px;
      margin-right: 10px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      max-width: 13px;
      max-height: 9px;
      margin-right: 9px;
    }
    ${({ theme }) => theme.adaptive.md} {
      max-width: 10px;
      max-height: 7px;
      margin-right: 8px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      max-width: 30px;
      max-height: 22px;
      margin-right: 0;
    }
    ${({ theme }) => theme.adaptive.xs} {
      max-width: 23px;
      max-height: 17px;
    }
  }
  &:hover {
    ${SocialText} {
      color: #ff65fa;
    }
    svg {
      fill: #ff65fa;
    }
  }
`

const BuildRow = styled.div`
  position: absolute;
  top: 64px;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 3;
  ${({ theme }) => theme.adaptive.xl} {
    top: 53px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 48px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 43px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 34px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    top: 230px;
    padding: 23px;
    background: #090909;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 182px;
    padding: 27px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 144px;
    padding: 20px;
  }
`

const BuildText = styled.div`
  font-family: 'Coders Crux';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  text-transform: uppercase;
  color: #000000;
  margin-right: 11px;
  margin-top: 5px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 29px;
    margin-right: 11px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 26px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 23px;
    margin-right: 9px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 18px;
    margin-right: 4px;
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 28px;
    margin-right: 7px;
    color: #f4f3eb;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 22px;
    margin-right: 5px;
    margin-top: 4px;
  }
`

const Logo = styled.div`
  width: 100px;
  height: 26px;
  background: url('${nearLogo}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    width: 83px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 75px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 67px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 53px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 81px;
    height: 21px;
    background: url('${nearLogoSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 63px;
    height: 17px;
  }
`

const MobDeckBtn = styled.a`
  font-family: 'MMC';
  display: block;
  padding: 5px 0 6px;
  width: 146px;
  height: 34px;
  margin-left: 30px;
  border: 1px solid rgba(9, 9, 10, 0.2);
  border-radius: 3px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #272a32;
  ${({ theme }) => theme.adaptive.smm} {
    width: 94px;
    height: 34px;
    margin-left: 18px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 68px;
    height: 26px;
    margin-left: 16px;
    border-radius: 2px;
    font-size: 12px;
    padding: 4px 0 5px;
  }
`

const Ship = styled.div`
  position: absolute;
  top: -732px;
  left: 863px;
  width: 875px;
  height: 766px;
  background: url('${shipImg}') center no-repeat;
  background-size: cover;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    top: -610px;
    left: 705px;
    width: 730px;
    height: 639px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -543px;
    left: 634px;
    width: 665px;
    height: 573px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -482px;
    left: 564px;
    width: 582px;
    height: 510px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -387px;
    left: 450px;
    width: 467px;
    height: 409px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Wale = styled.div`
  position: absolute;
  left: 763px;
  bottom: 0;
  width: 405px;
  height: 404px;
  background: url('${waleImg}') center no-repeat;
  background-size: contain;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    left: 639px;
    width: 338px;
    height: 337px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    left: 575px;
    width: 303px;
    height: 302px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    left: 511px;
    width: 270px;
    height: 269px;
  }
  ${({ theme }) => theme.adaptive.md} {
    left: 407px;
    width: 216px;
    height: 215px;
    bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`
