import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import fishBg from 'img/incubator/fish-bg.svg'
import fishBgSm from 'img/incubator/fish-bg-mob.svg'
import fishBgSmm from 'img/incubator/fish-bg-smm.svg'
import titleImg from 'img/incubator/title.svg'
import { ReactComponent as BtnIcon } from 'img/incubator/btn-icon.svg'
import lightImg from 'img/incubator/light.svg'
import btnCopy from 'img/incubator/btn-copy.svg'
import goldFishes from 'img/incubator/gold-fishes.png'
import goldFishesSm from 'img/incubator/gold-fishes-mob.png'
import submarineImg from 'img/incubator/submarine-img.png'

function Main() {
  return (
    <Root>
      <Container>
        <TitleWrap>
          <Submarine />
          <Title />
          <SubTitle>
            We are seeding opportunities and resources to{' '}
            <span>support the world's best DeFi and Web3 entrepreneurs</span> to
            launch their projects on the NEAR Protocol.
          </SubTitle>
          <BtnWrap>
            <Light />
            <BtnCopy />
            <Button
              href={'https://forms.gle/6KZL8L83x1CQvyVQ9'}
              target={'_blank'}
            >
              <BtnText>Get in touch</BtnText>
              <BtnIcon />
            </Button>
          </BtnWrap>
        </TitleWrap>
        <Fishes />
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 125px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 112px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 99px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 79px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 63px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 0;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 979px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 813px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 734px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 655px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 523px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 540px;
    margin-top: -8px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 350px;
    margin-top: -6px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 280px;
    margin-top: -5px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -164px;
    left: -506px;
    width: 2505px;
    height: 947px;
    background: url('${fishBg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      top: -136px;
      left: -422px;
      width: 2088px;
      height: 789px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: -121px;
      left: -380px;
      width: 1879px;
      height: 710px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: -107px;
      left: -337px;
      width: 1670px;
      height: 631px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: -85px;
      left: -270px;
      width: 1336px;
      height: 505px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: -55px;
      left: -2px;
      width: 531px;
      height: 1004px;
      background: url('${fishBgSm}') center no-repeat;
      background-size: contain;
    }
    ${({ theme }) => theme.adaptive.smm} {
      top: -60px;
      left: -58px;
      width: 428px;
      height: 1015px;
      background: url('${fishBgSmm}') center no-repeat;
      background-size: contain;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: -48px;
      left: -42px;
      width: 334px;
      height: 792px;
    }
  }
`

const Submarine = styled.div`
  position: absolute;
  top: 3px;
  right: -845px;
  width: 907px;
  height: 621px;
  background: url('${submarineImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 2px;
    right: -708px;
    width: 756px;
    height: 517px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 0;
    right: -634px;
    width: 680px;
    height: 466px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 1px;
    right: -561px;
    width: 605px;
    height: 414px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 1px;
    right: -450px;
    width: 484px;
    height: 331px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 108px;
    right: -150px;
    width: 538px;
    height: 369px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 94px;
    right: -234px;
    width: 468px;
    height: 320px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 72px;
    right: -191px;
    width: 365px;
    height: 250px;
  }
`

const Title = styled.div`
  width: 100%;
  height: 105px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    height: 86px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 68px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 464px;
    height: 49px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 301px;
    height: 32px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 247px;
    height: 26px;
  }
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 37px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #090909;
  margin-top: 28px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 31px;
    margin-top: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
    margin-top: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
    margin-top: 21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 37px;
    margin-top: 504px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 37px;
    margin-top: 462px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 29px;
    margin-top: 362px;
  }
  span {
    font-weight: 600;
  }
`

const BtnWrap = styled.div`
  position: relative;
`

const BtnText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
  }
`

const Button = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 430px;
  height: 60px;
  background: #ff85fb;
  border: 1px solid #090909;
  box-shadow: 3px 3px 0px #353535;
  border-radius: 4px;
  padding: 16px 36px 16px 21px;
  margin-top: 73px;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 358px;
    height: 50px;
    box-shadow: 3px 3px 0px #353535;
    padding: 13px 30px 14px 17px;
    margin-top: 61px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 323px;
    height: 45px;
    box-shadow: 2px 2px 0px #353535;
    padding: 12px 27px 13px 15px;
    margin-top: 55px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 287px;
    height: 40px;
    padding: 11px 24px 11px 14px;
    border-radius: 3px;
    margin-top: 49px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 229px;
    height: 32px;
    padding: 9px 19px 9px 11px;
    border-radius: 2px;
    margin-top: 37px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 476px;
    height: 60px;
    padding: 16px 23px 16px 21px;
    box-shadow: 3px 3px 0px #353535;
    border-radius: 4px;
    margin-top: 60px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 308px;
    height: 60px;
    padding: 16px 16px 16px 21px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 240px;
    height: 47px;
    padding: 12px 12px 12px 16px;
    box-shadow: 2px 2px 0px #353535;
    border-radius: 3px;
  }
  svg {
    width: 30px;
    height: 30px;
    fill: #1a1a1a;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      width: 25px;
      height: 25px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 23px;
      height: 23px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 20px;
      height: 20px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 16px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 30px;
      height: 30px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 23px;
      height: 23px;
    }
  }
  &:hover {
    background: #ff65fa;
    ${BtnText} {
      color: #fff;
    }
    svg {
      fill: #fff;
    }
  }
`

const Light = styled.div`
  position: absolute;
  top: 12px;
  left: 0;
  width: 1343px;
  height: 468px;
  background: url('${lightImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 10px;
    width: 1119px;
    height: 390px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 9px;
    width: 1007px;
    height: 351px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 8px;
    width: 895px;
    height: 312px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 6px;
    width: 716px;
    height: 250px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const BtnCopy = styled.div`
  position: absolute;
  top: 20px;
  left: 29px;
  width: 459px;
  height: 80px;
  background: url('${btnCopy}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    top: 17px;
    left: 24px;
    width: 383px;
    height: 67px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 15px;
    left: 22px;
    width: 344px;
    height: 60px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 13px;
    left: 19px;
    width: 306px;
    height: 53px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 11px;
    left: 15px;
    width: 245px;
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 20px;
    left: 32px;
    width: 508px;
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 20px;
    left: 21px;
    width: 329px;
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 16px;
    left: 16px;
    width: 257px;
    height: 62px;
  }
`

const Fishes = styled.div`
  position: relative;
  margin-top: 96px;
  margin-left: 40px;
  width: 611px;
  height: 231px;
  background: url('${goldFishes}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 80px;
    margin-left: 34px;
    width: 509px;
    height: 193px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 72px;
    margin-left: 30px;
    width: 458px;
    height: 173px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 64px;
    margin-left: 26px;
    width: 407px;
    height: 154px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 51px;
    margin-left: 22px;
    width: 326px;
    height: 123px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    margin: 0;
    left: 0;
    top: 95px;
    width: 179px;
    height: 409px;
    background: url('${goldFishesSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 78px;
    width: 156px;
    height: 355px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 63px;
    width: 122px;
    height: 277px;
  }
`
