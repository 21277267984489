import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as TierInfoBg } from 'img/dao/tiers/tier-info.svg'
import { ReactComponent as SmallInfoBg } from 'img/dao/tiers/small-info.svg'
import tierOneMonster from 'img/dao/tiers/tier-one-monster.png'
import tierTwoMonster from 'img/dao/tiers/tier-two-monster.png'
import tierThreeMonster from 'img/dao/tiers/tier-three-monster.png'
import useWindowSize from '../../../helpers/utils/useWindowSize'
import { ReactComponent as Icon } from 'img/dao/tiers/requirements-icon.svg'
import { ReactComponent as ArrowDown } from 'img/dao/tiers/arrow-down.svg'
import roundsImg from 'img/dao/tiers/rounds-img.svg'
import { ReactComponent as RectsLine } from 'img/dao/tiers/rects-img.svg'
import { TierProps } from 'store/daoTiers'

interface Props {
  tier: TierProps
  bg: any
  id: number
  activeId: number
  setActiveId: (id: number) => void
}

function Tier(props: Props) {
  const { width } = useWindowSize()
  const [stylesTransform, setStylesTransform] = useState<any>({
    left: props.id === 1 ? 0 : props.id === 2 ? '50%' : 'unset',
    right: props.id === 3 ? 0 : 'unset',
    transform: props.id === 2 ? 'translate(-50%,-50%)' : 'translateY(-50%)',
  })

  useEffect(() => {
    if (props.activeId !== 3 && props.activeId > 0) {
      if (props.id > props.activeId) {
        setStylesTransform({
          ...stylesTransform,
          transform:
            props.id === 2 ? 'translate(50%,-50%)' : 'translate(100%, -50%)',
        })
      } else {
        setStylesTransform({
          ...stylesTransform,
          left: props.id === 1 ? 0 : props.id === 2 ? '50%' : 'unset',
          right: props.id === 3 ? 0 : 'unset',
          transform:
            props.id === 2 ? 'translate(-50%,-50%)' : 'translateY(-50%)',
        })
      }
    }
    if (props.activeId === 3 && props.activeId > 0) {
      setStylesTransform({
        ...stylesTransform,
        transform:
          props.id === 2 ? 'translate(-150%,-50%)' : 'translate(-100%, -50%)',
      })
    }
    if (props.activeId === 0) {
      setStylesTransform({
        ...stylesTransform,
        left: props.id === 1 ? 0 : props.id === 2 ? '50%' : 'unset',
        right: props.id === 3 ? 0 : 'unset',
        transform: props.id === 2 ? 'translate(-50%,-50%)' : 'translateY(-50%)',
      })
    }
  }, [props.activeId])

  const onTierClick = (id: number) => {
    console.log('dad')
    if (id !== props.activeId) {
      props.setActiveId(id)
    } else {
      props.setActiveId(0)
    }
  }

  return (
    <Root style={{ ...stylesTransform }}>
      <HoverArea onClick={() => onTierClick(props.id)} />
      <TierInfo
        isActive={props.id === props.activeId}
        colorVal={props.tier.color}
      >
        <TierInfoRequirements>
          <RequirementsText>$NSTART Stake requirements</RequirementsText>
          <RequirementsValue>{props.tier.requirementsValue}</RequirementsValue>
        </TierInfoRequirements>
        <RequirementsList>
          <RequirementsItem>
            <Icon />
            {props.tier.firstLi}
          </RequirementsItem>
          <RequirementsItem>
            <Icon />
            {props.tier.secondLi}
          </RequirementsItem>
          <RequirementsItem>
            <Icon />
            {props.tier.thirdLi}
          </RequirementsItem>
        </RequirementsList>
        {width > 960 && <TierInfoBg className={'bg'} />}
        {width <= 960 && (
          <TierInfoBgSm className={'bg'}>
            <Rounds />
            <Rects>
              <RectsLine />
            </Rects>
          </TierInfoBgSm>
        )}
      </TierInfo>
      <SmallInfo
        isActive={props.id === props.activeId}
        colorVal={props.tier.color}
      >
        {width <= 960 && <SmallInfoMobBg />}
        {width > 960 && <SmallInfoBg />}
        {width > 960 && (
          <SmallInfoValue>{props.tier.smallInfoVal}</SmallInfoValue>
        )}
      </SmallInfo>
      {props.bg}
      <Monster idx={props.id} />
      {width <= 960 && (
        <SmallInfoValue
          isActive={props.id === props.activeId}
          colorVal={props.tier.color}
        >
          {props.tier.smallInfoVal}
          <ArrowDown />
        </SmallInfoValue>
      )}
    </Root>
  )
}

export default Tier

const Root = styled.div`
  position: absolute;
  width: 532px;
  height: 532px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 452px;
    height: 452px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 408px;
    height: 408px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 366px;
    height: 366px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 296px;
    height: 296px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    margin: 142px auto 0 !important;
    transform: unset !important;
    width: 498px;
    height: 498px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin: 59px auto 0 !important;
    width: 362px;
    height: 362px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin: 67px auto 0 !important;
    width: 283px;
    height: 283px;
  }
  &:last-child {
    margin-right: 0;
  }
  .bg {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
`

const TierInfoBgSm = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 5.3087px 0px rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  ${({ theme }) => theme.adaptive.smm} {
    box-shadow: 0px 4.70897px 0px rgba(0, 0, 0, 0.9);
    border-radius: 7px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    box-shadow: 0px 3.67977px 0px rgba(0, 0, 0, 0.9);
    border-radius: 6px;
  }
`

const Rounds = styled.div`
  position: absolute;
  width: 362px;
  height: 32px;
  bottom: 52px;
  left: 50%;
  transform: translateX(-50%);
  background: url('${roundsImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.smm} {
    width: 260px;
    height: 23px;
    bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 203px;
    height: 18px;
    bottom: 19px;
  }
`

const Rects = styled.div`
  position: absolute;
  width: 421px;
  height: 27px;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.adaptive.smm} {
    width: 296px;
    height: 16px;
    bottom: 77px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 231px;
    height: 12px;
    bottom: 60px;
  }
  svg {
    width: 100%;
  }
`

const SmallInfoMobBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
  border: 2px solid #000000;
  border-radius: 12px;
`

const SmallInfo = styled.div<{ colorVal: string; isActive: boolean }>`
  position: absolute;
  width: 263px;
  height: 276px;
  top: 2px;
  right: 2px;
  transition: 0.4s;
  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  ${({ theme }) => theme.adaptive.xl} {
    width: 221px;
    height: 231px;
    top: 1px;
    right: 1px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 200px;
    height: 209px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 179px;
    height: 188px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 144px;
    height: 151px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 287px;
    height: 287px;
    top: unset;
    bottom: -34px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 210px;
    height: 210px;
    bottom: -27px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 164px;
    height: 164px;
    bottom: -22px;
  }
  ${SmallInfoMobBg} {
    background: ${({ colorVal }) => (colorVal ? colorVal : '')};
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: ${({ colorVal }) => (colorVal ? colorVal : '')};
    z-index: 1;
    ${({ theme }) => theme.adaptive.sm} {
      transform: rotate(45deg);
    }
  }
`

const SmallInfoValue = styled.div<{ isActive?: boolean; colorVal?: string }>`
  position: absolute;
  top: 56px;
  right: 14px;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 33px;
  line-height: 62%;
  text-align: right;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: #181817;
  ${({ theme }) => theme.adaptive.xl} {
    top: 47px;
    right: 13px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 43px;
    right: 12px;
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 38px;
    right: 11px;
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 31px;
    right: 10px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 519px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    font-size: 46px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 379px;
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 293px;
    font-size: 29px;
  }
  svg {
    ${({ theme }) => theme.adaptive.sm} {
      content: '';
      position: absolute;
      width: 30px;
      height: 14px;
      left: 50%;
      transform: translateX(-50%);
      bottom: -21px;
      fill: #090909;
      transition: 0.4s;
      ${({ isActive, colorVal }) => {
        if (isActive && colorVal)
          return css`
            transform: translateX(-50%) rotate(180deg);
            fill: ${colorVal};
          `
      }}
    ${({ theme }) => theme.adaptive.smm} {
      width: 22px;
      height: 10px;
      bottom: -16px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 17px;
      height: 8px;
      bottom: -13px;
    }
  }
`

const TierInfoRequirements = styled.div`
  display: flex;
  align-items: center;
  background: #74e7bf;
  border: 1px solid #000000;
  box-shadow: 4.67761px 4.67761px 0px #181817;
  border-radius: 7px;
  padding: 22px 0;
  ${({ theme }) => theme.adaptive.xl} {
    box-shadow: 3.95235px 3.95235px 0px #181817;
    padding: 16px 0;
  }
  ${({ theme }) => theme.adaptive.lg} {
    box-shadow: 3.64143px 3.64143px 0px #181817;
    padding: 12px 0;
  }
  ${({ theme }) => theme.adaptive.slg} {
    box-shadow: 3.29917px 3.29917px 0px #181817;
    padding: 12px 0;
  }
  ${({ theme }) => theme.adaptive.md} {
    box-shadow: 2.70325px 2.70325px 0px #181817;
    padding: 9px 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
    flex-wrap: wrap;
    box-shadow: 5.27334px 5.27334px 0px #181817;
    border-radius: 7px;
    padding: 27px 31px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    box-shadow: 4.67761px 4.67761px 0px #181817;
    border-radius: 7px;
    padding: 18px 24px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    box-shadow: 3.65525px 3.65525px 0px #181817;
    border-radius: 5px;
    padding: 14px 18px;
  }
`

const RequirementsText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #1d1e1f;
  margin-right: 40px;
  margin-left: 92px;
  width: 141px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
    margin-right: 35px;
    margin-left: 77px;
    width: 119px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 18px;
    margin-right: 31px;
    margin-left: 71px;
    width: 109px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 16px;
    margin-right: 28px;
    margin-left: 65px;
    width: 99px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 14px;
    margin-right: 17px;
    margin-left: 53px;
    width: 88px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 26px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 14px;
  }
`

const RequirementsList = styled.div`
  margin-top: 31px;
  margin-left: 92px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 26px;
    margin-left: 77px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 24px;
    margin-left: 72px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 22px;
    margin-left: 65px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 16px;
    margin-left: 53px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 46px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 24px;
  }
`

const RequirementsItem = styled.div`
  position: relative;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: -0.03em;
  color: #1d1e1f;
  margin-bottom: 9px;
  margin-left: 26px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 8px;
    margin-left: 22px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 7px;
    margin-left: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;
    margin-left: 19px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-left: 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 7px;
    margin-left: 21px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 7px;
    margin-left: 19px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-left: 14px;
  }
  svg {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -26px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 13px;
      height: 13px;
      left: -22px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 12px;
      height: 12px;
      left: -21px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 11px;
      height: 11px;
      left: -19px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 9px;
      height: 9px;
      left: -15px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 12px;
      height: 12px;
      left: -21px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      width: 12px;
      height: 12px;
      left: -19px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 8px;
      height: 8px;
      left: -14px;
    }
  }
`

const RequirementsValue = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 60px;
  line-height: 60%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #181817;
  width: 153px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 50px;
    width: 128px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 46px;
    width: 118px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 36px;
    width: 92px;
    line-height: 60%;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 90px;
    width: auto;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 60px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 48px;
  }
`

const TierInfo = styled.div<{ colorVal: string; isActive: boolean }>`
  position: absolute;
  width: 655px;
  height: 365px;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  right: -537px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s;
  padding: 36px 118px 27px 55px;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 31px 100px 23px 47px;
    width: 553px;
    height: 308px;
    right: -452px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 29px 90px 21px 43px;
    width: 510px;
    height: 284px;
    right: -423px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 26px 84px 19px 39px;
    width: 462px;
    height: 257px;
    right: -371px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 21px 69px 16px 32px;
    width: 378px;
    height: 211px;
    right: -308px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 376px 30px 140px;
    width: 499px;
    height: 970px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    top: 245px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 262px 27px 77px;
    width: 350px;
    height: 637px;
    top: 188px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 204px 19px 60px;
    width: 274px;
    height: 498px;
    top: 147px;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: ${({ colorVal }) => (colorVal ? colorVal : '')};
    z-index: -1;
  }
  svg {
    fill: ${({ colorVal }) => (colorVal ? colorVal : '')};
  }
  ${TierInfoRequirements} {
    background: ${({ colorVal }) => (colorVal ? colorVal : '')};
  }
`

const HoverArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
`

const Monster = styled.div<{ idx: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 79%;
  height: 79%;
  border-radius: 50%;
  overflow: hidden;
  ${({ idx }) => {
    if (idx === 1) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: 52px;
          left: -83px;
          width: 452px;
          height: 452px;
          background: url('${tierOneMonster}') center no-repeat;
          background-size: cover;
          ${({ theme }) => theme.adaptive.xl} {
            top: 45px;
            left: -69px;
            width: 380px;
            height: 380px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            top: 42px;
            left: -61px;
            width: 343px;
            height: 343px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: 39px;
            left: -55px;
            width: 308px;
            height: 308px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: 31px;
            left: -44px;
            width: 249px;
            height: 249px;
          }
          ${({ theme }) => theme.adaptive.sm} {
          }
          ${({ theme }) => theme.adaptive.smm} {
          }
          ${({ theme }) => theme.adaptive.xs} {
          }
        }
      `
    }
    if (idx === 2) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: 72px;
          left: -22px;
          width: 452px;
          height: 452px;
          background: url('${tierTwoMonster}') center no-repeat;
          background-size: cover;
          ${({ theme }) => theme.adaptive.xl} {
            top: 60px;
            left: -19px;
            width: 380px;
            height: 380px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            top: 54px;
            left: -17px;
            width: 343px;
            height: 343px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: 49px;
            left: -15px;
            width: 308px;
            height: 308px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: 39px;
            left: -12px;
            width: 249px;
            height: 249px;
          }
          ${({ theme }) => theme.adaptive.sm} {
          }
          ${({ theme }) => theme.adaptive.smm} {
          }
          ${({ theme }) => theme.adaptive.xs} {
          }
        }
      `
    }
    if (idx === 3) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: 66px;
          left: -55px;
          width: 452px;
          height: 452px;
          background: url('${tierThreeMonster}') center no-repeat;
          background-size: cover;
          ${({ theme }) => theme.adaptive.xl} {
            top: 55px;
            left: -47px;
            width: 418px;
            height: 418px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            top: 50px;
            left: -42px;
            width: 377px;
            height: 377px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: 45px;
            left: -38px;
            width: 339px;
            height: 339px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: 36px;
            left: -30px;
            width: 273px;
            height: 273px;
          }
          ${({ theme }) => theme.adaptive.sm} {
          }
          ${({ theme }) => theme.adaptive.smm} {
          }
          ${({ theme }) => theme.adaptive.xs} {
          }
        }
      `
    }
  }}
`
