import React, { Props, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import Tier from '../tier'
import { ReactComponent as TierOneBg } from 'img/dao/tiers/tier-one-img.svg'
import { ReactComponent as TierTwoBg } from 'img/dao/tiers/tier-two-img.svg'
import { ReactComponent as TierThreeBg } from 'img/dao/tiers/tier-three-img.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import prevImg from 'img/dao/tiers/prev-img.svg'
import nextImg from 'img/dao/tiers/next-img.svg'
import arrow from 'img/dao/tiers/arrow.svg'
import daoTiersList from '../../../../store/daoTiers'

function TierSlider() {
  const [activeId, setActiveId] = useState(0)
  const sliderRef = useRef(null)

  const settings = {
    infinite: true,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    afterChange(currentSlide: number) {
      setActiveId(0)
    },
  }

  const onNextClick = () => {
    if (!sliderRef.current) return
    // @ts-ignore
    sliderRef.current.slickNext()
  }

  const onPrevClick = () => {
    if (!sliderRef.current) return
    // @ts-ignore
    sliderRef.current.slickPrev()
  }

  return (
    <SliderContainer isActive={activeId !== 0}>
      <Slider ref={sliderRef} {...settings} className="tier-slider">
        {daoTiersList.map((item, idx) => {
          return (
            <Slide>
              <Tier
                key={idx}
                tier={item}
                bg={item.getBg()}
                id={idx + 1}
                activeId={activeId}
                setActiveId={setActiveId}
              />
            </Slide>
          )
        })}
      </Slider>
      <Buttons>
        <Prev onClick={onPrevClick}>Prev</Prev>
        <Next onClick={onNextClick}>Next</Next>
      </Buttons>
    </SliderContainer>
  )
}

export default TierSlider

const Slide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const SliderContainer = styled.div<{ isActive?: boolean }>`
  position: relative;
  width: 100%;
  min-height: ${({ isActive }) => (isActive ? '1519px' : '931px')};
  ${({ theme }) => theme.adaptive.smm} {
    min-height: ${({ isActive }) => (isActive ? '1002px' : '618px')};
  }
  ${({ theme }) => theme.adaptive.xs} {
    min-height: ${({ isActive }) => (isActive ? '783px' : '516px')};
  }
  ${Slide} {
    min-height: ${({ isActive }) => (isActive ? '1519px' : '931px')};
    ${({ theme }) => theme.adaptive.smm} {
      min-height: ${({ isActive }) => (isActive ? '1002px' : '618px')};
    }
    ${({ theme }) => theme.adaptive.xs} {
      min-height: ${({ isActive }) => (isActive ? '783px' : '516px')};
    }
  }
`

const Buttons = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 79px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.adaptive.smm} {
    bottom: 63px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    bottom: 32px;
  }
`

const BtnCss = css`
  position: relative;
  width: 228px;
  height: 43px;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #090909;
  ${({ theme }) => theme.adaptive.smm} {
    width: 170px;
    height: 32px;
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 138px;
    height: 26px;
    font-size: 22px;
  }
`

const Prev = styled.button`
  ${BtnCss};
  background: url('${prevImg}') center no-repeat;
  background-size: contain;
  margin-right: 15px;
  ${({ theme }) => theme.adaptive.smm} {
    margin-right: 12px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-right: 9px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 11px;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;
    background: url('${arrow}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.smm} {
      width: 10px;
      height: 8px;
      left: 18px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 8px;
      height: 7px;
      left: 10px;
    }
  }
`

const Next = styled.button`
  ${BtnCss};
  background: url('${nextImg}') center no-repeat;
  background-size: contain;
  &:before {
    content: '';
    position: absolute;
    width: 13px;
    height: 11px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 24px;
    background: url('${arrow}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.smm} {
      width: 10px;
      height: 8px;
      right: 18px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 8px;
      height: 7px;
      right: 10px;
    }
  }
`
