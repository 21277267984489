import { Player } from './Player'

export class PlayerOverlay {
  private player: Player
  private img: any

  constructor(player: Player, img: any) {
    this.player = player
    this.img = img
  }
  draw(
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number
  ) {
    context.drawImage(this.img, x, y, w, h)
  }
}
