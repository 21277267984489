import { Rect } from './Rect'
import { Game } from './Game'

export class Hole {
  rectArr: Rect[]
  private game: Game
  private size: number
  private isColision: boolean

  constructor(game: Game, size: number, x?: number) {
    this.game = game
    this.size = size
    this.rectArr = this.getRects(x ? x : this.game.width)
    this.isColision = false
  }

  update(deltaTime: number) {
    this.rectArr.forEach((rect) => {
      rect.update(deltaTime)

      if (rect.markedForDelete)
        this.rectArr.splice(this.rectArr.indexOf(rect), 1)
    })
  }

  draw(context: CanvasRenderingContext2D) {
    this.rectArr.forEach((rect) => {
      rect.draw(context)
    })
  }

  getRects = (xVal: number) => {
    let arr: Rect[] = []
    let countW = 6
    let countH = 3
    let x = xVal
    let y = this.game.height - 30
    let dx = 2

    let type = this.game.RandomIntInRange(1, 3)

    if (type === 1) {
      for (let i = 0; i < countH; i++) {
        dx -= this.size
        countW += 2
        for (let j = 0; j < countW; j++) {
          let rect = new Rect(
            this.game,
            x + dx,
            y,
            this.size,
            this.size,
            '#090909'
          )
          arr.push(rect)
          x = x + this.size - 1
        }
        x = xVal
        y = y - this.size
      }
    } else if (type === 2) {
      for (let i = 0; i < countH; i++) {
        if (i === 0) {
          countW = 0
        } else if (i === 1) {
          dx = this.size
          countW = 4
        } else if (i === 2) {
          dx = 0
          countW = 6
        }
        for (let j = 0; j < countW; j++) {
          let rect = new Rect(
            this.game,
            x + dx,
            y,
            this.size,
            this.size,
            '#090909'
          )
          arr.push(rect)
          x = x + this.size - 1
        }
        x = xVal
        y = y - this.size
      }
    } else if (type === 3) {
      for (let i = 0; i < countH; i++) {
        if (i === 0) {
          dx = this.size * 2
          countW = 2
        } else if (i === 1) {
          dx = this.size
          countW = 7
        } else if (i === 2) {
          dx = 0
          countW = 9
        }
        for (let j = 0; j < countW; j++) {
          let rect = new Rect(
            this.game,
            x + dx,
            y,
            this.size,
            this.size,
            '#090909'
          )
          arr.push(rect)
          x = x + this.size - 1
        }
        x = xVal
        y = y - this.size
      }
    }

    return arr
  }
}
