import React, { useState } from 'react'
import styled from 'styled-components'
import logo from 'img/index/main/logo.svg'
import logoCopy from 'img/index/main/logo-copy.svg'
import Header from '../header'
import BgIndex from './bg'
import Game from 'components/game'
import useWindowSize from 'helpers/utils/useWindowSize'
import rectImg from 'img/index/main/rect.svg'
import { Link } from 'react-router-dom'
import { DEX_LINK } from '../../../config/app'

interface Props {
  isRestart: boolean
  onEnd(val: number): void
}

function Main(props: Props) {
  const [isSlow, setIsSlow] = useState(false)
  const { width } = useWindowSize()

  const slowHandler = (val: boolean) => {
    setIsSlow(val)
  }

  return (
    <Root>
      {width > 960 && (
        <Bg>
          <BgIndex isSlow={isSlow} />
        </Bg>
      )}

      <Header />
      <TitleWrap>
        <Title>
          <TitleCopy />
        </Title>
        {width <= 960 && (
          <SubTitle>
            DAO-governed <span>incubator</span>
          </SubTitle>
        )}
        {width <= 960 && <Text>merged with an innovative DeFi layer</Text>}
      </TitleWrap>
      <Nav>
        <NavItem>
          <Link to={'/dao'}>
            NS<span>DAO</span>
          </Link>
        </NavItem>
        <Border />
        <NavItem>
          <Link to={'/pad'}>
            NS<span>PAD</span>
          </Link>
        </NavItem>
        <Border />
        <NavItem>
          <a href={DEX_LINK} target={'_blank'}>
            <span>DEX</span>
          </a>
        </NavItem>
        <Border />
        <NavItem>
          <Link to={'/incubator'}>
            <span>Incubator</span>
          </Link>
        </NavItem>
      </Nav>
      {width > 960 && (
        <GameWrap>
          <Game
            onSlow={slowHandler}
            isRestart={props.isRestart}
            onEnd={props.onEnd}
          />
        </GameWrap>
      )}
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  height: 100vh;
  ${({ theme }) => theme.adaptive.sm} {
    background: #090909;
    height: auto;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 916px;
  margin: 0 auto;
  margin-top: 302px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 252px;
    width: 763px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 226px;
    width: 687px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 199px;
    width: 604px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 156px;
    width: 489px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 0;
    width: 540px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 280px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -40px;
    right: -33px;
    width: 30px;
    height: 30px;
    background: url('${rectImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      top: -34px;
      right: -26px;
      width: 25px;
      height: 25px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: -30px;
      right: -24px;
      width: 23px;
      height: 23px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: -24px;
      right: -21px;
      width: 20px;
      height: 20px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: -16px;
      right: -17px;
      width: 16px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      content: unset;
    }
  }
`

const Title = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  background: url('${logo}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    height: 73px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 58px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 450px;
    height: 38px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 307px;
    height: 25px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 239px;
    height: 20px;
  }
`

const TitleCopy = styled.div`
  position: absolute;
  width: 916px;
  height: 311px;
  top: -345px;
  left: 50%;
  transform: translateX(-50%);
  background: url('${logoCopy}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 763px;
    height: 259px;
    top: -285px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 687px;
    height: 233px;
    top: -257px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 610px;
    height: 207px;
    top: -229px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 488px;
    height: 166px;
    top: -183px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Nav = styled.div`
  position: relative;
  width: 582px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 35px;
  z-index: 3;
  ${({ theme }) => theme.adaptive.xl} {
    width: 484px;
    margin-top: 29px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 435px;
    margin-top: 27px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 387px;
    margin-top: 26px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 308px;
    margin-top: 24px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const NavItem = styled.div<{ inActive?: boolean }>`
  a {
    position: relative;
    font-family: 'Coders Crux';
    font-weight: 400;
    font-size: 35px;
    line-height: 90%;
    letter-spacing: -0.02em;
    color: ${({ inActive }) => (inActive ? '#5A5A5A' : '#ffffff')};
    transition: 0.4s;
    z-index: 2;
    cursor: pointer;
    ${({ theme }) => theme.adaptive.xl} {
      font-size: 29px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      font-size: 26px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      font-size: 23px;
    }
    ${({ theme }) => theme.adaptive.md} {
      font-size: 18px;
    }
    span {
      color: #74e7bf;
      transition: 0.4s;
    }
    &:hover {
      span {
        color: #ff65fa;
      }
    }
  }
`

const Border = styled.div`
  width: 2px;
  height: 11px;
  background: #f4f3eb;
  ${({ theme }) => theme.adaptive.xl} {
    height: 9px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 6px;
  }
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const GameWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`

const SubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 68px;
  line-height: 90%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 29px;
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 44px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 35px;
    margin-top: 14px;
  }
  span {
    color: #ff85fb;
  }
`

const Text = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 42px;
  line-height: 90%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f4f3eb;
  margin-top: 15px;
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 27px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 22px;
    margin-top: 7px;
  }
`
