import { Rect } from './Rect'
import { Game } from './Game'
import { Player } from './Player'
import { detectRectangleCollision } from './ColisionDetection'
import { Sound } from './Sound'

export class Cactus {
  rectArr: Rect[]
  private game: Game
  private size: number
  private isColision: boolean
  private markedForDelete: boolean

  constructor(game: Game, size: number, x?: number) {
    this.game = game
    this.size = size
    this.rectArr = this.getRects(x ? x : this.game.width + this.size)
    this.isColision = false
    this.markedForDelete = false
  }

  update(deltaTime: number, player: Player) {
    this.rectArr.forEach((rect) => {
      rect.update(deltaTime)

      if (rect.markedForDelete)
        this.rectArr.splice(this.rectArr.indexOf(rect), 1)

      let playerRect = {
        x: player.hitX,
        width: player.width,
        y: player.hitY,
        height: player.height,
        currRotation: player.currRotation,
      }
      let Rect = {
        x: rect.x,
        width: rect.w,
        y: rect.y,
        height: rect.h,
        currRotation: 0,
      }

      if (rect.x + rect.w > 0 && !this.isColision) {
        if (detectRectangleCollision(Rect, playerRect)) {
          if (!this.game.isMute) {
            let sound = new Sound(
              this.game.audioCtx,
              this.game.buffer.getSoundByIndex(1)
            )
            sound.play()
          }
          this.isColision = true
          this.game.colisionsDetect()
          this.markedForDelete = true
        }
      }
    })
  }

  draw(context: CanvasRenderingContext2D) {
    this.rectArr.forEach((rect) => {
      rect.draw(context)
    })
  }

  getRects = (xVal: number) => {
    let arr: Rect[] = []
    let countW = 4
    let countH = 8
    let size = this.size
    let x = xVal
    let y = this.game.height - 50 * this.game.ratio - size
    let dx = 0

    let type = this.game.RandomIntInRange(1, 3)

    if (type === 1) {
      for (let i = 0; i < countH; i++) {
        if (i === 0 || i === 4) {
          dx = size * 2 + 2
          countW = 1
        } else if (i === 1) {
          dx = size + 1
          countW = 2
        } else if (i === 2 || i === 5) {
          dx = size + 1
          countW = 3
        } else if (i === 3) {
          dx = 0
          countW = 3
        } else if (i === 6) {
          dx = size * 2 + 2
          countW = 2
        } else {
          countW = 0
        }
        for (let j = 0; j < countW; j++) {
          let rect = new Rect(this.game, x + dx, y, size, size)
          arr.push(rect)
          x = x + size + 1
        }
        x = xVal
        y = y - size - 1
      }
    } else if (type === 2) {
      for (let i = 0; i < countH; i++) {
        if (i === 0 || i === 2) {
          dx = size + 1
          countW = 2
        } else if (i === 1 || i === 4) {
          dx = size * 2 + 2
          countW = 1
        } else if (i === 3) {
          dx = 0
          countW = 4
        } else {
          countW = 0
        }
        for (let j = 0; j < countW; j++) {
          let rect = new Rect(this.game, x + dx, y, size, size)
          arr.push(rect)
          x = x + size + 1
        }
        x = xVal
        y = y - size - 1
      }
    } else if (type === 3) {
      for (let i = 0; i < countH; i++) {
        if (i === 0 || i === 4 || i === 7) {
          dx = size * 2 + 2
          countW = 1
        } else if (i === 1 || i === 3) {
          dx = size * 2 + 2
          countW = 2
        } else if (i === 2 || i === 6) {
          dx = 0
          countW = 3
        } else if (i === 5) {
          dx = size + 1
          countW = 3
        } else {
          countW = 0
        }
        for (let j = 0; j < countW; j++) {
          let rect = new Rect(this.game, x + dx, y, size, size)
          arr.push(rect)
          x = x + size + 1
        }
        x = xVal
        y = y - size - 1
      }
    }

    return arr
  }
}
