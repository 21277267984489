import { ReactComponent as Korea } from 'img/dao/flags/korea.svg'
import { ReactComponent as Nigeria } from 'img/dao/flags/nigeria.svg'
import { ReactComponent as Malaysia } from 'img/dao/flags/malaysia.svg'
import { ReactComponent as Ukraine } from 'img/dao/flags/ukraine.svg'
import { ReactComponent as Fraincais } from 'img/dao/flags/fraincais.svg'
import { ReactComponent as Turkey } from 'img/dao/flags/turkey.svg'
import { ReactComponent as Indonesia } from 'img/dao/flags/indonesia.svg'
import { ReactComponent as Arabic } from 'img/dao/flags/arabic.svg'
import { ReactComponent as Argentina } from 'img/dao/flags/argentina.svg'
import { ReactComponent as NearNative } from 'img/dao/flags/near-native.svg'

const guildList = [
  {
    link: 'https://twitter.com/NearKoreaDAO',
    text: 'Korea',
    flag: () => <Korea />,
  },
  {
    link: 'https://nearnigeria.org/',
    text: 'Nigeria',
    flag: () => <Nigeria />,
  },
  {
    link: 'https://mynear.org/',
    text: 'Malaysia',
    flag: () => <Malaysia />,
  },
  {
    link: 'https://twitter.com/nearuaguild',
    text: 'Ukraine',
    flag: () => <Ukraine />,
  },
  {
    link: 'https://twitter.com/FrancaisNear',
    text: 'Fraincais',
    flag: () => <Fraincais />,
  },
  {
    link: 'https://twitter.com/near_turkey',
    text: 'Turkey',
    flag: () => <Turkey />,
  },
  {
    link: 'https://nearindonesia.com/',
    text: 'Indonesia',
    flag: () => <Indonesia />,
  },
  {
    link: 'https://twitter.com/NEAR_Arabic',
    text: 'Arabic',
    flag: () => <Arabic />,
  },
  {
    link: 'https://twitter.com/NEARargentina',
    text: 'Argentina',
    flag: () => <Argentina />,
  },
  {
    link: 'https://twitter.com/near_protocol',
    text: 'NearNative',
    flag: () => <NearNative />,
  },
]

export default guildList
