import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import titleImg from 'img/incubator/about/title.svg'
import valueBg from 'img/incubator/about/value-bg.svg'
import { ReactComponent as Arrow } from 'img/incubator/about/arrow.svg'
import { ReactComponent as Spoon } from 'img/incubator/about/spoon.svg'
import { ReactComponent as Fly } from 'img/incubator/about/fly.svg'
import { ReactComponent as Pint } from 'img/incubator/about/pint.svg'
import { ReactComponent as Man } from 'img/incubator/about/man.svg'
import gameImg from 'img/incubator/about/game-img.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import { INCUBATOR_DECK_LINK } from 'config/app'

function About() {
  const { width } = useWindowSize()

  return (
    <Root>
      <Container>
        <Row>
          <TitleWrap>
            {width <= 960 && <MobTitle>We onboard the projects</MobTitle>}
            {width > 960 && <Title />}

            <SubTitle>at various stages of development </SubTitle>
            <List>
              <ListRow>
                <Value>Value:</Value>
                <Item>Product Formulation </Item>
                <Item>
                  Marketing, PR, roadmap and execution
                  <Fly />
                </Item>
              </ListRow>
              <ListRow>
                <Item>
                  Introduction to incubation partners and NEAR Foundation
                  <Man />
                </Item>
                <Item>
                  Technology consulting & UX/UI Assistance
                  <Spoon />
                </Item>
                <Item>
                  Captable management & investor relations
                  <Pint />
                </Item>
              </ListRow>
              <ListRow>
                <Item>Consulting on token structuring</Item>
                <Item>
                  Secondary market listing strategy & Market Maker Setup
                </Item>
                <Item>Technical audit</Item>
              </ListRow>
              <ListRow>
                <Item>Distributional & Utility tokenomics </Item>
                <Item>Community building, engagement & management</Item>
                <Download href={INCUBATOR_DECK_LINK} target={'_blank'}>
                  <BtnTitle>Download</BtnTitle>
                  <BtnText>The Incubation Services Deck</BtnText>
                  <Arrow />
                </Download>
              </ListRow>
            </List>
          </TitleWrap>
          <Pad>
            <Game />
            <Border />
            <Text>
              We deploy our massive network carrying your project all the way
              from concept formulation to successful funding round led by
              reputable VCs to IDO launch and beyond.{' '}
            </Text>
            <Button
              href={'https://forms.gle/6KZL8L83x1CQvyVQ9'}
              target={'_blank'}
            >
              contact our account managers
            </Button>
          </Pad>
        </Row>
      </Container>
    </Root>
  )
}

export default About

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 46px;
  padding-bottom: 196px;
  border-bottom: 1px solid rgba(9, 9, 9, 0.18);
  margin-bottom: 69px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 38px;
    padding-bottom: 163px;
    margin-bottom: 58px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 35px;
    padding-bottom: 147px;
    margin-bottom: 52px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 31px;
    padding-bottom: 131px;
    margin-bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 25px;
    padding-bottom: 105px;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 160px;
    padding-bottom: 120px;
    margin-bottom: 0;
    border: 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 125px;
    padding-bottom: 93px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${({ theme }) => theme.adaptive.sm} {
    flex-wrap: wrap;
  }
`

const TitleWrap = styled.div`
  width: 1101px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 919px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 828px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 737px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 593px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    order: 2;
  }
`

const Title = styled.div`
  width: 100%;
  height: 103px;
  background: url('${titleImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    height: 86px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 77px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 69px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 55px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const MobTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 116px;
  line-height: 71%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #090909;
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 90px;
  }
`

const SubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 56px;
  line-height: 90%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #090909;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 47px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 42px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 37px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 175px;
    left: 0;
    font-size: 56px;
    width: 364px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 342px;
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 267px;
    font-size: 44px;
  }
`

const List = styled.div`
  width: 100%;
  margin-top: 54px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 45px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 28px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 115px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 100px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 90px;
  }
`

const Value = styled.div`
  width: 414px;
  height: 120px;
  margin-right: 30px;
  padding-top: 6px;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 145px;
  line-height: 90%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: rgba(9, 9, 9, 0.18);
  background: url('${valueBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 345px;
    height: 100px;
    margin-right: 25px;
    padding-top: 5px;
    font-size: 121px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 311px;
    height: 90px;
    margin-right: 23px;
    font-size: 109px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 276px;
    height: 80px;
    margin-right: 20px;
    padding-top: 4px;
    font-size: 97px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 221px;
    height: 64px;
    margin-right: 16px;
    padding-top: 3px;
    font-size: 77px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    width: 100%;
    height: 120px;
    margin-right: 0;
    padding-top: 17px;
    font-size: 116px;
    color: #090909;
    background: none;
    margin-bottom: 50px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 634px;
      height: 100%;
      transform: translateX(-50%);
      background: url('${valueBg}') center no-repeat;
      background-size: cover;
    }
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 94px;
    padding-top: 13px;
    font-size: 90px;
    margin-bottom: 39px;
    &:before {
      width: 332px;
      height: 100%;
    }
  }
`

const Item = styled.div`
  position: relative;
  padding: 22px 25px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: rgba(9, 9, 9, 0.8);
  margin-right: 30px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 18px 21px;
    font-size: 19px;
    margin-right: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 17px 20px;
    font-size: 17px;
    margin-right: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 15px 18px;
    font-size: 15px;
    margin-right: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 12px 14px;
    font-size: 12px;
    margin-right: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100% !important;
    padding: 20px 22px;
    font-size: 23px;
    margin-right: 0;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 20px 26px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 16px 21px;
    font-size: 18px;
    margin-bottom: 21px;
  }
  svg {
    position: absolute;
    max-width: 26px;
    max-height: 26px;
    right: 28px;
    bottom: 16px;
    ${({ theme }) => theme.adaptive.xl} {
      max-width: 21px;
      max-height: 21px;
      right: 17px;
      bottom: 14px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      max-width: 19px;
      max-height: 19px;
      right: 15px;
      bottom: 13px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      max-width: 17px;
      max-height: 17px;
      right: 14px;
      bottom: 11px;
    }
    ${({ theme }) => theme.adaptive.md} {
      max-width: 14px;
      max-height: 14px;
      right: 11px;
      bottom: 9px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      max-width: 26px;
      max-height: 26px;
      right: 21px;
      bottom: 17px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      max-width: 20px;
      max-height: 20px;
      right: 24px;
      bottom: 13px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    opacity: 0.4;
    z-index: -1;
    border: 1px solid rgba(9, 9, 9, 0.22);
    border-radius: 7px;
    ${({ theme }) => theme.adaptive.xl} {
      border-radius: 6px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      border-radius: 5px;
    }
    ${({ theme }) => theme.adaptive.md} {
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 513px !important;
      height: 69px !important;
      top: unset !important;
      bottom: -7px !important;
      right: unset !important;
      left: 50% !important;
      transform: translateX(-50%);
      border-radius: 7px;
      ${({ theme }) => theme.adaptive.smm} {
        width: 325px !important;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 254px !important;
        bottom: -6px !important;
        height: 54px !important;
        border-radius: 5px;
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    border: 1px solid rgba(9, 9, 9, 0.22);
    z-index: -1;
    ${({ theme }) => theme.adaptive.xl} {
      border-radius: 6px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      border-radius: 5px;
    }
    ${({ theme }) => theme.adaptive.md} {
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      border-radius: 7px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      border-radius: 5px;
    }
  }
`

const ListRow = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 30px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin-bottom: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:nth-child(1) {
    ${Item} {
      &:nth-child(2) {
        width: 196px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 163px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 147px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 131px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 105px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 70px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 55px;
        }
        &:after {
          background: #f1f0e8;
        }
        &:before {
          top: -10px;
          left: 13px;
          width: 170px;
          height: 104px;
          background: #eae9e1;
          ${({ theme }) => theme.adaptive.xl} {
            top: -8px;
            left: 11px;
            width: 142px;
            height: 87px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            top: -8px;
            left: 10px;
            width: 128px;
            height: 78px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: -7px;
            left: 9px;
            width: 113px;
            height: 69px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: -5px;
            left: 7px;
            width: 91px;
            height: 55px;
          }
        }
      }
      &:nth-child(3) {
        width: 319px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 266px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 239px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 213px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 170px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 120px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 93px;
        }
        &:after {
          background: #fbdd6f;
        }
        &:before {
          top: -10px;
          right: -10px;
          width: 300px;
          height: 113px;
          background: #fbdd6f;
          ${({ theme }) => theme.adaptive.xl} {
            top: -8px;
            right: -8px;
            width: 250px;
            height: 94px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            width: 225px;
            height: 85px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: -7px;
            right: -7px;
            width: 200px;
            height: 75px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: -5px;
            right: -5px;
            width: 160px;
            height: 60px;
          }
        }
      }
    }
  }
  &:nth-child(2) {
    ${Item} {
      &:nth-child(1) {
        width: 318px;
        height: 175px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 265px;
          height: 146px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 239px;
          height: 131px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 212px;
          height: 117px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 170px;
          height: 93px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 175px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 136px;
        }
        &:after {
          background: #fbdd6f;
        }
      }
      &:nth-child(2) {
        width: 292px;
        height: 175px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 243px;
          height: 146px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 219px;
          height: 131px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 195px;
          height: 117px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 156px;
          height: 93px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 175px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 136px;
        }
        &:after {
          background: #f1f0e8;
        }
      }
      &:nth-child(3) {
        width: 319px;
        height: 175px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 266px;
          height: 146px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 239px;
          height: 131px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 213px;
          height: 117px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 170px;
          height: 94px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 175px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 136px;
        }
        &:after {
          background: #f1f0e8;
        }
        &:before {
          top: -10px;
          right: -10px;
          width: 300px;
          height: 158px;
          background: #eae9e1;
          ${({ theme }) => theme.adaptive.xl} {
            top: -8px;
            right: -8px;
            width: 250px;
            height: 132px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            width: 225px;
            height: 119px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: -7px;
            right: -7px;
            width: 200px;
            height: 105px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: -5px;
            right: -5px;
            width: 170px;
            height: 94px;
          }
        }
      }
    }
  }
  &:nth-child(3) {
    ${Item} {
      &:nth-child(1) {
        width: 241px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 201px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 181px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 161px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 129px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 120px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 94px;
        }
        &:after {
          background: #f1f0e8;
        }
      }
      &:nth-child(2) {
        width: 369px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 308px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 277px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 246px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 197px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 120px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 94px;
        }
        &:after {
          background: #f4f3eb;
        }
      }
      &:nth-child(3) {
        width: 319px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 266px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 239px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 213px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 170px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 70px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 55px;
        }
        &:after {
          background: #f4f3eb;
        }
        &:before {
          top: 8px;
          right: -10px;
          width: 268px;
          height: 104px;
          background: #eae9e1;
          ${({ theme }) => theme.adaptive.xl} {
            top: 7px;
            right: -8px;
            width: 223px;
            height: 87px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            top: 6px;
            right: -8px;
            width: 201px;
            height: 78px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            top: 5px;
            right: -7px;
            width: 179px;
            height: 69px;
          }
          ${({ theme }) => theme.adaptive.md} {
            top: 4px;
            right: -5px;
            width: 143px;
            height: 55px;
          }
        }
      }
    }
  }
  &:nth-child(4) {
    ${Item} {
      &:nth-child(1) {
        width: 241px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 201px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 181px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 161px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 129px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 120px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 94px;
        }
        &:after {
          background: #f1f0e8;
        }
        &:before {
          bottom: -10px;
          right: -10px;
          width: 240px;
          height: 107px;
          background: #eae9e1;
          ${({ theme }) => theme.adaptive.xl} {
            bottom: -8px;
            right: -8px;
            width: 200px;
            height: 89px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            width: 180px;
            height: 80px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            bottom: -7px;
            right: -7px;
            width: 160px;
            height: 71px;
          }
          ${({ theme }) => theme.adaptive.md} {
            bottom: -5px;
            right: -5px;
            width: 128px;
            height: 57px;
          }
        }
      }
      &:nth-child(2) {
        width: 370px;
        height: 120px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 308px;
          height: 100px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 278px;
          height: 90px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 247px;
          height: 80px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 197px;
          height: 64px;
        }
        ${({ theme }) => theme.adaptive.sm} {
          height: 120px;
          margin-bottom: 69px;
        }
        ${({ theme }) => theme.adaptive.xs} {
          height: 94px;
          margin-bottom: 54px;
        }
        &:after {
          background: #f4f3eb;
        }
        &:before {
          bottom: -10px;
          right: 20px;
          width: 330px;
          height: 107px;
          background: #eae9e1;
          ${({ theme }) => theme.adaptive.xl} {
            bottom: -8px;
            right: 17px;
            width: 275px;
            height: 89px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            bottom: -8px;
            right: 15px;
            width: 248px;
            height: 80px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            bottom: -7px;
            right: 13px;
            width: 220px;
            height: 71px;
          }
          ${({ theme }) => theme.adaptive.md} {
            bottom: -5px;
            right: 11px;
            width: 176px;
            height: 57px;
          }
        }
      }
    }
  }
`

const BtnTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  transition: 0.4s;
  margin-bottom: 3px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 31px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 37px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 29px;
    margin-bottom: 2px;
  }
`

const BtnText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #000000;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 17px;
  }
`

const Download = styled.a`
  position: relative;
  display: block;
  width: 419px;
  height: 120px;
  background: #ff85fb;
  border: 1px solid #090909;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.88);
  border-radius: 4px;
  padding: 22px 25px;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    width: 349px;
    height: 100px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
    padding: 18px 24px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 314px;
    height: 90px;
    padding: 17px 22px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 279px;
    height: 80px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
    padding: 15px 19px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 223px;
    height: 64px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.88);
    border-radius: 2px;
    padding: 12px 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    height: 120px;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.88);
    border-radius: 4px;
    padding: 22px 26px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 94px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
    padding: 17px 21px;
  }
  svg {
    display: block;
    position: absolute;
    top: 74px;
    right: 28px;
    width: 14px;
    height: 21px;
    fill: #0d0d0c;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      top: 59px;
      right: 19px;
      width: 11px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 53px;
      right: 17px;
      width: 10px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 48px;
      right: 15px;
      width: 9px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 38px;
      right: 12px;
      width: 7px;
      height: 11px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 32px;
      right: 274px;
      width: 17px;
      height: 26px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      right: 84px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: 25px;
      right: 73px;
      width: 13px;
      height: 20px;
    }
  }
  &:hover {
    background: #ff65fa;
    svg {
      fill: #fff;
    }
    ${BtnText}, ${BtnTitle} {
      color: #fff;
    }
  }
`

const Pad = styled.div`
  position: relative;
  width: 526px;
  height: 888px;
  background: #f4f3eb;
  border: 1px solid #000000;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.9);
  border-radius: 7px;
  padding: 42px 52px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 438px;
    height: 740px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.9);
    border-radius: 6px;
    padding: 35px 43px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 395px;
    height: 666px;
    padding: 32px 33px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 351px;
    height: 592px;
    border-radius: 5px;
    padding: 28px 29px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 281px;
    height: 474px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    padding: 22px 23px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    height: 898px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    padding: 43px 52px;
    margin-top: 325px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 582px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.9);
    border-radius: 5px;
    padding: 28px 34px;
    margin-top: 492px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 456px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    padding: 22px 27px;
    margin-top: 384px;
  }
`

const Game = styled.div`
  width: 422px;
  height: 296px;
  background: url('${gameImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 352px;
    height: 247px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 317px;
    height: 222px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 281px;
    height: 197px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 225px;
    height: 158px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 434px;
    height: 305px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 282px;
    height: 198px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 220px;
    height: 154px;
  }
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: #090909;
  opacity: 0.2;
  margin-top: 26px;
  margin-bottom: 35px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 22px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 20px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 17px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 14px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 27px;
    margin-bottom: 36px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 17px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 14px;
    margin-bottom: 18px;
  }
`

const Text = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 37px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
  }
`

const Button = styled.a`
  display: block;
  width: 100%;
  height: 64px;
  background: #f4f3eb;
  border: 1px solid #090909;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.88);
  border-radius: 4px;
  padding: 18px 0;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  margin-top: 61px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.xl} {
    height: 53px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
    padding: 15px 0;
    font-size: 18px;
    margin-top: 49px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 48px;
    padding: 14px 0;
    font-size: 16px;
    margin-top: 39px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 43px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
    padding: 12px 0;
    font-size: 15px;
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 34px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.88);
    border-radius: 2px;
    padding: 10px 0;
    font-size: 12px;
    margin-top: 29px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 68px;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.88);
    border-radius: 4px;
    padding: 19px 0;
    font-size: 23px;
    margin-top: 58px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 44px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
    padding: 12px 0;
    font-size: 15px;
    margin-top: 41px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 34px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.88);
    border-radius: 2px;
    padding: 10px 0;
    font-size: 12px;
    margin-top: 30px;
  }
`
