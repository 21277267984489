import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import cloudTitle from 'img/pad/cloud-title.svg'
import arrowImg from 'img/pad/arrow.svg'
import plusImg from 'img/pad/plsu-img.svg'
import { ReactComponent as AppIcon } from 'img/pad/app-icon.svg'
import { ReactComponent as ApplyIcon } from 'img/pad/apply-icon.svg'
import coinImg from 'img/pad/coin.svg'
import cloudStarBg from 'img/pad/cloud-star-bg.svg'
import cloudStarBgSm from 'img/pad/cloud-star-bg-sm.svg'
import cloudStarBgSmm from 'img/pad/cloud-star-bg-smm.svg'
import moonBg from 'img/pad/moon-bg.png'
import moonBgSm from 'img/pad/moon-bg-sm.png'
import moonBgSmm from 'img/pad/moon-bg-smm.png'
import lineImg from 'img/pad/line-img.svg'
import { APPLY_FOR_IDO_LINK, APP_LINK } from 'config/app'

function Main() {
  return (
    <Root>
      <Container>
        <CloudStarsBg />
        <MoonBg />
        <Title>
          NS<span>PAD</span>
        </Title>
        <Row>
          <Arrow />
          <SubTitle>NEAR’s DAO-Governed Innovation Launchpad</SubTitle>
        </Row>
        <TextBlock>
          <Plus />
          <Text>
            We’ve built a next generation launchpad augmented with algorithmic
            tiers,{' '}
            <span>
              deflationary tokenomics and a first-of-its-kind community-centric
              automated diversification engine.
            </span>
            <Buttons>
              <AppBtn href={APP_LINK} target={'_blank'}>
                <BtnText>NS APP</BtnText>
                <AppIcon />
              </AppBtn>
              <ApplyBtn href={APPLY_FOR_IDO_LINK} target={'_blank'}>
                <BtnText>Apply for IDO</BtnText>
                <ApplyIcon />
              </ApplyBtn>
            </Buttons>
          </Text>
        </TextBlock>
        <BottomRow>
          {/*<BuyBtn>*/}
          {/*  <BuyTitle>Buy $NSTART</BuyTitle>*/}
          {/*  <BuySubTitle>7 ways to buy</BuySubTitle>*/}
          {/*</BuyBtn>*/}
        </BottomRow>
      </Container>
      <Border />
      <Line />
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 138px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 115px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 105px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 94px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 75px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 0;
    margin-top: -6px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: -5px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: -4px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'PressStart2P';
  width: 654px;
  font-weight: 400;
  font-size: 134px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  margin-bottom: 4px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 547px;
    font-size: 112px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 488px;
    font-size: 100px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 430px;
    font-size: 88px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 352px;
    font-size: 72px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 313px;
    font-size: 64px;
    margin-bottom: 481px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 235px;
    font-size: 48px;
    margin-bottom: 502px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 186px;
    font-size: 38px;
    margin-bottom: 398px;
  }
  span {
    color: #74e7bf;
  }
  &:before {
    content: '';
    position: absolute;
    top: 45px;
    right: -194px;
    width: 269px;
    height: 118px;
    background: url('${cloudTitle}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      top: 38px;
      right: -161px;
      width: 226px;
      height: 99px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 34px;
      right: -145px;
      width: 204px;
      height: 90px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 30px;
      right: -127px;
      width: 181px;
      height: 80px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 24px;
      right: -106px;
      width: 145px;
      height: 64px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 17px;
      right: -95px;
      width: 129px;
      height: 56px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      top: 11px;
      right: -70px;
      width: 96px;
      height: 42px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: 9px;
      right: -56px;
      width: 76px;
      height: 33px;
    }
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.sm} {
    align-items: flex-start;
  }
`

const Arrow = styled.div`
  width: 54px;
  height: 8px;
  margin-right: 20px;
  background: url('${arrowImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 44px;
    height: 7px;
    margin-right: 17px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 40px;
    height: 6px;
    margin-right: 18px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 35px;
    height: 5px;
    margin-right: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 29px;
    height: 4px;
    margin-right: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 40px;
    height: 6px;
    margin-right: 24px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 24px;
    height: 3px;
    margin-right: 13px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 19px;
    height: 3px;
    margin-right: 10px;
  }
`

const SubTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 37px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  opacity: 0.3;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 31px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 322px;
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 31px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 255px;
    font-size: 25px;
  }
`

const TextBlock = styled.div`
  margin-left: 76px;
  display: flex;
  margin-top: 48px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 41px;
    margin-left: 63px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 37px;
    margin-left: 58px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 33px;
    margin-left: 53px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 26px;
    margin-left: 42px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-top: 28px;
    margin-left: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 9px;
  }
`

const Plus = styled.div`
  width: 21px;
  height: 21px;
  margin-top: 5px;
  margin-right: 27px;
  background: url('${plusImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 17px;
    height: 17px;
    margin-top: 5px;
    margin-right: 24px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 20px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 14px;
    height: 14px;
    margin-top: 3px;
    margin-right: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 11px;
    height: 11px;
    margin-top: 4px;
    margin-right: 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 30px;
    height: 30px;
    margin-top: 8px;
    margin-right: 34px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-right: 14px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 19px;
    height: 19px;
    margin-top: 5px;
    margin-right: 11px;
  }
`

const Text = styled.div`
  font-family: 'Manrope';
  width: 621px;
  font-weight: 600;
  font-size: 23px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  ${({ theme }) => theme.adaptive.xl} {
    width: 550px;
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 490px;
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 433px;
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 345px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 493px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 322px;
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 255px;
    font-size: 16px;
  }
  span {
    font-weight: 300;
  }
`

const Buttons = styled.div`
  position: relative;
  display: flex;
  margin-top: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 43px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 37px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 27px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 45px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 39px;
  }
`

const BtnText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
  }
`

const BtsCss = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  height: 60px;
  border: 1px solid #090909;
  box-shadow: 3px 3px 0px #353535;
  border-radius: 4px;
  padding: 0 17px 0 21px;
  transition: 0.4s;
  cursor: pointer;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    width: 218px;
    height: 50px;
    box-shadow: 3px 3px 0px #353535;
    border-radius: 3px;
    padding: 0 14px 0 17px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 196px;
    height: 45px;
    box-shadow: 2px 2px 0px #353535;
    border-radius: 3px;
    padding: 0 12px 0 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 175px;
    height: 40px;
    box-shadow: 2px 2px 0px #353535;
    border-radius: 3px;
    padding: 0 11px 0 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 140px;
    height: 32px;
    box-shadow: 2px 2px 0px #353535;
    border-radius: 2px;
    padding: 0 9px 0 11px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 0;
    right: 0;
    width: 540px;
    height: 45px;
    box-shadow: 2px 2px 0px #353535;
    border-radius: 4px;
    padding: 0 14px 0 20px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 348px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 276px;
    height: 36px;
    padding: 0 11px 0 16px;
    box-shadow: 1px 1px 0px #353535;
    border-radius: 3px;
  }
  svg {
    display: block;
    transition: 0.4s;
    fill: #090909;
  }
  &:hover {
    background: #ff65fa;
    ${BtnText} {
      color: #ffffff;
    }
    svg {
      fill: #ffffff;
    }
  }
`

const AppBtn = styled.a`
  ${BtsCss};
  background: #ff85fb;
  margin-right: 26px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-right: 22px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-right: 20px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-right: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-right: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-right: 0;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-bottom: 18px;
  }
  svg {
    width: 32px;
    height: 32px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 27px;
      height: 27px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 24px;
      height: 24px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 22px;
      height: 22px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 17px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 22px;
      height: 22px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 18px;
      height: 18px;
    }
  }
`

const ApplyBtn = styled.a`
  ${BtsCss};
  background: #f4f3eb;
  ${({ theme }) => theme.adaptive.sm} {
    top: 67px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 54px;
  }
  svg {
    width: 23px;
    height: 25px;
    fill: black;
    ${({ theme }) => theme.adaptive.xl} {
      width: 19px;
      height: 21px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 17px;
      height: 19px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 15px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 12px;
      height: 13px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 18px;
      height: 20px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 16px;
      height: 18px;
    }
  }
`

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 99px;
  height: 76px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 83px;
    height: 64px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 76px;
    height: 58px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 67px;
    height: 55px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 38px;
    height: 60px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 399px;
    left: 0;
    display: block;
    margin-top: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 399px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 306px;
  }
`

const BuyBtn = styled.div`
  position: relative;
  padding: 15px 19px 0;
  width: 198px;
  height: 76px;
  background: #090909;
  border: 1px solid #f4f3eb;
  box-shadow: 5px 5px 0px #262524;
  border-radius: 3px;
  cursor: pointer;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 13px 16px 0;
    width: 166px;
    height: 64px;
    box-shadow: 4px 4px 0px #262524;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 11px 14px 0;
    width: 150px;
    height: 58px;
    box-shadow: 4px 4px 0px #262524;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 10px 13px 0;
    width: 150px;
    height: 55px;
    box-shadow: 3px 3px 0px #262524;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 11px 13px 0;
    width: 160px;
    height: 60px;
    box-shadow: 3px 3px 0px #262524;
    border-radius: 3px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 16px;
    right: 22px;
    width: 23px;
    height: 20px;
    background: url('${coinImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      top: 13px;
      right: 17px;
      width: 20px;
      height: 18px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 12px;
      right: 16px;
      width: 18px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 11px;
      right: 16px;
      width: 16px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 13px;
      right: 13px;
      width: 16px;
      height: 14px;
    }
  }
`

const BuyTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #f4f3eb;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 16px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 14px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 16px;
    margin-bottom: 2px;
  }
`

const BuySubTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #ffffff;
  opacity: 0.2;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 14px;
  }
`

const CloudStarsBg = styled.div`
  position: absolute;
  width: 1916px;
  height: 452px;
  background: url('${cloudStarBg}') center no-repeat;
  background-size: cover;
  top: -120px;
  left: -50px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 1610px;
    height: 380px;
    top: -101px;
    left: -42px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 1451px;
    height: 342px;
    top: -91px;
    left: -37px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 1290px;
    height: 304px;
    top: -81px;
    left: -32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 1032px;
    height: 244px;
    top: -65px;
    left: -26px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 932px;
    height: 258px;
    top: 58px;
    left: 294px;
    background: url('${cloudStarBgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 932px;
    height: 255px;
    top: 58px;
    left: 294px;
    background: url('${cloudStarBgSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 739px;
    height: 202px;
    top: 46px;
    left: 233px;
  }
`

const MoonBg = styled.div`
  position: absolute;
  width: 1978px;
  height: 919px;
  background: url('${moonBg}') center no-repeat;
  background-size: cover;
  top: -190px;
  left: -117px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 1662px;
    height: 771px;
    top: -159px;
    left: -98px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 1498px;
    height: 695px;
    top: -143px;
    left: -88px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 1332px;
    height: 618px;
    top: -128px;
    left: -77px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 1066px;
    height: 495px;
    top: -102px;
    left: -62px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 1169px;
    height: 461px;
    top: 43px;
    left: -506px;
    background: url('${moonBgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 1066px;
    height: 467px;
    top: 38px;
    left: -506px;
    background: url('${moonBgSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 845px;
    height: 370px;
    top: 30px;
    left: -401px;
  }
`

const Border = styled.div`
  width: 100%;
  height: 6px;
  background: #222220;
  margin-top: 61px;
  ${({ theme }) => theme.adaptive.xl} {
    height: 5px;
    margin-top: 52px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 5px;
    margin-top: 56px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 4px;
    margin-top: 37px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 3px;
    margin-top: 35px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 488px;
    left: 0;
    height: 3px;
    margin-top: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 491px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 389px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 46px;
  background: url('${lineImg}') repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    height: 39px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 31px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 25px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    top: 494px;
    left: 0;
    height: 25px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 496px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 394px;
  }
`
