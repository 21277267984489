import { Game } from './Game'
import { Player } from './Player'
import { detectRectangleCollision } from './ColisionDetection'
import { Sound } from './Sound'

export class Anchor {
  private game: Game
  private width: number
  private height: number
  public x: number
  public y: number
  markedForDelete: boolean
  private img: any
  private isColision: boolean
  private currRotation: number
  private isBg: boolean
  private imgW: number
  private imgH: number
  public hitX: number
  public hitY: number

  constructor(
    game: Game,
    width: number,
    height: number,
    img: any,
    isBg: boolean,
    y?: number,
    x?: number
  ) {
    this.game = game
    this.width = width - 24 * this.game.ratio
    this.height = height - 24 * this.game.ratio
    this.imgW = width
    this.imgH = height
    this.x = this.getXPos(x)
    this.y = y ? y : -100 * this.game.ratio
    this.hitX = this.x + 12 * this.game.ratio
    this.hitY = this.y + 12 * this.game.ratio
    this.markedForDelete = false
    this.img = img
    this.isColision = false
    this.currRotation = game.RandomIntInRange(-30, 30)
    this.isBg = isBg
  }
  update(deltaTime: number, player: Player) {
    this.hitX = this.x + 12 * this.game.ratio
    this.hitY = this.y + 12 * this.game.ratio

    let playerRect = {
      x: player.hitX,
      width: player.width,
      y: player.hitY,
      height: player.height,
      currRotation: player.currRotation,
    }
    let anchorRect = {
      x: this.hitX,
      width: this.width,
      y: this.hitY,
      height: this.height,
      currRotation: this.currRotation,
    }
    let groundRect = {
      x: 0,
      width: this.game.width,
      y: this.game.height + 10 * this.game.ratio,
      height: 1,
      currRotation: 0,
    }
    let leftEdge = {
      x: -100,
      width: 1,
      y: 0,
      height: this.game.height,
      currRotation: 0,
    }
    if (detectRectangleCollision(anchorRect, leftEdge)) {
      this.markedForDelete = true
    }

    if (!this.isColision) {
      if (detectRectangleCollision(anchorRect, playerRect)) {
        if (!this.game.isMute) {
          let sound = new Sound(
            this.game.audioCtx,
            this.game.buffer.getSoundByIndex(1)
          )
          sound.play()
        }
        this.isColision = true
        this.game.colisionsDetect()
        this.markedForDelete = true
      }
    }
    if (!detectRectangleCollision(anchorRect, groundRect)) {
      this.y += this.game.horizontalSpeed * deltaTime
    }
    this.x -= this.game.groundSpeed * deltaTime
  }

  getXPos(x?: number) {
    let xPos = x ? x : Math.random() * this.game.width * 2
    this.game.fallingEnemies.forEach((enemy) => {
      if (xPos <= enemy.x + enemy.width && xPos >= enemy.x) {
        xPos = (enemy.x + enemy.width) * 3
      }
    })
    return xPos
  }

  draw(context: CanvasRenderingContext2D) {
    context.save()
    context.globalAlpha = this.isBg ? 0.14 : 1
    context.translate(this.x + this.imgW / 2, this.y + this.imgH / 2)
    context.rotate((this.currRotation * Math.PI) / 180)
    context.translate(-(this.x + this.imgW / 2), -(this.y + this.imgH / 2))
    context.drawImage(this.img, this.x, this.y, this.imgW, this.imgH)
    context.restore()
  }
}
