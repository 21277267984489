import { Player } from './Player'
import { InputHandler } from './input'
import { Anchor } from './Anchor'
import { Cactus } from './Cactus'
import { Reef } from './Reef'
import { Buffer } from './Buffer'
import { Plastic } from './Plastic'
import { Coin } from './Coin'
import { Ground } from './Ground'
import { Hole } from './Hole'
import { states, statesList } from './playerState'
import { PlayerOverlay } from './PlayerOverlay'
import { Live } from './Live'
import coinSound from 'components/game/audio/coin.mp3'
import heartSound from 'components/game/audio/heart.mp3'
import vomitSound from 'components/game/audio/vomit.mp3'
import cdSound from 'components/game/audio/cd-disk.mp3'
import bottleSound from 'components/game/audio/bottle.mp3'
import gameOverSound from 'components/game/audio/game_over.mp3'
import anchorSound from 'components/game/audio/anchor.mp3'
import { Sound } from './Sound'

export class Game {
  canvas: HTMLCanvasElement
  player: Player
  ctx: CanvasRenderingContext2D
  width: number
  height: number
  input: InputHandler
  private lastTime: number
  private coinImg: any
  private groundEnemies: any[]
  private groundTimer: number
  private groundInterval: number
  fallingEnemies: any[]
  private fallingTimer: number
  private fallingInterval: number
  live: number
  private cdImg: any
  private binImg: any
  private plastic: Plastic[]
  private plasticTimer: number
  private plasticInterval: number
  coinsVal: number
  groundSpeed: number
  horizontalSpeed: number
  private coins: Coin[]
  private isCoinSpawn: boolean
  private coinTime: number
  private anchorImg: any
  private liveHandler: (val: number) => void
  private coinsHandler: (val: number) => void
  groundBg: Ground
  private holes: Hole[]
  private holeTimer: number
  private holeInterval: number
  ratio: number
  public maxHeight: number
  private animationId: number
  private isPause: boolean
  private playerOverlayImg: any
  playerOverlay: PlayerOverlay
  private diamondImg: any
  private heartImg: any
  private hearts: Live[]
  private heartsTimer: number
  private heartsInterval: number
  private speedVal: number
  private horizontalSpeedVal: number
  public isSlow: boolean
  private loadedImgs: number
  playerImgs: any[]
  isMute: boolean
  audioCtx: AudioContext
  buffer: Buffer
  private resizeTimeout: any
  constructor(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    playerOverlayImg: any,
    cdImg: any,
    binImg: any,
    coinImg: any,
    diamondImg: any,
    anchorImg: any,
    heartImg: any,
    liveHandler: (val: number) => void,
    coinsHandler: (val: number) => void
  ) {
    this.canvas = canvas
    this.ratio = this.getRatio()
    this.canvas.width = window.innerWidth
    this.canvas.height = 600 * this.ratio
    this.ctx = ctx
    this.width = this.canvas.width
    this.height = this.canvas.height
    this.player = new Player(this)
    this.input = new InputHandler()
    this.lastTime = 0
    this.playerOverlayImg = playerOverlayImg
    this.playerOverlay = new PlayerOverlay(this.player, playerOverlayImg)
    this.coinImg = coinImg
    this.heartImg = heartImg
    this.diamondImg = diamondImg
    this.cdImg = cdImg
    this.binImg = binImg
    this.anchorImg = anchorImg
    this.groundEnemies = []
    this.plastic = []
    this.hearts = []
    this.fallingEnemies = []
    this.plasticTimer = 0
    this.plasticInterval = 4000 * this.ratio
    this.groundTimer = 0
    this.groundInterval = 2000 * this.ratio
    this.fallingTimer = 0
    this.fallingInterval = 1500 * this.ratio
    this.heartsTimer = 0
    this.heartsInterval = 5000 * this.ratio
    this.holeTimer = 750
    this.holeInterval = 2000 * this.ratio
    this.live = 3
    this.coinsVal = 0
    this.groundSpeed = 0.3
    this.horizontalSpeed = 0.05
    this.isCoinSpawn = true
    this.coinTime = this.RandomIntInRange(400 * this.ratio, 1400 * this.ratio)
    this.coins = []
    this.liveHandler = liveHandler
    this.coinsHandler = coinsHandler
    this.groundBg = new Ground(this)
    this.holes = []
    this.maxHeight = 100 * this.ratio
    this.animationId = 0
    this.isPause = false
    this.speedVal = 0.3
    this.horizontalSpeedVal = 0.05
    this.isSlow = false
    this.isMute = false
    this.playerImgs = []
    this.loadedImgs = 0
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    this.buffer = new Buffer(this.audioCtx, [
      coinSound,
      anchorSound,
      bottleSound,
      cdSound,
      vomitSound,
      gameOverSound,
      heartSound,
    ])
  }
  updateOptions() {
    this.ratio = this.getRatio()
    this.canvas.width = window.innerWidth
    this.canvas.height = 600 * this.ratio
    this.width = this.canvas.width
    this.height = this.canvas.height
    this.plasticInterval = 4000 * this.ratio
    this.groundInterval = 2000 * this.ratio
    this.fallingInterval = 1500 * this.ratio
    this.heartsInterval = 5000 * this.ratio
    this.holeInterval = 2000 * this.ratio
    this.maxHeight = 100 * this.ratio
    this.coinTime = this.RandomIntInRange(400 * this.ratio, 1400 * this.ratio)
    this.groundBg = new Ground(this)
  }
  clear() {
    this.coins = []
    this.groundBg = new Ground(this)
    this.holes = []
    this.coinTime = this.RandomIntInRange(400 * this.ratio, 1400 * this.ratio)
    this.groundEnemies = []
    this.plastic = []
    this.hearts = []
    this.fallingEnemies = []
    this.plasticTimer = 0
    this.groundTimer = 0
    this.fallingTimer = 0
    this.heartsTimer = 0
    this.holeTimer = 750
    this.playerOverlay = new PlayerOverlay(this.player, this.playerOverlayImg)
    this.player = new Player(this)
    this.input = new InputHandler()
    this.lastTime = 0
    this.speedVal = 0.3
    this.horizontalSpeedVal = 0.05
    this.coinsVal = 0
    this.groundSpeed = 0.3
    this.horizontalSpeed = 0.05
    this.live = 3
    this.playerImgs = []
    this.loadedImgs = 0
  }
  update(deltaTime: number) {
    if (this.isSlow) {
      if (this.groundSpeed > this.groundSpeed / 2) {
        this.groundSpeed -= 0.01
      }
      if (this.horizontalSpeed > this.horizontalSpeed / 2) {
        this.horizontalSpeed -= 0.01
      }
      if (this.groundSpeed <= this.groundSpeed / 2) {
        this.isSlow = false
      }
    } else {
      if (this.groundSpeed < this.speedVal + ((this.coinsVal / 50) | 0) / 20) {
        this.groundSpeed += 0.01
      }
      if (
        this.horizontalSpeed <
        this.horizontalSpeedVal + ((this.coinsVal / 50) | 0) / 40
      ) {
        this.horizontalSpeed += 0.01
      }
    }
    this.player.update(this.input.keys, deltaTime, this.ctx)
    let timerDelta = (deltaTime * this.groundSpeed) / this.speedVal
    if (this.holeTimer > this.holeInterval) {
      this.addHoles()
      this.holeTimer = 0
    } else {
      this.holeTimer += timerDelta
    }
    if (this.groundTimer > this.groundInterval) {
      this.addGroundEnemy()
      this.groundTimer = 0
      this.isCoinSpawn = true
    } else {
      this.groundTimer += timerDelta
    }
    if (
      this.groundTimer > this.groundInterval - this.coinTime &&
      this.isCoinSpawn
    ) {
      this.isCoinSpawn = false
      this.coinTime = this.RandomIntInRange(400 * this.ratio, 1400 * this.ratio)
      this.addCoins()
    }
    if (this.plasticTimer > this.plasticInterval) {
      this.addPlastic()
      this.plasticTimer = 0
    } else {
      this.plasticTimer += timerDelta
    }
    if (this.fallingTimer > this.fallingInterval) {
      this.addFallingEnemy()
      this.fallingTimer = 0
    } else {
      this.fallingTimer += timerDelta
    }
    if (this.heartsTimer > this.heartsInterval) {
      this.addHeart()
      this.heartsTimer = 0
    } else {
      this.heartsTimer += timerDelta
    }
    this.groundBg.update(deltaTime)
    this.groundEnemies.forEach((enemy) => {
      enemy.update(deltaTime, this.player)
      if (
        enemy.rectArr.length === 0 ||
        (this.live > 0 && enemy.markedForDelete)
      )
        this.groundEnemies.splice(this.groundEnemies.indexOf(enemy), 1)
    })

    this.holes.forEach((hole) => {
      hole.update(deltaTime)
      if (hole.rectArr.length === 0)
        this.holes.splice(this.holes.indexOf(hole), 1)
    })

    this.fallingEnemies.forEach((enemy) => {
      enemy.update(deltaTime, this.player)
      if (enemy.markedForDelete && this.live > 0)
        this.fallingEnemies.splice(this.fallingEnemies.indexOf(enemy), 1)
    })
    this.plastic.forEach((plastic) => {
      plastic.update(deltaTime, this.player)
      if (plastic.markedForDelete && this.live > 0)
        this.plastic.splice(this.plastic.indexOf(plastic), 1)
    })
    this.coins.forEach((coin) => {
      coin.update(deltaTime, this.player)
      if (coin.markedForDelete) this.coins.splice(this.coins.indexOf(coin), 1)
    })
    this.hearts.forEach((live) => {
      live.update(deltaTime, this.player)
      if (live.markedForDelete) this.hearts.splice(this.hearts.indexOf(live), 1)
    })
  }
  draw(context: CanvasRenderingContext2D) {
    this.player.draw(context)
    this.groundBg.draw(context)
    this.groundEnemies.forEach((enemy) => {
      enemy.draw(context)
    })
    this.holes.forEach((hole) => {
      hole.draw(context)
    })
    this.fallingEnemies.forEach((enemy) => {
      enemy.draw(context)
    })
    this.plastic.forEach((s) => {
      s.draw(context)
    })
    this.coins.forEach((coin) => {
      coin.draw(context)
    })
    this.hearts.forEach((live) => {
      live.draw(context)
    })
    //this.playerOverlay.draw(context)
  }

  addPlastic(x?: number) {
    let type = this.RandomIntInRange(1, 2)
    if (type === 1) {
      this.plastic.push(new Plastic(this, this.cdImg, 7, x))
    } else if (type === 2) {
      this.plastic.push(new Plastic(this, this.binImg, 3, x))
    }
  }

  addFallingEnemy(x?: number, y?: number) {
    let anchorType = this.RandomIntInRange(1, 3)
    if (anchorType === 1) {
      this.fallingEnemies.push(
        new Anchor(
          this,
          105 * this.ratio,
          127 * this.ratio,
          this.anchorImg,
          false,
          y,
          x
        )
      )
    } else if (anchorType === 2) {
      this.fallingEnemies.push(
        new Anchor(
          this,
          85 * this.ratio,
          103 * this.ratio,
          this.anchorImg,
          false,
          y,
          x
        )
      )
    } else {
      this.fallingEnemies.push(
        new Anchor(
          this,
          66 * this.ratio,
          80 * this.ratio,
          this.anchorImg,
          false,
          y,
          x
        )
      )
    }
  }

  addHoles(x?: number) {
    this.holes.push(new Hole(this, 10 * this.ratio, x))
  }

  addGroundEnemy(x?: number) {
    let type = this.RandomIntInRange(1, 2)
    if (type === 1) {
      this.groundEnemies.push(new Cactus(this, 10 * this.ratio, x))
    } else if (type === 2) {
      this.groundEnemies.push(new Reef(this, 10 * this.ratio, x))
    }
  }

  addHeart() {
    let chance = this.RandomIntInRange(1, 5)
    if (chance === 5) {
      this.hearts.push(new Live(this, this.heartImg))
    }
  }

  addCoins(x?: number) {
    let type = this.RandomIntInRange(1, 11)
    let length = this.RandomIntInRange(1, 3)
    let y = this.maxHeight + Math.random() * this.height
    if (y > this.height - 120 * this.ratio) y = this.height - 120 * this.ratio
    if (y < this.maxHeight + 120 * this.ratio)
      y = this.maxHeight + 120 * this.ratio + this.player.height / 2
    if (type < 6) {
      for (let i = 0; i < length; i++) {
        let dx = 0
        dx += 60 * i * this.ratio
        this.coins.push(new Coin(this, y, dx, 0, this.coinImg, 1, x))
      }
    } else if (type < 11) {
      for (let i = 0; i < length; i++) {
        let dy = 0
        dy -= 60 * i * this.ratio
        this.coins.push(new Coin(this, y, 0, dy, this.coinImg, 1, x))
      }
    } else {
      this.coins.push(
        new Coin(
          this,
          this.height - 120 * this.ratio,
          0,
          0,
          this.diamondImg,
          50,
          x
        )
      )
    }
  }

  colisionsDetect = () => {
    this.player.setState(states.COLLISION)
    if (this.live > 0) {
      this.live = this.live - 1
      this.liveHandler(this.live)
      this.isSlow = true
    }
  }

  pause() {
    this.isPause = true
    setTimeout(() => {
      this.isPause = false
    }, 500)
  }

  coinColisionsDetect = (val: number) => {
    this.coinsVal += val
    this.coinsHandler(this.coinsVal)
  }

  heartColisionsDetect = () => {
    if (!this.isMute) {
      let sound = new Sound(this.audioCtx, this.buffer.getSoundByIndex(6))
      sound.play()
    }
    if (this.live < 3) {
      this.live += 1
    }
    this.liveHandler(this.live)
  }

  plasticColisionsDetect = (coinVal: number) => {
    if (this.coinsVal > coinVal) {
      this.coinsVal = this.coinsVal - coinVal
      if (this.coinsVal < 0) this.coinsVal = 0
    } else {
      if (this.live > 0) {
        this.coinsVal = 0

        this.live = this.live - 1
        this.isSlow = true
        this.liveHandler(this.live)
      }
    }
    this.coinsHandler(this.coinsVal)
  }

  onTouch(key: string) {
    this.input.keys.push(key)
  }

  deleteKey(key: string) {
    this.input.keys.splice(this.input.keys.indexOf(key), 1)
  }

  muteHandler() {
    this.isMute = !this.isMute
  }

  unlockAudioContext(audioCtx: AudioContext) {
    if (audioCtx.state !== 'suspended') return
    const b = document.body
    const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
    events.forEach((e) => b.addEventListener(e, unlock, false))
    function unlock() {
      audioCtx.resume().then(clean)
    }
    function clean() {
      events.forEach((e) => b.removeEventListener(e, unlock))
    }
  }

  init = () => {
    console.log('init')
    this.unlockAudioContext(this.audioCtx)
    this.buffer.loadAll()
    this.clear()
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.addPlastic(
      this.RandomIntInRange(
        1000 * this.ratio,
        this.canvas.width - 300 * this.ratio
      )
    )
    this.addFallingEnemy(
      this.RandomIntInRange(800 * this.ratio, 850 * this.ratio),
      this.RandomIntInRange(this.height - 150 * this.ratio, 0)
    )
    this.addGroundEnemy(
      this.RandomIntInRange(350 * this.ratio, 400 * this.ratio)
    )
    this.addGroundEnemy(
      this.RandomIntInRange(1000 * this.ratio, 1200 * this.ratio)
    )
    this.addCoins(this.RandomIntInRange(500 * this.ratio, 600 * this.ratio))
    this.addCoins(this.RandomIntInRange(1500 * this.ratio, 1600 * this.ratio))
    this.addHoles(this.RandomIntInRange(600 * this.ratio, 850 * this.ratio))
    statesList.forEach((state) => {
      for (let i = 0; i < state.frames; i++) {
        let img = new Image()
        img.src = state.images[i]
        img.name = `${state.name}-${i}`
        this.playerImgs.push(img)
      }
    })
    this.playerImgs.forEach((img) => {
      if (img.complete && img.naturalHeight !== 0) {
        ++this.loadedImgs
        if (this.loadedImgs === this.playerImgs.length - 2) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.draw(this.ctx)
        }
      } else {
        img.onload = () => {
          ++this.loadedImgs
          if (this.loadedImgs === this.playerImgs.length - 2) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.draw(this.ctx)
          }
        }
      }
    })

    window.addEventListener('resize', (e) => {
      this.handleResize()
    })
  }

  animate = (timeStamp: number) => {
    this.animationId = requestAnimationFrame(this.animate)
    const deltaTime = timeStamp - this.lastTime
    if (this.lastTime > 0 && !this.isPause) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.update(deltaTime)
      this.draw(this.ctx)
    }
    if (this.live === 0) {
      if (!this.isMute) {
        let sound = new Sound(this.audioCtx, this.buffer.getSoundByIndex(5))
        sound.play()
      }
      cancelAnimationFrame(this.animationId)
    }
    this.lastTime = timeStamp
  }

  RandomIntInRange = (min: number, max: number) => {
    return Math.round(Math.random() * (max - min) + min)
  }

  private handleResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      this.updateOptions()
      this.draw(this.ctx)
    }, 200)
  }

  getRatio() {
    if (window.innerWidth <= 1300) return 0.53
    if (window.innerWidth <= 1380) return 0.67
    if (window.innerWidth <= 1480) return 0.75
    if (window.innerWidth <= 1780) return 0.83
    else return 1
  }
}
