import { FishEnemy } from './Fish'
import { BgAnchor } from './Anchor'

export class Background {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  width: number
  height: number
  private lastTime: number
  private fps: number
  private fpsInterval: number
  private fishImg: any
  private bgEnemies: FishEnemy[]
  private fishInterval: number
  private fishTimer: number
  private bgAnchorsTimer: number
  private bgAnchorsInterval: number
  private bgAnchors: BgAnchor[]
  groundSpeed: number
  private anchorImg: any
  private isSlow: boolean

  constructor(
    width: number,
    height: number,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    fishImg: any,
    anchorImg: any
  ) {
    this.canvas = canvas
    this.ctx = ctx
    this.width = width
    this.height = height
    this.lastTime = 0
    this.fps = 60
    this.fpsInterval = 1000 / this.fps
    this.fishImg = fishImg
    this.anchorImg = anchorImg
    this.bgEnemies = []
    this.bgAnchors = []
    this.fishTimer = 0
    this.fishInterval = 400
    this.bgAnchorsTimer = 0
    this.bgAnchorsInterval = 4000
    this.groundSpeed = 0.3
    this.isSlow = false
  }
  slow() {
    this.isSlow = true
  }
  update(deltaTime: number) {
    if (this.isSlow) {
      if (this.groundSpeed > this.groundSpeed / 2) {
        this.groundSpeed -= 0.01
      }
      if (this.groundSpeed <= this.groundSpeed / 2) {
        this.isSlow = false
      }
    } else {
      if (this.groundSpeed < 0.3) {
        this.groundSpeed += 0.01
      }
    }
    if (this.fishTimer > this.fishInterval) {
      this.addFishEnemy()
      this.fishTimer = 0
    } else {
      this.fishTimer += deltaTime
    }
    // if (this.bgAnchorsTimer > this.bgAnchorsInterval) {
    //   this.addBgAnchorEnemy()
    //   this.bgAnchorsTimer = 0
    // } else {
    //   this.bgAnchorsTimer += deltaTime
    // }
    this.bgAnchors.forEach((anchor) => {
      anchor.update(deltaTime)
      if (anchor.markedForDelete)
        this.bgAnchors.splice(this.bgAnchors.indexOf(anchor), 1)
    })
    this.bgEnemies.forEach((fish) => {
      fish.update(deltaTime)
      if (fish.markedForDelete)
        this.bgEnemies.splice(this.bgEnemies.indexOf(fish), 1)
    })
  }
  draw(context: CanvasRenderingContext2D) {
    this.bgEnemies.forEach((enemy) => {
      enemy.draw(context, this.fishImg)
    })
    this.bgAnchors.forEach((anchor) => {
      anchor.draw(context)
    })
  }
  addFishEnemy() {
    const type = this.RandomIntInRange(1, 3)
    switch (type) {
      case 1:
        this.bgEnemies.push(new FishEnemy(this, 44, 21))
        break
      case 2:
        this.bgEnemies.push(new FishEnemy(this, 29, 14))
        break
      case 3:
        this.bgEnemies.push(new FishEnemy(this, 14, 7))
        break
      default:
        this.bgEnemies.push(new FishEnemy(this, 44, 21))
        break
    }
  }

  // addBgAnchorEnemy() {
  //   this.bgAnchors.push(new BgAnchor(this, 33, 53, this.anchorImg, true))
  // }

  animate = (timeStamp: number) => {
    requestAnimationFrame(this.animate)
    const deltaTime = timeStamp - this.lastTime
    if (this.lastTime > 0) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.update(deltaTime)
      this.draw(this.ctx)
    }
    this.lastTime = timeStamp
  }

  RandomIntInRange = (min: number, max: number) => {
    return Math.round(Math.random() * (max - min) + min)
  }
}
