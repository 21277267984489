import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import spheresImg from 'img/pad/pool/sphers.png'
import spheresImgSm from 'img/pad/pool/sphers.svg'
import spheresImgSmm from 'img/pad/pool/sphers-smm.svg'
import plusImg from 'img/pad/pool/plus.svg'
import fishesImg from 'img/pad/pool/fishes.png'

function Pool() {
  return (
    <Root>
      <Spheres />
      <Container>
        <Fishes />
        <Title>NEAR Index Alpha Pool</Title>
        <SubTitle>
          <span>NEARIA Pool</span>, a special basket of curated projects{' '}
        </SubTitle>
        <Text>
          that is collectively owned by the community and grows with every
          pre-money round funded through NEARStarter and every IDO filled
          through the NEARStarter Launchpad.
        </Text>
        <List>
          <Row>
            <Item>
              <Number>I</Number>
              <ItemText>
                <span>$NEARIA</span> is your automatically compounding NEAR
                innovation index. Only the best projects end up in $NEARIA.
              </ItemText>
            </Item>
            <Item>
              <Number>II</Number>
              <ItemText>
                Tokens of every incubated project or any other project that
                NSDAO selects is added to the <span>NEAR Index Alpha Pool</span>
                .
              </ItemText>
            </Item>
            <Item>
              <Number>III</Number>
              <ItemText>
                The only way to withdraw tokens from the NS Alpha Pool is to
                burn $NEARIA.
              </ItemText>
            </Item>
            <Item>
              <Number>IV</Number>
              <ItemText>
                ANYONE staking $NSTART is guaranteed to gain exposure to every
                IDO through $NEARIA.
              </ItemText>
            </Item>
          </Row>
          <Row>
            <Item>
              <Number>V</Number>
              <ItemText>
                Stakers of <span>$NSTART</span> farm <span>$NEARIA</span> .
                $NEARIA are shares of the Alpha pool.
              </ItemText>
            </Item>
            <Item>
              <Number>VI</Number>
              <ItemText>
                <span>$NEARIA</span> minting is algorithmic with regular
                halvings. No pre-mines. Fair distribution rewarding{' '}
                <span>$NSTART</span> stakers.
              </ItemText>
            </Item>
          </Row>
        </List>
      </Container>
    </Root>
  )
}

export default Pool

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 113px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 95px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 86px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 76px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 61px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 205px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 215px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 171px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'PressStart2P';
  font-weight: 400;
  font-size: 80px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  margin-bottom: 47px;
  width: fit-content;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 68px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 62px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 55px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 44px;
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 52px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 36px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 28px;
    margin-bottom: 14px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -43px;
    right: -96px;
    width: 59px;
    height: 59px;
    background: url('${plusImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xl} {
      top: -36px;
      right: -66px;
      width: 50px;
      height: 50px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: -33px;
      right: -46px;
      width: 45px;
      height: 45px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: -30px;
      right: -45px;
      width: 40px;
      height: 40px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: -23px;
      right: -35px;
      width: 32px;
      height: 32px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 547px;
      right: -37px;
      width: 32px;
      height: 32px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      content: unset;
    }
  }
`

const SubTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 800;
  font-size: 37px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  margin-bottom: 9px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 31px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 37px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 37px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 29px;
  }
  span {
    color: #ff85fb;
  }
`

const Text = styled.div`
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 21px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  width: 835px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 19px;
    width: 728px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
    width: 656px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 15px;
    width: 583px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    width: 467px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const List = styled.div`
  display: flex;
  margin-top: 57px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 48px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 45px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 41px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 31px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin-top: 55px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 38px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 30px;
  }
`

const Item = styled.div`
  display: flex;
  margin-bottom: 34px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    display: block;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-bottom: 30px;
  }
`

const Number = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid #4d4d4a;
  border-radius: 3px;
  padding-top: 5px;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 23px;
  line-height: 90%;
  text-align: center;
  letter-spacing: -0.1em;
  text-transform: uppercase;
  color: #f2ecdb;
  margin-right: 31px;
  margin-top: 4px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 25px;
    height: 25px;
    border-radius: 3px;
    padding-top: 5px;
    font-size: 19px;
    margin-right: 26px;
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 23px;
    height: 23px;
    border-radius: 3px;
    padding-top: 4px;
    font-size: 17px;
    margin-right: 23px;
    margin-top: 1px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    padding-top: 4px;
    font-size: 15px;
    margin-right: 21px;
    margin-top: 1px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    padding-top: 3px;
    font-size: 12px;
    margin-right: 17px;
    margin-top: 1px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding-top: 5px;
    font-size: 23px;
    margin-right: 32px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    padding-top: 4px;
    font-size: 18px;
    margin-bottom: 13px;
  }
`

const ItemText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 21px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  width: 402px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 348px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 324px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 274px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 224px;
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 402px;
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 100% !important;
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
  }
  span {
    font-weight: 800;
  }
`

const Row = styled.div`
  width: 515px;
  margin-right: 56px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 432px;
    margin-right: 47px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 391px;
    margin-right: 42px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 347px;
    margin-right: 38px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 278px;
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    margin-right: 0;
  }
  &:nth-child(1) {
    ${Item} {
      &:nth-child(4) {
        ${ItemText} {
          width: 454px;
          ${({ theme }) => theme.adaptive.xl} {
            width: 381px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            width: 344px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            width: 306px;
          }
          ${({ theme }) => theme.adaptive.md} {
            width: 244px;
          }
          ${({ theme }) => theme.adaptive.sm} {
            width: 454px;
          }
        }
      }
    }
  }
`

const Spheres = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 1828px;
  height: 926px;
  background: url('${spheresImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 17px;
    width: 1536px;
    height: 778px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 15px;
    width: 1384px;
    height: 701px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 13px;
    width: 1231px;
    height: 623px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 11px;
    width: 985px;
    height: 499px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 461px;
    width: 539px;
    height: 910px;
    background: url('${spheresImgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 399px;
    width: 748px;
    height: 1441px;
    background: url('${spheresImgSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 280px;
    width: 593px;
    height: 1143px;
  }
`

const Fishes = styled.div`
  position: absolute;
  top: -805px;
  left: 214px;
  width: 2579px;
  height: 2579px;
  background: url('${fishesImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: -676px;
    left: 180px;
    width: 2167px;
    height: 2167px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -610px;
    left: 163px;
    width: 1953px;
    height: 1953px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -542px;
    left: 145px;
    width: 1737px;
    height: 1737px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -434px;
    left: 116px;
    width: 1390px;
    height: 1390px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 98px;
    left: -761px;
    width: 2057px;
    height: 2057px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 584px;
    left: -722px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 453px;
    left: -573px;
    width: 1631px;
    height: 1631px;
  }
`
