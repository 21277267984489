import { Background } from './Bg'

export class FishEnemy {
  private bg: Background
  private width: number
  private height: number
  private x: number
  private y: number
  markedForDelete: boolean
  private speedRatio: number

  constructor(bg: Background, width: number, height: number) {
    this.bg = bg
    this.width = width
    this.height = height
    this.x = -this.width
    this.y = Math.random() * this.bg.height
    this.markedForDelete = false
    this.speedRatio = this.bg.RandomIntInRange(10, 20) / 10
  }
  update(deltaTime: number) {
    this.x += this.bg.groundSpeed * deltaTime * this.speedRatio
    if (this.x > this.bg.width) this.markedForDelete = true
  }
  draw(context: CanvasRenderingContext2D, img: any) {
    context.drawImage(img, this.x, this.y, this.width, this.height)
  }
}
