import styled from 'styled-components'

const theme = {
  adaptive: {
    xl: '@media screen and (max-width: 1780px)',
    lg: '@media screen and (max-width: 1480px)',
    slg: '@media screen and (max-width: 1380px)',
    md: '@media screen and (max-width: 1300px)',
    sm: '@media screen and (max-width: 960px)',
    smm: '@media screen and (max-width: 580px)',
    xs: '@media screen and (max-width: 380px)',
  },
}

export default theme

export const Container = styled.div`
  position: relative;
  width: 1720px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.xl} {
    width: 1430px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 1290px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 1140px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 914px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 540px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 280px;
  }
`
