import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import nImg from 'img/dao/join/n.svg'
import handIcon from 'img/dao/board/hand.svg'
import fishesImg from 'img/dao/join/fishes.svg'
import fishesSmImg from 'img/dao/join/fishes-sm.svg'
import fishesSmmImg from 'img/dao/join/fishes-smm.svg'
import formBg from 'img/dao/join/form-bg.svg'
import formSmBg from 'img/dao/join/form-bg-sm.svg'
import formSmmBg from 'img/dao/join/form-bg-smm.svg'
import { ReactComponent as WaleIcon } from 'img/dao/join/wale.svg'
import formImg from 'img/dao/join/form-img.png'
import { DISCORD_LINK } from 'config/app'
import titleIcon from 'img/partners/title-icon.svg'
import guildList from '../../../store/guilds'

function Join() {
  return (
    <Root>
      <Container>
        <Row>
          <N />
          <Text>
            <Fishes />
            <Title>
              Staking over <span>200,000 NSTART</span> grants you an individual
              seat on the NSDAO Board as part of the Sector Synth.
            </Title>
            <Desc>
              doing so will enable PMF opportunities, ability to raise
              Governance proposals, and direct contact to projects' teams.
            </Desc>
            <JoinUs>
              <div>
                <FormSubTitle>Are you a staker with over </FormSubTitle>
                <FormTitle>200,000 $NSTART?</FormTitle>
              </div>
              <Btn href={DISCORD_LINK} target={'_blank'}>
                Join us now!
                <WaleIcon />
              </Btn>
              <FormImg />
            </JoinUs>
          </Text>
        </Row>
        <GuildsTitle>The NEAR Protocol DAO Guilds</GuildsTitle>
        <Guilds>
          {guildList.map((guild, idx) => {
            return (
              <Guild href={guild.link} target={'_blank'} key={idx}>
                {guild.flag()}
                <GuildText>{guild.text}</GuildText>
              </Guild>
            )
          })}
        </Guilds>
      </Container>
    </Root>
  )
}

export default Join

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 63px;
  padding-bottom: 249px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 83px;
    padding-bottom: 215px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 94px;
    padding-bottom: 194px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 85px;
    padding-bottom: 177px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 66px;
    padding-bottom: 122px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 95px;
    padding-bottom: 506px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 99px;
    padding-bottom: 589px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 80px;
    padding-bottom: 433px;
  }
`

const Row = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
  }
`

const N = styled.div`
  min-width: 277px;
  height: 286px;
  background: url('${nImg}') center no-repeat;
  background-size: contain;
  margin-right: 74px;
  margin-left: 38px;
  ${({ theme }) => theme.adaptive.xl} {
    min-width: 232px;
    height: 239px;
    margin-right: 62px;
    margin-left: 32px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    min-width: 208px;
    height: 215px;
    margin-right: 55px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    min-width: 185px;
    height: 191px;
    margin-right: 49px;
    margin-left: 27px;
  }
  ${({ theme }) => theme.adaptive.md} {
    min-width: 148px;
    height: 153px;
    margin-right: 39px;
    margin-left: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    min-width: 223px;
    height: 229px;
    margin: 0 auto 76px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    min-width: 180px;
    height: 185px;
    margin: 0 auto 96px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    min-width: 146px;
    height: 151px;
    margin: 0 auto 75px;
  }
`

const Text = styled.div`
  position: relative;
`

const Fishes = styled.div`
  position: absolute;
  top: -36px;
  left: -114px;
  width: 1346px;
  height: 338px;
  background: url('${fishesImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    top: -20px;
    left: -95px;
    width: 1124px;
    height: 282px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -7px;
    left: -85px;
    width: 1009px;
    height: 253px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -6px;
    left: -76px;
    width: 897px;
    height: 225px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -5px;
    left: -61px;
    width: 719px;
    height: 181px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: -392px;
    left: -283px;
    width: 1009px;
    height: 481px;
    background: url('${fishesSmImg}') center no-repeat;
    background-size: contain;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -331px;
    left: -150px;
    width: 643px;
    height: 390px;
    background: url('${fishesSmmImg}') center no-repeat;
    background-size: contain;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -267px;
    left: -122px;
    width: 523px;
    height: 317px;
  }
`

const Title = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 60px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  width: 1158px;
  margin-bottom: 24px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 967px;
    font-size: 50px;
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 868px;
    font-size: 46px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 771px;
    font-size: 40px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 619px;
    font-size: 32px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 37px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 35px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 28px;
    margin-bottom: 25px;
  }
  span {
    font-weight: 700;
  }
`

const Desc = styled.div`
  position: relative;
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  margin-left: 37px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 20px;
    margin-left: 36px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
    margin-left: 32px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 16px;
    margin-left: 28px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 13px;
    margin-left: 23px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 27px;
    margin-left: 38px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 23px;
    margin-left: 38px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
    margin-left: 29px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -37px;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 21px;
    background: url('${handIcon}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      left: -36px;
      width: 22px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      left: -32px;
      width: 19px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      left: -28px;
      width: 17px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.md} {
      left: -23px;
      width: 14px;
      height: 11px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      left: -38px;
      width: 17px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      left: -29px;
      width: 14px;
      height: 11px;
    }
  }
`

const JoinUs = styled.div`
  z-index: 3;
  position: absolute;
  display: flex;
  padding: 54px 0 0 44px;
  top: 714px;
  left: 0;
  width: 1220px;
  height: 207px;
  background: url('${formBg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    top: 633px;
    padding: 45px 0 0 37px;
    width: 1019px;
    height: 173px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 579px;
    padding: 40px 0 0 33px;
    width: 914px;
    height: 155px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 526px;
    padding: 36px 0 0 29px;
    width: 813px;
    height: 138px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 403px;
    padding: 29px 0 0 24px;
    width: 652px;
    height: 111px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 1018px;
    padding: 78px 30px 0 30px;
    width: 541px;
    height: 463px;
    background: url('${formSmBg}') center no-repeat;
    background-size: contain;
    display: block;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 1195px;
    padding: 75px 0 0 23px;
    width: 350px;
    height: 520px;
    background: url('${formSmmBg}') center no-repeat;
    background-size: contain;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 962px;
    padding: 46px 0 0 19px;
    width: 285px;
    height: 404px;
  }
`

const FormSubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 56px;
  line-height: 80%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #090909;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 46px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 42px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 38px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 30px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 52px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 54px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 44px;
    margin-bottom: 4px;
  }
`

const FormTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 90px;
  line-height: 72.3%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #62dcaf;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 76px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 68px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 62px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 48px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 85px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 54px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 44px;
  }
`

const Btn = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 310px;
  height: 56px;
  padding: 14px 22px;
  background: #ff85fb;
  border: 1px solid #090909;
  box-shadow: 4.78125px 4.78125px 0px rgba(0, 0, 0, 0.88);
  border-radius: 4px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000000;
  transition: 0.4s;
  margin-left: 62px;
  margin-top: 46px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.xl} {
    width: 259px;
    height: 47px;
    padding: 12px 18px;
    font-size: 20px;
    margin-left: 50px;
    margin-top: 38px;
    box-shadow: 3.99435px 3.99435px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 232px;
    height: 42px;
    padding: 10px 16px;
    font-size: 17px;
    margin-left: 45px;
    margin-top: 35px;
    box-shadow: 3.58356px 3.58356px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 207px;
    height: 37px;
    padding: 9px 15px;
    font-size: 16px;
    margin-left: 32px;
    margin-top: 31px;
    box-shadow: 3.18539px 3.18539px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 166px;
    height: 30px;
    padding: 7px 12px;
    font-size: 13px;
    margin-left: 32px;
    margin-top: 24px;
    box-shadow: 2.55453px 2.55453px 0px rgba(0, 0, 0, 0.88);
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    height: 60px;
    padding: 15px 28px;
    font-size: 23px;
    margin-left: 0;
    margin-top: 29px;
    box-shadow: 4.78125px 4.78125px 0px rgba(0, 0, 0, 0.88);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 301px;
    padding: 15px 18px;
    margin-top: 43px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 245px;
    padding: 12px 14px;
    margin-top: 32px;
    height: 48px;
    font-size: 18px;
    box-shadow: 3.88658px 3.88658px 0px rgba(0, 0, 0, 0.88);
    border-radius: 3px;
  }
  svg {
    display: block;
    width: 23px;
    height: 25px;
    fill: #1a1a1a;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      width: 19px;
      height: 21px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 17px;
      height: 18px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 15px;
      height: 16px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 12px;
      height: 13px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 23px;
      height: 25px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 19px;
      height: 20px;
    }
    .eye {
      fill: white;
    }
  }
  &:hover {
    background: #ff65fa;
    color: #f4f3eb;
    border: 1px solid #f4f3eb;
    svg {
      fill: #f4f3eb;
      .eye {
        fill: #1a1a1a;
      }
    }
  }
`

const FormImg = styled.div`
  position: absolute;
  bottom: 21px;
  left: 1003px;
  width: 166px;
  height: 132px;
  background: url('${formImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    bottom: 17px;
    left: 837px;
    width: 138px;
    height: 110px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    bottom: 16px;
    left: 752px;
    width: 123px;
    height: 98px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    bottom: 14px;
    left: 668px;
    width: 109px;
    height: 87px;
  }
  ${({ theme }) => theme.adaptive.md} {
    bottom: 11px;
    left: 536px;
    width: 87px;
    height: 69px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    height: 143px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 160px;
    height: 127px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 130px;
    height: 103px;
  }
`

const GuildsTitle = styled.div`
  position: relative;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 90px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: #090909;
  margin-top: 68px;
  margin-bottom: 50px;
  margin-left: 71px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 75px;
    margin-top: 90px;
    margin-bottom: 42px;
    margin-left: 60px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 67px;
    margin-top: 86px;
    margin-bottom: 38px;
    margin-left: 55px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 60px;
    margin-top: 79px;
    margin-bottom: 35px;
    margin-left: 47px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 48px;
    margin-top: 55px;
    margin-bottom: 26px;
    margin-left: 38px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 56px;
    margin-left: 48px;
    margin-top: 121px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-left: 48px;
    margin-top: 116px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 44px;
    margin-top: 75px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -71px;
    top: 50%;
    transform: translateY(-50%);
    width: 54px;
    height: 54px;
    background: url('${titleIcon}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      width: 45px;
      height: 45px;
      left: -60px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 41px;
      height: 41px;
      left: -55px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 35px;
      height: 35px;
      left: -47px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 28px;
      height: 28px;
      left: -38px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 40px;
      height: 40px;
      left: -48px;
    }
  }
`

const Guilds = styled.div`
  display: flex;
  margin: 0 -22px;
  ${({ theme }) => theme.adaptive.xl} {
    margin: 0 -19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin: 0 -18px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin: 0 -17px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin: 0 -14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin: 0 -28px;
    flex-wrap: wrap;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin: 0 -18px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin: 0 -14px;
  }
`

const Guild = styled.a`
  display: block;
  margin: 0 22px;
  ${({ theme }) => theme.adaptive.xl} {
    margin: 0 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin: 0 18px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin: 0 17px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin: 0 14px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin: 0 28px;
    margin-top: 50px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin: 0 18px;
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin: 0 14px;
    margin-top: 40px;
  }
  svg {
    display: block;
    width: auto;
    height: 75px;
    filter: drop-shadow(10px 15px 20px rgba(0, 0, 0, 0.2));
    ${({ theme }) => theme.adaptive.xl} {
      filter: drop-shadow(8.34032px 12.5105px 16.6806px rgba(0, 0, 0, 0.2));
      height: 63px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      filter: drop-shadow(7.64736px 11.471px 15.2947px rgba(0, 0, 0, 0.2));
      height: 57px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      filter: drop-shadow(6.59341px 9.89012px 13.1868px rgba(0, 0, 0, 0.2));
      height: 49px;
    }
    ${({ theme }) => theme.adaptive.md} {
      filter: drop-shadow(5.29412px 7.94118px 10.5882px rgba(0, 0, 0, 0.2));
      height: 40px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      filter: drop-shadow(8.54661px 12.8199px 17.0932px rgba(0, 0, 0, 0.2));
      height: 64px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      filter: drop-shadow(6.797px 10.1955px 13.594px rgba(0, 0, 0, 0.2));
      height: 51px;
    }
  }
`

const GuildText = styled.div`
  margin-top: 25px;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 28px;
  line-height: 63%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #090909;
  text-align: center;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 21px;
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 19px;
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 16px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 13px;
    font-size: 18px;

`
