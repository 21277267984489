import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as NImg } from 'img/header/n-icon.svg'
import Menu from './menu'
import useWindowSize from 'helpers/utils/useWindowSize'
import { Link } from 'react-router-dom'

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { width } = useWindowSize()

  const onClose = () => {
    console.log('close')
    setIsMenuOpen(false)
    console.log(isMenuOpen)
  }

  const openMenu = () => {
    if (!isMenuOpen) setIsMenuOpen(true)
  }

  return (
    <Root>
      <Line>
        <N>
          <Link to={'/'}>
            <NIcon>
              <NImg />
            </NIcon>
          </Link>
        </N>
        <Burger onClick={() => openMenu()}>
          <BurgerLine />
          <BurgerLine />
          <BurgerLine />
        </Burger>
        {width > 380 && <Menu onClose={() => onClose()} isOpen={isMenuOpen} />}
      </Line>
      {width <= 380 && <Menu onClose={() => onClose()} isOpen={isMenuOpen} />}
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 7px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 5px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 60px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 41px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 29px 0 0 0;
  }
`

const Line = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.adaptive.sm} {
    width: 540px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 350px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 280px;
  }
`

const Burger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f4f3eb;
  border: 2px solid #090909;
  box-shadow: 3px 3px 0px #444444;
  border-radius: 3px;
  z-index: 10;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.xl} {
    width: 33px;
    height: 33px;
    border: 2px solid #090909;
    box-shadow: 2.50011px 2.50011px 0px #444444;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 30px;
    height: 30px;
    border: 2px solid #090909;
    box-shadow: 2.2501px 2.2501px 0px #444444;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 27px;
    height: 27px;
    border: 1px solid #090909;
    box-shadow: 2px 2px 0px #444444;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 21px;
    height: 21px;
    border: 1px solid #090909;
    box-shadow: 1px 1px 0px #444444;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: absolute;
    right: 0;
    top: -3px;
    width: 40px;
    height: 40px;
    border: 1px solid #090909;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 27px;
    height: 27px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 24px;
    height: 24px;
  }
`

const BurgerLine = styled.div`
  width: 26px;
  height: 5px;
  background: #000000;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 22px;
    height: 4px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 20px;
    height: 4px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 17px;
    height: 3px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 14px;
    height: 3px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 26px;
    height: 5px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 18px;
    height: 3px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 16px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const N = styled.div`
  position: relative;
  z-index: 10;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #74e7bf;
    border-radius: 3px;
    cursor: pointer;
    ${({ theme }) => theme.adaptive.xl} {
      width: 30px;
      height: 30px;
      border-radius: 3px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 27px;
      height: 27px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 24px;
      height: 24px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 19px;
      height: 19px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      display: none;
    }
  }
`

const NIcon = styled.div`
  width: 20px;
  height: 20px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 17px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 13px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 11px;
    height: 11px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`
