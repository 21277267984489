import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import lineImg from 'img/news/line.svg'
import rectanglesImg from 'img/news/rectangles.svg'
import roundImg from 'img/news/round.png'
import { ReactComponent as Arrow } from 'img/news/arrow.svg'
import { ReactComponent as Icon1 } from 'img/news/icon-1.svg'
import { ReactComponent as Icon2 } from 'img/news/icon-2.svg'
import { ReactComponent as Icon3 } from 'img/news/icon-3.svg'
import manImg from 'img/news/articles/man.png'
import waleImg from 'img/news/articles/wale.png'
import plusImg from 'img/news/plus.svg'
import rectImg from 'img/news/rect.svg'
import getLatestMediumPosts from 'api/medium/medium'
import { format, parseISO } from 'date-fns'
import LazyLoad from 'react-lazyload'
import { MEDIUM_LINK } from 'config/app'

function News() {
  const [newsList, setNewsList] = useState<any[]>([])
  const [postsFetched, setPostFetched] = useState(false)

  //@ts-ignore
  useEffect(async () => {
    const posts = await getLatestMediumPosts()
    setNewsList(posts.data)
    console.log(posts.data)
    setPostFetched(true)
  }, [])

  const parseArticleDate = (date: string) => {
    try {
      return format(parseISO(date), 'MMM dd, yyyy')
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  const truncateByChars = (str: string, max: number) => {
    if (!str) return
    return str.length > max ? str.substring(0, max) + '...' : str
  }

  return (
    <Root>
      <LineWrap>
        <Line />
      </LineWrap>
      <Container>
        <TitleWrap>
          <SubTitle>NEARStarter</SubTitle>
          <Title>news</Title>
        </TitleWrap>
        {postsFetched && (
          <List>
            <Plus />
            <Rect />
            <MainArticle>
              <ArticleHeader>
                <Icons>
                  <Icon>
                    <Icon1 />
                  </Icon>
                  <Icon>
                    <Icon2 />
                  </Icon>
                  <Icon>
                    <Icon3 />
                  </Icon>
                </Icons>
              </ArticleHeader>
              <div>
                <LazyLoad height={250} offset={300} once>
                  <MainImg src={newsList[0].thumbnail} />
                </LazyLoad>
                <ArticleTitle>{newsList[0].title}</ArticleTitle>
                <ArticleText>
                  {truncateByChars(newsList[0].description, 140)}
                </ArticleText>
              </div>
              <DateRow>
                <Link href={newsList[0].link} target={'_blank'}>
                  <LinkText>more information</LinkText>
                  <Arrow />
                </Link>
                <div>
                  <DateText>Publication date:</DateText>
                  <ArticleDate>
                    {parseArticleDate(newsList[0].isoDate)}
                  </ArticleDate>
                </div>
              </DateRow>
            </MainArticle>
            <SecondArticle>
              <ArticleHeader>
                <Icons>
                  <Icon>
                    <Icon1 />
                  </Icon>
                  <Icon>
                    <Icon2 />
                  </Icon>
                  <Icon>
                    <Icon3 />
                  </Icon>
                </Icons>
              </ArticleHeader>
              <div>
                <LazyLoad height={250} offset={300} once>
                  <MainImg src={newsList[1].thumbnail} />
                </LazyLoad>
                <ArticleTitle>
                  {truncateByChars(newsList[1].title, 45)}
                </ArticleTitle>
                <ArticleText>
                  {truncateByChars(newsList[1].description, 140)}
                </ArticleText>
              </div>
              <DateRow>
                <div>
                  <DateText>Publication date:</DateText>
                  <ArticleDate>
                    {parseArticleDate(newsList[1].isoDate)}
                  </ArticleDate>
                </div>
                <Link href={newsList[1].link} target={'_blank'}>
                  <LinkText>more information</LinkText>
                  <Arrow />
                </Link>
              </DateRow>
            </SecondArticle>
            <ThirdArticle>
              <ArticleHeader>
                <Icons>
                  <Icon>
                    <Icon1 />
                  </Icon>
                  <Icon>
                    <Icon2 />
                  </Icon>
                  <Icon>
                    <Icon3 />
                  </Icon>
                </Icons>
              </ArticleHeader>
              <div>
                <ArticleTitle>
                  {truncateByChars(newsList[2].title, 45)}
                </ArticleTitle>
                <ArticleText>
                  {truncateByChars(newsList[2].description, 140)}
                </ArticleText>
              </div>
              <DateRow>
                <div>
                  <DateText>Publication date:</DateText>
                  <ArticleDate>
                    {parseArticleDate(newsList[2].isoDate)}
                  </ArticleDate>
                </div>
                <Link href={newsList[2].link} target={'_blank'}>
                  <LinkText>more information</LinkText>
                  <Arrow />
                </Link>
              </DateRow>
            </ThirdArticle>
            <FourthArticle>
              <ArticleHeader>
                <Icons>
                  <Icon>
                    <Icon1 />
                  </Icon>
                  <Icon>
                    <Icon2 />
                  </Icon>
                  <Icon>
                    <Icon3 />
                  </Icon>
                </Icons>
              </ArticleHeader>
              <div>
                <ArticleTitle>{newsList[3].title}</ArticleTitle>
                <ArticleText>
                  {truncateByChars(newsList[3].description, 140)}
                </ArticleText>
              </div>
              <DateRow>
                <Link href={newsList[3].link} target={'_blank'}>
                  <LinkText>more information</LinkText>
                  <Arrow />
                </Link>
                <div>
                  <DateText>Publication date:</DateText>
                  <ArticleDate>
                    {parseArticleDate(newsList[3].isoDate)}
                  </ArticleDate>
                </div>
              </DateRow>
            </FourthArticle>
            <BgArticle>
              <ArticleHeader>
                <Icons>
                  <Icon>
                    <Icon1 />
                  </Icon>
                  <Icon>
                    <Icon2 />
                  </Icon>
                  <Icon>
                    <Icon3 />
                  </Icon>
                </Icons>
              </ArticleHeader>
            </BgArticle>
            <BgArticle2>
              <ArticleHeader>
                <Icons>
                  <Icon>
                    <Icon1 />
                  </Icon>
                  <Icon>
                    <Icon2 />
                  </Icon>
                  <Icon>
                    <Icon3 />
                  </Icon>
                </Icons>
              </ArticleHeader>
            </BgArticle2>
            <ToAll href={MEDIUM_LINK} target={'_blank'}>
              <ReadAll>Read all</ReadAll>
              <Arrow />
            </ToAll>
          </List>
        )}
        <Round />
      </Container>
    </Root>
  )
}

export default News

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #f4f3eb;
`

const LineWrap = styled.div`
  padding-top: 9px;
  border-top: 1px solid rgba(9, 9, 9, 0.22);
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(9, 9, 9, 0.22);
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 7px;
    padding-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 39px;
  background: url('${lineImg}') center repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    height: 33px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 29px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 16px;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 360px;
  padding-top: 101px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 300px;
    padding-top: 84px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 268px;
    padding-top: 76px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 240px;
    padding-top: 67px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 192px;
    padding-top: 54px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 328px;
    padding-top: 94px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 224px;
    padding-top: 64px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 176px;
    padding-top: 49px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 416px;
    top: 127px;
    width: 1425px;
    height: 167px;
    background: url('${rectanglesImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      left: 347px;
      top: 106px;
      width: 1188px;
      height: 140px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      left: 312px;
      top: 95px;
      width: 1069px;
      height: 124px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      left: 277px;
      top: 85px;
      width: 950px;
      height: 110px;
    }
    ${({ theme }) => theme.adaptive.md} {
      left: 222px;
      top: 68px;
      width: 760px;
      height: 88px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      left: -30px;
      top: 16px;
      width: 585px;
      height: 66px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      left: -20px;
      top: 12px;
      width: 399px;
      height: 45px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      left: -19px;
      top: 8px;
      width: 311px;
      height: 35px;
    }
  }
`

const SubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 90px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: #090909;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 75px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 67px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 60px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 48px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 82px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 56px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 44px;
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 234px;
  line-height: 90%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #74e7bf;
  margin-top: -25px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 195px;
    margin-top: -25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 175px;
    margin-top: -22px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 155px;
    margin-top: -21px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 124px;
    margin-top: -15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 160px;
    margin-top: -24px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 116px;
    margin-top: -15px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 86px;
    margin-top: -13px;
  }
`

const Round = styled.div`
  position: absolute;
  width: 453px;
  height: 454px;
  top: -103px;
  right: 0;
  background: url('${roundImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 378px;
    height: 378px;
    top: -86px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 341px;
    height: 341px;
    top: -77px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 303px;
    height: 303px;
    top: -69px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 242px;
    height: 242px;
    top: -55px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 251px;
    height: 251px;
    top: -73px;
    right: -89px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 198px;
    height: 198px;
    top: -65px;
    right: -95px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 155px;
    height: 155px;
    top: -52px;
    right: -73px;
  }
`

const List = styled.div`
  position: relative;
  padding-top: 29px;
  height: 1806px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 25px;
    height: 1504px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 23px;
    height: 1354px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 21px;
    height: 1204px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 16px;
    height: 963px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 26px;
    padding-bottom: 135px;
    height: auto;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 11px;
    padding-bottom: 126px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 12px;
    padding-bottom: 99px;
  }
`

const ArticleTitle = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: -0.03em;
  opacity: 0.8;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 31px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
  }
`

const ArticleText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.03em;
  opacity: 0.32;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 12px;
  }
`

const ArticleDate = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 90%;
  letter-spacing: -0.02em;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 29px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 16px;
  }
`

const DateText = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 21px;
  line-height: 90%;
  letter-spacing: -0.02em;
  opacity: 0.23;
  margin-bottom: 4px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 12px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 14px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 11px;
    margin-bottom: 1px;
  }
`

const DateRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.sm} {
    display: flex !important;
  }
`

const LinkText = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #090909;
  opacity: 0.6;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 11px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 12px;
  }
`

const Link = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 33px 14px 22px;
  width: 508px;
  height: 54px;
  border: 1px solid rgba(9, 9, 9, 0.6);
  border-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 12px 17px 11px 19px;
    width: 423px;
    height: 45px;
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 11px 25px 11px 17px;
    width: 381px;
    height: 41px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding: 9px 22px 10px 15px;
    width: 339px;
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 7px 18px 8px 12px;
    width: 271px;
    height: 29px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding: 9px 14px 10px 24px;
    width: 360px;
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding: 8px 18px 9px 11px;
    width: 208px;
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 6px 8px 6px 14px;
    width: 162px;
    height: 26px;
  }
  svg {
    display: block;
    width: 16px;
    height: 14px;
    fill: #090909;
    opacity: 0.5;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.xl} {
      width: 16px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 12px;
      height: 10px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 11px;
      height: 9px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 9px;
      height: 7px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 10px;
      height: 8px;
    }
  }
  &:hover {
    border: 1px solid #090909;
    ${LinkText} {
      opacity: 1;
    }
    svg {
      opacity: 1;
    }
  }
`

const ArticleCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const MainArticle = styled.div`
  ${ArticleCss};
  position: absolute;
  top: 29px;
  left: 52px;
  width: 831px;
  height: 929px;
  background: #fbfaf6;
  border: 4px solid #dfdfdf;
  box-shadow: 18.5155px 18.5155px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 79px 50px 61px;
  ${({ theme }) => theme.adaptive.xl} {
    border: 3px solid #dfdfdf;
    box-shadow: 15.4302px 15.4302px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 66px 42px 51px;
    top: 25px;
    left: 42px;
    width: 692px;
    height: 774px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    border: 3px solid #dfdfdf;
    box-shadow: 13.8872px 13.8872px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 59px 38px 46px;
    top: 23px;
    left: 39px;
    width: 623px;
    height: 697px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    border: 3px solid #dfdfdf;
    box-shadow: 12.3442px 12.3442px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 53px 33px 41px;
    top: 21px;
    left: 34px;
    width: 554px;
    height: 619px;
  }
  ${({ theme }) => theme.adaptive.md} {
    border: 2px solid #dfdfdf;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 42px 27px 33px;
    top: 16px;
    left: 26px;
    width: 443px;
    height: 495px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    border: 0px;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 53px 25px 39px;
    top: unset;
    left: unset;
    width: 100%;
    height: 609px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    box-shadow: 9px 9px 0px rgba(0, 0, 0, 0.05);
    padding: 52px 15px 41px;
    height: 512px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    box-shadow: 7px 7px 0px rgba(0, 0, 0, 0.05);
    padding: 41px 18px 32px;
    height: 400px;
  }
  ${ArticleTitle}, ${ArticleDate}, ${DateText} {
    color: #090909;
  }
  ${ArticleText} {
    color: #090909;
    margin-top: 27px;
    ${({ theme }) => theme.adaptive.xl} {
      margin-top: 22px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      margin-top: 18px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      margin-top: 17px;
    }
    ${({ theme }) => theme.adaptive.md} {
      margin-top: 13px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      margin-top: 16px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      margin-top: 14px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      margin-top: 11px;
    }
  }
`

const MainImg = styled.img`
  display: block;
  width: 725px;
  height: 410px;
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.22);
  margin-bottom: 58px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 604px;
    height: 342px;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.22);
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 543px;
    height: 308px;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.22);
    margin-bottom: 44px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 483px;
    height: 273px;
    box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.22);
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 387px;
    height: 219px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.22);
    margin-bottom: 31px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 492px;
    height: 279px;
    box-shadow: 3px 3px 0px rgba(0, 0, 0, 0.22);
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 306px;
    height: 172px;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 238px;
    height: 134px;
    margin-bottom: 22px;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.22);
  }
`

const SecondArticle = styled.div`
  ${ArticleCss};
  position: absolute;
  top: 140px;
  left: 1021px;
  width: 460px;
  height: 797px;
  background: #0d0d0c;
  border: 4px solid #d7d7d7;
  box-shadow: 18.5155px 18.5155px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 81px 44px 48px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 118px;
    left: 849px;
    width: 383px;
    height: 664px;
    border: 3px solid #d7d7d7;
    box-shadow: 15.4302px 15.4302px 0px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    padding: 68px 37px 40px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 106px;
    left: 766px;
    width: 345px;
    height: 598px;
    border: 3px solid #d7d7d7;
    box-shadow: 13.8872px 13.8872px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 61px 33px 36px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 95px;
    left: 680px;
    width: 307px;
    height: 531px;
    border: 3px solid #d7d7d7;
    box-shadow: 12.3442px 12.3442px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 54px 29px 32px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 75px;
    left: 543px;
    width: 245px;
    height: 425px;
    border: 2px solid #d7d7d7;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 43px 23px 26px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    height: 609px;
    border: 0px;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 53px 25px 40px;
    margin-top: 52px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 512px;
    box-shadow: 9px 9px 0px rgba(0, 0, 0, 0.05);
    padding: 52px 15px 41px;
    margin-top: 49px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 400px;
    box-shadow: 7px 7px 0px rgba(0, 0, 0, 0.05);
    padding: 41px 11px 32px 18px;
    margin-top: 39px;
  }
  ${ArticleTitle} {
    color: #ffffff;
    font-size: 35px;
    ${({ theme }) => theme.adaptive.xl} {
      font-size: 28px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      font-size: 24px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      font-size: 23px;
    }
    ${({ theme }) => theme.adaptive.md} {
      font-size: 19px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      font-size: 24px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      font-size: 23px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      font-size: 18px;
    }
  }
  ${ArticleDate}, ${DateText} {
    color: #f4f3eb;
  }
  ${ArticleText} {
    color: #ffffff;
    font-size: 22px;
    margin-top: 14px;
    ${({ theme }) => theme.adaptive.xl} {
      font-size: 17px;
      margin-top: 11px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      font-size: 15px;
      margin-top: 8px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      font-size: 15px;
      margin-top: 7px;
    }
    ${({ theme }) => theme.adaptive.md} {
      font-size: 12px;
      margin-top: 6px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      font-size: 14px;
      margin-top: 15px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      margin-top: 14px;
      max-width: 241px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      margin-top: 11px;
      font-size: 12px;
      max-width: 202px;
    }
  }
  ${DateRow} {
    display: block;
  }
  ${MainImg} {
    width: 369px;
    height: 208px;
    box-shadow: 6px 6px 0px rgba(244, 243, 235, 0.11);
    margin-bottom: 38px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 308px;
      height: 173px;
      margin-bottom: 32px;
      box-shadow: 5.00021px 5.00021px 0px rgba(244, 243, 235, 0.11);
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 277px;
      height: 156px;
      margin-bottom: 29px;
      box-shadow: 5.00021px 5.00021px 0px rgba(244, 243, 235, 0.11);
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 246px;
      height: 139px;
      margin-bottom: 25px;
      box-shadow: 4px 4px 0px rgba(244, 243, 235, 0.11);
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 197px;
      height: 111px;
      margin-bottom: 20px;
      box-shadow: 3px 3px 0px rgba(244, 243, 235, 0.11);
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 492px;
      height: 279px;
      margin-bottom: 35px;
      box-shadow: 3px 3px 0px rgba(244, 243, 235, 0.11);
    }
    ${({ theme }) => theme.adaptive.smm} {
      width: 306px;
      height: 172px;
      margin-bottom: 28px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 238px;
      height: 134px;
      margin-bottom: 22px;
      box-shadow: 2px 2px 0px rgba(244, 243, 235, 0.11);
    }
  }
  ${Link} {
    width: 370px;
    height: 54px;
    padding: 14px 33px 14px 18px;
    margin-top: 24px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 308px;
      height: 45px;
      padding: 12px 17px 11px 15px;
      margin-top: 20px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 277px;
      height: 41px;
      padding: 11px 25px 11px 13px;
      margin-top: 18px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 247px;
      height: 36px;
      padding: 9px 22px 10px 12px;
      margin-top: 16px;
      border-radius: 3px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 197px;
      height: 29px;
      padding: 7px 18px 8px 10px;
      margin-top: 13px;
      border-radius: 3px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 360px;
      height: 36px;
      padding: 9px 14px 10px 19px;
      margin-top: 0;
      border-radius: 4px;
      order: -1;
    }
    ${({ theme }) => theme.adaptive.smm} {
      width: 208px;
      height: 34px;
      padding: 8px 18px 9px 11px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 162px;
      height: 26px;
      padding: 6px 8px 6px 14px;
    }
    svg {
      fill: #f4f3eb;
      opacity: 0.3;
    }
    &:hover {
      border: 1px solid #fff;
      ${LinkText} {
        opacity: 1;
      }
      svg {
        opacity: 1;
      }
    }
  }
  ${LinkText} {
    color: #ffffff;
    opacity: 0.3;
  }
`

const ThirdArticle = styled.div`
  ${ArticleCss};
  position: absolute;
  top: 1094px;
  left: 405px;
  width: 528px;
  height: 546px;
  background: #0d0d0c;
  border: 4px solid #d7d7d7;
  box-shadow: 18.5155px 18.5155px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 81px 46px 55px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 913px;
    left: 336px;
    width: 440px;
    height: 455px;
    padding: 68px 40px 46px;
    border: 3px solid #d7d7d7;
    box-shadow: 15.4302px 15.4302px 0px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 822px;
    left: 304px;
    width: 396px;
    height: 410px;
    padding: 61px 34px 41px;
    border: 3px solid #d7d7d7;
    box-shadow: 13.8872px 13.8872px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 731px;
    left: 270px;
    width: 352px;
    height: 364px;
    padding: 54px 31px 37px;
    border: 3px solid #d7d7d7;
    box-shadow: 12.3442px 12.3442px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 584px;
    left: 214px;
    width: 282px;
    height: 291px;
    padding: 42px 25px 29px;
    border: 2px solid #d7d7d7;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
  ${ArticleTitle} {
    color: #ffffff;
  }
  ${ArticleDate}, ${DateText} {
    color: #f4f3eb;
  }
  ${ArticleText} {
    color: #ffffff;
    margin-top: 20px;
    ${({ theme }) => theme.adaptive.xl} {
      margin-top: 16px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      margin-top: 13px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      margin-top: 12px;
    }
    ${({ theme }) => theme.adaptive.md} {
      margin-top: 10px;
    }
  }
  ${DateRow} {
    display: block;
  }
  ${LinkText} {
    color: #ffffff;
    opacity: 0.3;
  }
  ${Link} {
    width: 424px;
    height: 54px;
    padding: 14px 38px 14px 22px;
    margin-top: 25px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 353px;
      height: 45px;
      padding: 12px 31px 11px 19px;
      margin-top: 25px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 318px;
      height: 41px;
      padding: 11px 28px 11px 17px;
      margin-top: 20px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 283px;
      height: 36px;
      padding: 9px 25px 10px 15px;
      margin-top: 16px;
      border-radius: 3px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 226px;
      height: 29px;
      padding: 7px 20px 8px 12px;
      margin-top: 14px;
      border-radius: 3px;
    }
    svg {
      fill: #f4f3eb;
      opacity: 0.3;
    }
    &:hover {
      border: 1px solid #fff;
      ${LinkText} {
        opacity: 1;
      }
      svg {
        opacity: 1;
      }
    }
  }
`

const FourthArticle = styled.div`
  ${ArticleCss};
  position: absolute;
  top: 1022px;
  left: 1017px;
  width: 728px;
  height: 472px;
  background: #fbfaf6;
  border: 4px solid #dfdfdf;
  box-shadow: 18.5155px 18.5155px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 81px 38px 50px 50px;
  ${({ theme }) => theme.adaptive.xl} {
    top: 853px;
    left: 846px;
    width: 607px;
    height: 393px;
    border: 3px solid #dfdfdf;
    box-shadow: 15.4302px 15.4302px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 68px 32px 42px 42px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 768px;
    left: 763px;
    width: 546px;
    height: 354px;
    border: 3px solid #dfdfdf;
    box-shadow: 13.8872px 13.8872px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 61px 37px 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 683px;
    left: 678px;
    width: 485px;
    height: 315px;
    border: 3px solid #dfdfdf;
    box-shadow: 12.3442px 12.3442px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 54px 33px 33px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 546px;
    left: 540px;
    width: 388px;
    height: 252px;
    border: 2px solid #dfdfdf;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding: 42px 27px 27px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    top: unset;
    left: unset;
    width: 100%;
    height: 304px;
    border: 0px;
    box-shadow: 9.87533px 9.87533px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 61px 25px 44px;
    margin-top: 52px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 319px;
    box-shadow: 9px 9px 0px rgba(0, 0, 0, 0.05);
    padding: 58px 15px 42px;
    margin-top: 49px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 250px;
    box-shadow: 7px 7px 0px rgba(0, 0, 0, 0.05);
    padding: 46px 10px 33px 15px;
    margin-top: 39px;
  }
  ${ArticleTitle} {
    color: #090909;
  }
  ${ArticleDate}, ${DateText} {
    color: #090909;
  }
  ${ArticleText} {
    color: #090909;
    margin-top: 25px;
    font-size: 21px;
    ${({ theme }) => theme.adaptive.xl} {
      margin-top: 20px;
      font-size: 18px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      margin-top: 17px;
      font-size: 15px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      margin-top: 15px;
      font-size: 14px;
    }
    ${({ theme }) => theme.adaptive.md} {
      margin-top: 12px;
      font-size: 11px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      margin-top: 15px;
      font-size: 14px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      margin-top: 13px;
      font-size: 13px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      margin-top: 11px;
      font-size: 11px;
    }
  }
  ${Link} {
    width: 433px;
    height: 54px;
    padding: 14px 17px 14px 22px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 361px;
      height: 45px;
      padding: 12px 14px 11px 19px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 325px;
      height: 41px;
      padding: 11px 13px 11px 17px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 289px;
      height: 36px;
      padding: 9px 11px 10px 15px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 231px;
      height: 29px;
      padding: 7px 9px 8px 12px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 360px;
      height: 36px;
      padding: 9px 14px 10px 22px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      width: 208px;
      height: 34px;
      padding: 8px 18px 8px 11px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 162px;
      height: 26px;
      padding: 6px 8px 6px 14px;
    }
  }
`

const ArticleHeader = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 26px;
  left: -4px;
  top: -5px;
  width: calc(100% + 8px);
  height: 40px;
  background: #dfdfdf;
  border-radius: 8px 8px 0px 0px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-right: 22px;
    left: -3px;
    top: -5px;
    width: calc(100% + 6px);
    height: 33px;
    border-radius: 7px 7px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-right: 20px;
    left: -3px;
    top: -4px;
    width: calc(100% + 6px);
    height: 30px;
    border-radius: 7px 7px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-right: 15px;
    left: -3px;
    top: -4px;
    width: calc(100% + 6px);
    height: 27px;
    border-radius: 5px 5px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-right: 11px;
    left: -2px;
    top: -3px;
    width: calc(100% + 4px);
    height: 21px;
    border-radius: 4px 4px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-right: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    border-radius: 4px 4px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -1px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 24px;
    padding-right: 12px;
  }
`

const Icons = styled.div`
  display: flex;
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #dfdfdf;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  box-shadow: 1.5px 1.5px 0px rgba(53, 53, 53, 0.55);
  border-radius: 2px;
  margin-left: 6px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 17px;
    height: 17px;
    border-radius: 2px;
    margin-left: 5px;
    box-shadow: 1.25005px 1.25005px 0px rgba(53, 53, 53, 0.55);
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-left: 5px;
    box-shadow: 1.12505px 1.12505px 0px rgba(53, 53, 53, 0.55);
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 13px;
    height: 13px;
    border-radius: 1px;
    margin-left: 4px;
    box-shadow: 1px 1px 0px rgba(53, 53, 53, 0.55);
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 11px;
    height: 11px;
    margin-left: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    border-radius: 1px;
  }
  &:nth-child(1) {
    svg {
      display: block;
      width: 12px;
      height: 12px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 10px;
        height: 10px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 9px;
        height: 9px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 7px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 7px;
        height: 7px;
      }
    }
  }
  &:nth-child(2) {
    svg {
      display: block;
      width: 13px;
      height: 11px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 11px;
        height: 9px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 10px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        width: 9px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 8px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 9px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 8px;
        height: 7px;
      }
    }
  }
  &:nth-child(3) {
    svg {
      display: block;
      width: 11px;
      height: 11px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 9px;
        height: 9px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 7px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 7px;
        height: 7px;
      }
    }
  }
`

const BgArticle = styled.div`
  position: absolute;
  top: 1145px;
  left: -160px;
  width: 470px;
  height: 440px;
  background: #fbfaf6;
  mix-blend-mode: normal;
  border: 6px solid #dfdfdf;
  box-shadow: 18.5155px 18.5155px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: url('${manImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 955px;
    left: -135px;
    width: 395px;
    height: 366px;
    border: 5px solid #dfdfdf;
    box-shadow: 15.4302px 15.4302px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 860px;
    left: -120px;
    width: 356px;
    height: 330px;
    border: 5px solid #dfdfdf;
    box-shadow: 13.8872px 13.8872px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 765px;
    left: -107px;
    width: 294px;
    height: 316px;
    border: 4px solid #dfdfdf;
    box-shadow: 12.3442px 12.3442px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 609px;
    left: -55px;
    width: 239px;
    height: 223px;
    border: 3px solid #dfdfdf;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const BgArticle2 = styled.div`
  position: absolute;
  top: 223px;
  left: 1568px;
  width: 383px;
  height: 589px;
  background: #fbfaf6;
  border: 6px solid #dfdfdf;
  box-shadow: 18.5155px 18.5155px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: url('${waleImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 187px;
    left: 1305px;
    width: 319px;
    height: 491px;
    border: 5px solid #dfdfdf;
    box-shadow: 15.4302px 15.4302px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 169px;
    left: 1176px;
    width: 287px;
    height: 442px;
    border: 5px solid #dfdfdf;
    box-shadow: 13.8872px 13.8872px 0px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 151px;
    left: 1045px;
    width: 255px;
    height: 393px;
    border: 4px solid #dfdfdf;
    box-shadow: 12.3442px 12.3442px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 120px;
    left: 845px;
    width: 204px;
    height: 314px;
    border: 3px solid #dfdfdf;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const ToAll = styled.a`
  position: absolute;
  top: 1662px;
  right: 0;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.xl} {
    top: 1386px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 1248px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 1110px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 887px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 1705px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 1505px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 1178px;
  }
  svg {
    display: block;
    width: 16px;
    height: 14px;
    fill: #0d0d0c;
    margin-top: -3px;
    ${({ theme }) => theme.adaptive.xl} {
      width: 14px;
      height: 11px;
      margin-top: -2px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 12px;
      height: 10px;
      margin-top: -4px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 11px;
      height: 9px;
      margin-top: -4px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 9px;
      height: 7px;
      margin-top: -4px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 10px;
      height: 8px;
      margin-top: -4px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 131px;
    height: 2px;
    background: #0d0d0c;
    ${({ theme }) => theme.adaptive.xl} {
      width: 109px;
      bottom: -4px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      width: 98px;
      bottom: -3px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      width: 87px;
      bottom: -3px;
    }
    ${({ theme }) => theme.adaptive.md} {
      width: 70px;
      bottom: -3px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      width: 79px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      width: 62px;
    }
  }
`

const ReadAll = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: #0d0d0c;
  margin-right: 13px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 29px;
    margin-right: 11px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 26px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 23px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 18px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 20px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 16px;
    margin-right: 6px;
  }
`

const Plus = styled.div`
  position: absolute;
  width: 59px;
  height: 59px;
  top: 1032px;
  left: 346px;
  background: url('${plusImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 49px;
    height: 49px;
    top: 861px;
    left: 289px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 44px;
    height: 44px;
    top: 775px;
    left: 260px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 39px;
    height: 39px;
    top: 690px;
    left: 230px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 31px;
    height: 31px;
    top: 551px;
    left: 185px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 25px;
    height: 25px;
    top: -30px;
    left: unset;
    right: 0;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 20px;
    height: 20px;
    top: -20px;
  }
`

const Rect = styled.div`
  position: absolute;
  width: 29px;
  height: 29px;
  top: 934px;
  left: 960px;
  background: url('${rectImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 24px;
    height: 24px;
    top: 779px;
    left: 800px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 22px;
    height: 22px;
    top: 702px;
    left: 720px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 19px;
    height: 19px;
    top: 624px;
    left: 640px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 15px;
    height: 15px;
    top: 499px;
    left: 512px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`
