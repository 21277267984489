import React from 'react'
import styled from 'styled-components'
import Header from 'components/index/header'
import Main from './main'
import Pool from './pool'
import Tiers from './tiers'
import About from './about'
import News from '../index/news'
import Contact from '../contact'
import Footer from '../footer'

function Index() {
  return (
    <Root>
      <Header />
      <Main />
      <Pool />
      <Tiers />
      <About />
      <News />
      <Contact />
      <Footer />
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #090909;
`
