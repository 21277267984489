import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Icon1 } from '../../img/news/icon-1.svg'
import { ReactComponent as Icon2 } from '../../img/news/icon-2.svg'
import { ReactComponent as Icon3 } from '../../img/news/icon-3.svg'
import { ReactComponent as Restart } from '../../img/modals/restart-icon.svg'
import getResults from 'api/results/results'

export interface ModalProps {
  onClose(): void
  restartHandler(): void
}

const TableForm: React.FunctionComponent<ModalProps> = (props) => {
  const [results, setResults] = useState([])
  const [postsFetched, setPostFetched] = useState(false)
  const [isFull, setIsFull] = useState<boolean>(false)

  const onF5Click = () => {
    setIsFull(!isFull)
  }

  useEffect(() => {
    const res = async () => {
      let bestResults = await getResults()
      setResults(bestResults.data)
      setPostFetched(true)
    }
    res()
  }, [])

  return (
    <Form isFull={isFull}>
      <ArticleHeader>
        <Icons>
          <Icon>
            <Icon1 />
          </Icon>
          <Icon onClick={onF5Click}>
            <Icon2 />
          </Icon>
          <Icon onClick={props.restartHandler}>
            <Icon3 />
          </Icon>
        </Icons>
      </ArticleHeader>
      <Cont>
        <List>
          <Title>Top results</Title>
          {results.map(({ name, score }, idx) => {
            if (postsFetched) {
              return (
                <Item key={idx}>
                  <Number>{idx + 1}</Number>
                  <Name>{name}</Name>
                  <Line />
                  <Dollar>$</Dollar>
                  <Value>{score}</Value>
                </Item>
              )
            }
          })}
        </List>
        <Button onClick={props.restartHandler}>
          Try Again
          <RestartIcon>
            <Restart />
          </RestartIcon>
        </Button>
      </Cont>
    </Form>
  )
}

export default TableForm

const Form = styled.div<{ isFull: boolean }>`
  position: relative;
  ${({ isFull }) => {
    if (isFull)
      return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100% !important;
        height: 100% !important;
      `
  }}
  width: 528px;
  height: 730px;
  background: #0d0d0c;
  border: 4px solid #d7d7d7;
  box-shadow: 19px 19px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 82px 50px 55px;
`

const Cont = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 420px;
  height: 100%;
`

const ArticleHeader = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 26px;
  left: -4px;
  top: -5px;
  width: calc(100% + 8px);
  height: 40px;
  background: #dfdfdf;
  border-radius: 8px 8px 0px 0px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-right: 22px;
    left: -3px;
    top: -5px;
    width: calc(100% + 6px);
    height: 33px;
    border-radius: 7px 7px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-right: 20px;
    left: -3px;
    top: -4px;
    width: calc(100% + 6px);
    height: 30px;
    border-radius: 7px 7px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-right: 15px;
    left: -3px;
    top: -4px;
    width: calc(100% + 6px);
    height: 27px;
    border-radius: 5px 5px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-right: 11px;
    left: -2px;
    top: -3px;
    width: calc(100% + 4px);
    height: 21px;
    border-radius: 4px 4px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-right: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    border-radius: 4px 4px 0px 0px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -1px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 24px;
    padding-right: 12px;
  }
`

const Icons = styled.div`
  display: flex;
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #dfdfdf;
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  box-shadow: 1.5px 1.5px 0px rgba(53, 53, 53, 0.55);
  border-radius: 2px;
  margin-left: 6px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 17px;
    height: 17px;
    border-radius: 2px;
    margin-left: 5px;
    box-shadow: 1.25005px 1.25005px 0px rgba(53, 53, 53, 0.55);
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-left: 5px;
    box-shadow: 1.12505px 1.12505px 0px rgba(53, 53, 53, 0.55);
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 13px;
    height: 13px;
    border-radius: 1px;
    margin-left: 4px;
    box-shadow: 1px 1px 0px rgba(53, 53, 53, 0.55);
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 11px;
    height: 11px;
    margin-left: 3px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    border-radius: 1px;
  }
  &:nth-child(1) {
    svg {
      display: block;
      width: 12px;
      height: 12px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 10px;
        height: 10px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 9px;
        height: 9px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 7px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 7px;
        height: 7px;
      }
    }
  }
  &:nth-child(2) {
    cursor: pointer;
    svg {
      display: block;
      width: 13px;
      height: 11px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 11px;
        height: 9px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 10px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        width: 9px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 8px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 9px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 8px;
        height: 7px;
      }
    }
  }
  &:nth-child(3) {
    cursor: pointer;
    svg {
      display: block;
      width: 11px;
      height: 11px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 9px;
        height: 9px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 7px;
        height: 7px;
      }
      ${({ theme }) => theme.adaptive.sm} {
        width: 8px;
        height: 8px;
      }
      ${({ theme }) => theme.adaptive.xs} {
        width: 7px;
        height: 7px;
      }
    }
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 56px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 28px;
`

const List = styled.div`
  width: 100%;
`

const Item = styled.div`
  display: flex;
  margin-bottom: 11px;
`

const Number = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: #f4f3eb;
  margin-right: 7px;
`

const Name = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 90%;
  letter-spacing: -0.02em;
  color: #f4f3eb;
  margin-right: 7px;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: dashed 1px #f4f3eb;
  margin-right: 7px;
  margin-top: 18px;
  opacity: 0.2;
`

const Dollar = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 90%;
  text-align: right;
  letter-spacing: -0.02em;
  color: #f4f3eb;
  opacity: 0.2;
  margin-right: 7px;
`

const Value = styled.div`
  font-family: 'Coders Crux';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 90%;
  text-align: right;
  letter-spacing: -0.02em;
  color: #74e7bf;
`

const Button = styled.button<{ isDisable?: boolean; isMargin?: boolean }>`
  position: relative;
  width: 100%;
  height: 54px;
  background: ${({ isDisable }) => (isDisable ? '#767676' : '#FF85FB')};
  border: 1px solid ${({ isDisable }) => (isDisable ? '#a3a3a3' : '#F4F3EB')};
  box-shadow: 6px 6px 0px rgba(255, 255, 255, 0.16);
  border-radius: 5px;
  padding: 14px 20px 14px 20px;
  font-family: 'Manrope';
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #f4f3eb;
  transition: 0.4s;
  text-align: left;
  margin-bottom: ${({ isMargin }) => (isMargin ? '18px' : '0')};
  &:hover {
    background: #ff65fa;
    box-shadow: 6px 6px 0px rgba(255, 255, 255, 0.3);
  }
`

const RestartIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 21px;
  svg {
    display: block;
    width: 24px;
    height: 20px;
    fill: #f4f3eb;
    transition: 0.4s;
  }
`
