import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import lines from 'img/dao/tiers/line.svg'
import { ReactComponent as Bg } from 'img/dao/tiers/bg.svg'
import Tier from './tier'
import useWindowSize from '../../../helpers/utils/useWindowSize'
import TierSlider from './mobile/slider'
import daoTiersList from '../../../store/daoTiers'

function Tiers() {
  const [activeId, setActiveId] = useState(0)
  const RootRef = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()

  // useEffect(() => {
  //   if (RootRef.current) {
  //     let areas = RootRef.current.querySelectorAll('.hover-area')
  //     let info: Element | null
  //     areas.forEach((area) => {
  //       area.addEventListener('mouseenter', () => {
  //         if (!RootRef.current) return
  //         let landName = area.classList[1]
  //         info = RootRef.current.querySelector(`.${landName}-info`)
  //         if (info) {
  //           console.log(info)
  //           info.classList.add('active')
  //         }
  //       })
  //       area.addEventListener('mouseleave', () => {
  //         if (info) {
  //           info.classList.remove('active')
  //         }
  //       })
  //     })
  //   }
  // }, [])

  return (
    <Root>
      <Line />
      <Container>
        <Bg className={'background'} />
        <TitleWrap>
          <SubTitle>DAO Board</SubTitle>
          <Title>Tiers</Title>
        </TitleWrap>
        {width > 960 && (
          <TierList ref={RootRef}>
            {daoTiersList.map((item, idx) => {
              return (
                <Tier
                  key={idx}
                  tier={item}
                  bg={item.getBg()}
                  id={idx + 1}
                  activeId={activeId}
                  setActiveId={setActiveId}
                />
              )
            })}
          </TierList>
        )}
      </Container>
      {width <= 960 && <TierSlider />}
      <BottomLine>
        <Line />
      </BottomLine>
    </Root>
  )
}

export default Tiers

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 325px;
  overflow: hidden;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 268px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 239px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 210px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 164px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 189px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 149px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 142px;
  }
  .background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 1052px;
    ${({ theme }) => theme.adaptive.xl} {
      height: 897px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      height: 835px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      height: 765px;
    }
    ${({ theme }) => theme.adaptive.md} {
      height: 632px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: unset;
      bottom: -974px;
      height: 956px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      bottom: -649px;
      height: 618px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      bottom: 555px;
      height: 548px;
    }
  }
`

const Line = styled.div`
  width: 100%;
  height: 52px;
  background: url('${lines}') repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 39px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 28px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 29px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 28px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 23px;
  }
`

const TitleWrap = styled.div`
  position: relative;
  padding-top: 108px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 90px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 81px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 72px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 58px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 60px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 41px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 39px;
  }
`

const SubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 90px;
  line-height: 63%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #090909;
  margin-bottom: 27px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 75px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 67px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 60px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 48px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 56px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 56px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 44px;
    margin-bottom: 14px;
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 234px;
  line-height: 63%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #74e7bf;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 195px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 175px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 156px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 124px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 116px;
  }
  ${({ theme }) => theme.adaptive.smm} {
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 90px;
  }
`

const BottomLine = styled.div`
  position: relative;
  border-top: 1px solid rgba(9, 9, 9, 0.1);
  padding: 20px 0;
  border-bottom: 1px solid rgba(9, 9, 9, 0.1);
  background: #f4f3eb;
  z-index: 2;
  ${({ theme }) => theme.adaptive.xl} {
    padding: 17px 0;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding: 15px 0;
  }
  ${({ theme }) => theme.adaptive.slg} {
  }
  ${({ theme }) => theme.adaptive.md} {
    padding: 11px 0;
  }
  ${({ theme }) => theme.adaptive.sm} {
  }
  ${({ theme }) => theme.adaptive.smm} {
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding: 9px 0;
  }
`

const TierList = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 714px;
  width: 100%;
  ${({ theme }) => theme.adaptive.xl} {
    height: 614px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    height: 581px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    height: 539px;
  }
  ${({ theme }) => theme.adaptive.md} {
    height: 544px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    height: 974px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    height: 650px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 555px;
  }
`
