import { Game } from './Game'

export class Rect {
  x: number
  y: number
  w: number
  h: number
  public markedForDelete: boolean
  private game: Game
  private color: string | undefined

  constructor(
    game: Game,
    x: number,
    y: number,
    w: number,
    h: number,
    color?: string
  ) {
    this.game = game
    this.x = x
    this.y = y
    this.w = w
    this.h = h
    this.markedForDelete = false
    this.color = color
  }

  update(deltaTime: number) {
    this.x -= this.game.groundSpeed * deltaTime

    if (this.x + this.w < 0 || this.y - this.h > this.game.height)
      this.markedForDelete = true
  }

  draw = (context: CanvasRenderingContext2D) => {
    context.fillStyle = this.color ? this.color : '#F4F3EB'
    context.save()
    context.beginPath()
    context.rect(this.x, this.y, this.w, this.h)
    context.fill()
    context.closePath()
    context.restore()
  }
}
