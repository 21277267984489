import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import titleImg from 'img/pad/about/title-img.svg'
import titleImgSm from 'img/pad/about/title-sm.svg'
import titleBg from 'img/pad/about/title-bg.svg'
import rectImg from 'img/pad/about/rect.svg'
import cloudsImg from 'img/pad/about/clouds.svg'
import occamImg from 'img/pad/about/occam.svg'
import readerImg from 'img/pad/about/luna.png'

function About() {
  return (
    <Root>
      <Container>
        <TitleBg />
        <Title>
          <Clouds />
        </Title>
        <SubTitle>
          NEARStarter is a tokenized innovation incubator and next generation
          launchpad with the capabilities and resources required by the world’s
          best DeFi and Web3 entrepreneurs to hyperscale and launch their
          projects on the NEAR blockchain.
        </SubTitle>
        <Text>
          NEARStarter, built by the DeFi OGs of <span>Occam.fi</span>
        </Text>
        <List>
          <Row>
            <Item>
              <ItemIcon />
              <ItemText>
                Adapting rapidly to the high-paced demands of the NEAR
                environment.
              </ItemText>
            </Item>
            <Item>
              <ItemIcon />
              <ItemText>
                Empathizing with users, traders, builders and VCs to ensure most
                optimal (social) capital allocation.
              </ItemText>
            </Item>
          </Row>
          <Row>
            <Item>
              <ItemIcon />
              <ItemText>
                Incubating the best projects aiming to develop and mature on
                NEAR.
              </ItemText>
            </Item>
            <Item>
              <ItemIcon />
              <ItemText>
                Offering creative and innovative solutions from technical to
                marketing challenges.
              </ItemText>
            </Item>
          </Row>
        </List>
        <Links>
          <Occam href={'https://occam.fi/'} target={'_blank'} />
          <Reader />
        </Links>
      </Container>
    </Root>
  )
}

export default About

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 277px;
  padding-bottom: 165px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 233px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 210px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 187px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 149px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 158px;
    padding-bottom: 432px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 143px;
    padding-bottom: 401px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 111px;
    padding-bottom: 332px;
  }
`

const TitleBg = styled.div`
  position: absolute;
  top: -530px;
  left: -527px;
  width: 1488px;
  height: 772px;
  background: url('${titleBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: -445px;
    left: -443px;
    width: 1250px;
    height: 648px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -401px;
    left: -398px;
    width: 1127px;
    height: 584px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -357px;
    left: -354px;
    width: 1002px;
    height: 520px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -286px;
    left: -283px;
    width: 802px;
    height: 416px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: -36px;
    left: 0;
    width: 539px;
    height: 403px;
    background: #111111;
    border: 1px solid #434343;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: -39px;
    width: 350px;
    height: 448px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -31px;
    width: 280px;
    height: 355px;
  }
`

const Title = styled.div`
  position: relative;
  width: 862px;
  height: 91px;
  background: url('${titleImg}') center no-repeat;
  background-size: contain;
  margin-bottom: 15px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 717px;
    height: 76px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 646px;
    height: 68px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 571px;
    height: 60px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 457px;
    height: 48px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 463px;
    height: 158px;
    margin-bottom: 12px;
    background: url('${titleImgSm}') center no-repeat;
    background-size: contain;
    margin-left: 28px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 280px;
    height: 94px;
    margin-bottom: 11px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 224px;
    height: 75px;
    margin-bottom: 9px;
    margin-left: 24px;
  }
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  width: 816px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 686px;
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 618px;
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 550px;
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 440px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 482px;
    font-size: 21px;
    margin-left: 28px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 298px;
    font-size: 21px;
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 237px;
    font-size: 18px;
    margin-left: 24px;
  }
`

const Text = styled.div`
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  width: 796px;
  margin-top: 101px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 31px;
    width: 669px;
    margin-top: 85px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 28px;
    width: 603px;
    margin-top: 78px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 25px;
    width: 536px;
    margin-top: 71px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 20px;
    width: 429px;
    margin-top: 55px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 37px;
    width: 100%;
    margin-top: 139px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 105px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 29px;
    margin-top: 107px;
  }
  span {
    color: #62dcaf;
  }
`

const List = styled.div`
  display: flex;
  margin-top: 30px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-top: 27px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-top: 25px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-top: 23px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-top: 19px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: block;
    margin-top: 29px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 24px;
  }
`

const Item = styled.div`
  display: flex;
  margin-bottom: 30px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-bottom: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-bottom: 30px;
  }
`

const ItemText = styled.div`
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 22px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  width: 414px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 18px;
    width: 348px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 16px;
    width: 313px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 14px;
    width: 279px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 11px;
    width: 223px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 22px;
    width: 499px !important;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 22px;
    width: 306px !important;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 18px;
    width: 245px !important;
  }
`

const ItemIcon = styled.div`
  width: 17px;
  height: 17px;
  background: url('${rectImg}') center no-repeat;
  background-size: cover;
  margin-right: 24px;
  margin-top: 7px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 14px;
    height: 14px;
    margin-right: 20px;
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 13px;
    height: 13px;
    margin-right: 18px;
    margin-top: 5px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 11px;
    height: 11px;
    margin-right: 18px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 9px;
    height: 9px;
    margin-right: 13px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 17px;
    height: 17px;
    margin-right: 24px;
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 13px;
    height: 13px;
    margin-right: 19px;
    margin-top: 5px;
  }
`

const Row = styled.div`
  &:nth-child(1) {
    margin-right: 70px;
    ${({ theme }) => theme.adaptive.xl} {
      margin-right: 59px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      margin-right: 53px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      margin-right: 47px;
    }
    ${({ theme }) => theme.adaptive.md} {
      margin-right: 38px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      margin-right: 0;
    }
    ${Item} {
      &:nth-child(2) {
        ${ItemText} {
          width: 497px;
          ${({ theme }) => theme.adaptive.xl} {
            width: 418px;
          }
          ${({ theme }) => theme.adaptive.lg} {
            width: 376px;
          }
          ${({ theme }) => theme.adaptive.slg} {
            width: 335px;
          }
          ${({ theme }) => theme.adaptive.md} {
            width: 268px;
          }
        }
      }
    }
    ${ItemText} {
      width: 452px;
      ${({ theme }) => theme.adaptive.xl} {
        width: 380px;
      }
      ${({ theme }) => theme.adaptive.lg} {
        width: 342px;
      }
      ${({ theme }) => theme.adaptive.slg} {
        width: 304px;
      }
      ${({ theme }) => theme.adaptive.md} {
        width: 244px;
      }
    }
  }
`

const Clouds = styled.div`
  position: absolute;
  top: -141px;
  right: -905px;
  width: 949px;
  height: 806px;
  background: url('${cloudsImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: -118px;
    right: -768px;
    width: 797px;
    height: 677px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -107px;
    right: -692px;
    width: 718px;
    height: 610px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -95px;
    right: -620px;
    width: 639px;
    height: 543px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -76px;
    right: -495px;
    width: 511px;
    height: 434px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 1080px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 602px;
    height: 462px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 1495px;
    width: 462px;
    height: 354px;
    left: -80px;
    transform: unset;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 1198px;
    width: 366px;
    height: 281px;
    left: -70px;
  }
`

const Links = styled.div`
  position: absolute;
  top: 20px;
  right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.adaptive.xl} {
    top: 12px;
    right: 67px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 20px;
    right: 59px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 13px;
    right: 52px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 11px;
    right: 55px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    position: relative;
    justify-content: center;
    top: unset;
    right: unset;
    margin-top: 62px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 97px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 76px;
  }
`

const Occam = styled.a`
  display: block;
  width: 152px;
  height: 45px;
  background: url('${occamImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    width: 128px;
    height: 38px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 115px;
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 103px;
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 82px;
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 165px;
    height: 48px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 137px;
    height: 40px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 108px;
    height: 32px;
  }
`

const Reader = styled.div`
  position: absolute;
  top: 82px;
  left: 50%;
  transform: translateX(-50%);
  width: 563px;
  height: 563px;
  background: url('${readerImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.xl} {
    top: 68px;
    width: 473px;
    height: 473px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 62px;
    width: 425px;
    height: 425px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 54px;
    width: 379px;
    height: 379px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 44px;
    width: 303px;
    height: 303px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 86px;
    width: 316px;
    height: 316px;
    left: 50%;
    transform: translateX(-50%);
    right: unset;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 87px;
    width: 285px;
    height: 285px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 80px;
    width: 226px;
    height: 226px;
  }
`
