import React from 'react'
import styled from 'styled-components'
import Header from 'components/index/header'
import Footer from '../footer'
import Main from './main'
import Board from './board'
import Benefits from './benefits'
import Tiers from './tiers'
import Join from './join'
import Contact from 'components/contact'

function Index() {
  return (
    <Root>
      <Header />
      <Main />
      <Board />
      <Benefits />
      <Tiers />
      <Join />
      <Contact />
      <Footer />
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #f4f3eb;
`
