import { ReactComponent as TierOne } from 'img/pad/tiers/items/tier-1.svg'
import { ReactComponent as TierTwo } from 'img/pad/tiers/items/tier-2.svg'
import { ReactComponent as TierThree } from 'img/pad/tiers/items/tier-3.svg'
import { ReactComponent as TierFour } from 'img/pad/tiers/items/tier-4.svg'
import { ReactComponent as TierFive } from 'img/pad/tiers/items/tier-5.svg'
import TierOneImg from 'img/pad/tiers/items/img/tier-1-img.svg'
import TierTwoImg from 'img/pad/tiers/items/img/tier-2-img.svg'
import TierThreeImg from 'img/pad/tiers/items/img/tier-3-img.svg'
import TierFourImg from 'img/pad/tiers/items/img/tier-4-img.svg'
import TierFiveImg from 'img/pad/tiers/items/img/tier-5-img.svg'

const tiersList = [
  {
    title: 'V',
    name: 'LunarPunk',
    nstart: '200 NSTART',
    participation: '+1',
    pool: 'x 1.0',
    img: TierOneImg,
    greenRects: 1,
    purpleRects: 0,
  },
  {
    title: 'IV',
    name: 'SolarPunk',
    nstart: '3500 NSTART',
    participation: '+20',
    pool: 'x 1.3',
    img: TierTwoImg,
    greenRects: 5,
    purpleRects: 0,
  },
  {
    title: 'III',
    name: 'NanoPunk',
    nstart: '14,000 NSTART',
    participation: '+100',
    pool: 'x 1.7',
    img: TierThreeImg,
    greenRects: 5,
    purpleRects: 3,
  },
  {
    title: 'II',
    name: 'BioPunk',
    nstart: '42,000 NSTART',
    participation: '+400',
    pool: 'x 2.2',
    img: TierFourImg,
    greenRects: 5,
    purpleRects: 7,
  },
  {
    title: 'I',
    name: 'CyberNoir',
    nstart: '90,000 NSTART',
    participation: 'MAX',
    pool: 'x 3',
    img: TierFiveImg,
    greenRects: 5,
    purpleRects: 11,
  },
]

export default tiersList
