import React from 'react'
import styled from 'styled-components'
import Main from './main'
import Header from 'components/index/header'
import About from './about'
import Contact from 'components/contact'
import Footer from 'components/footer'

function Index() {
  return (
    <Root>
      <Header />
      <Main />
      <About />
      <Contact />
      <Footer />
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #f4f3eb;
`
