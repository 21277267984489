import gsap from 'gsap'
gsap.config({ force3D: false })

export const FadeIn = (
  elem: gsap.DOMTarget,
  delay: number,
  bg?: gsap.DOMTarget,
  title?: gsap.DOMTarget,
  btn?: gsap.DOMTarget,
  list?: gsap.DOMTarget
) => {
  gsap.fromTo(
    elem,
    { opacity: 0, x: 100, y: 100 },
    {
      scrollTrigger: {
        trigger: elem,
        start: 'bottom bottom',
      },
      delay: delay,
      duration: 0.3,
      x: 0,
      y: 0,
      opacity: 1,
      onComplete: () => {
        if (bg && title && btn && list) {
          FadeInBg(bg, title, btn, list)
        }
      },
    }
  )
}

const FadeInBg = (
  elem: gsap.TweenTarget,
  title: gsap.TweenTarget,
  btn: gsap.TweenTarget,
  list: gsap.TweenTarget
) => {
  gsap.to(elem, {
    opacity: 1,
    duration: 0.4,
    onComplete: () => {
      FadeInElem(title)
      FadeInElem(btn)
      FadeInElem(list)
    },
  })
}

const FadeInElem = (el: gsap.TweenTarget) => {
  gsap.to(el, {
    opacity: 1,
    y: 0,
    duration: 0.4,
  })
}

export const anim = (
  el: gsap.TweenTarget,
  translateVal: any,
  rotateVal: any,
  scaleVal: any
) => {
  gsap.to(el, {
    left: '50%',
    opacity: 1,
    transform: `${translateVal} ${rotateVal} ${scaleVal}`,
    duration: 0.4,
  })
}

export const showAllAnim = (el: gsap.TweenTarget, idx: number) => {
  gsap.to(el, {
    opacity: 1,
    left: `${idx * 20}%`,
    transform: 'scale(1) translateY(-50%)',
    duration: 0.4,
  })
}
