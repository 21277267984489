import padInfoImg from 'img/index/incubator/pad-info-img.svg'
import dexInfoImg from 'img/index/incubator/dex-info-img.svg'
import incubatorInfoImg from 'img/index/incubator/incubator-info-img.svg'
import daoInfoImg from 'img/index/incubator/dao-info-img.svg'
import { DEX_LINK } from 'config/app'

const incubatorList = [
  {
    getName: (
      <>
        <span>NS</span>
        DAO
      </>
    ),
    text: 'DAO-governed accelerator designed to have decentralized project access controls and a price-discovery engine for the early-stage funding round.',
    img: daoInfoImg,
    link: '/dao',
  },
  {
    getName: (
      <>
        <span>NS</span>
        PAD
      </>
    ),
    text: 'Launchpad augmented with algorithmic tiers, deflationary tokenomics and community-centric automated diversification engine.',
    img: padInfoImg,
    link: '/pad',
  },
  {
    getName: <>Incubator</>,
    text: 'We onboard projects at every stage of development and deploy our extensive network, carrying the projects all the way from concept formulation to MVP and successful funding rounds.',
    img: incubatorInfoImg,
    link: '/incubator',
  },
  {
    getName: <>DEX</>,
    text: 'A robust, built-in trading orderbook based platform that provides a seamless transition to secondary markets for recently launched projects.',
    img: dexInfoImg,
    link: DEX_LINK,
  },
]

export default incubatorList
