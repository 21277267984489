import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import incubatorList from 'store/incubatorList'
import arrowImg from 'img/index/incubator/arrow.png'
import linesImg from 'img/index/incubator/lines.svg'
import iconImg from 'img/index/incubator/icon.svg'
import fishesMob from 'img/index/incubator/fishes-mobile.png'
import TextBorder from 'img/contact/text-border.svg'
import { Link } from 'react-router-dom'

function MobIncubator() {
  const [activeID, setActiveId] = useState(0)
  const [activeItem, setActiveItem] = useState<any>(null)

  const onClickHandler = (item: any, id: number) => {
    setActiveId(id)
    setActiveItem(item)
  }

  return (
    <Root>
      <Container>
        <Cont>
          <Fishes />
          <ApplyText>Apply for the acceleration program</ApplyText>
          <ActiveWrap active={activeID !== 0}>
            <TextWrap>
              <Text>{activeItem ? activeItem.text : ''}</Text>
            </TextWrap>
            <Btn
              onClick={() =>
                onClickHandler(incubatorList[activeID - 2], activeID - 1)
              }
              active={activeID !== 1}
            >
              <BtnArrow left={true} />
              <BtnText>Back</BtnText>
            </Btn>
            <ActiveItem>
              <Link to={activeItem ? activeItem.link : ''}>
                <ItemImg src={activeItem ? activeItem.img : ''} />
                <ItemName>{activeItem ? activeItem.getName : ''}</ItemName>
              </Link>
            </ActiveItem>
            <Btn
              onClick={() =>
                onClickHandler(incubatorList[activeID], activeID + 1)
              }
              active={activeID !== 4}
            >
              <BtnArrow left={false} />
              <BtnText>Next</BtnText>
            </Btn>
          </ActiveWrap>
          <Items active={activeID === 0}>
            {incubatorList.map((item, idx) => {
              return (
                <Item onClick={() => onClickHandler(item, idx + 1)} key={idx}>
                  <ItemImg src={item.img} />
                  <ItemName>{item.getName}</ItemName>
                </Item>
              )
            })}
          </Items>
          <Lines />
          <About>
            <Icon />
            <AboutText>
              <span>
                NEARStarter is a high tech community-owned ecosystem.{' '}
              </span>
              Expressly built for incubating, accelerating, and funding
              state-of-the-art projects deploying on the NEAR Protocol.
            </AboutText>
          </About>
        </Cont>
      </Container>
    </Root>
  )
}

export default MobIncubator

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #090909;
  padding-bottom: 88px;
  ${({ theme }) => theme.adaptive.smm} {
    padding-bottom: 101px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-bottom: 76px;
  }
`

const Cont = styled.div`
  width: 100%;
  margin-top: 597px;
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 608px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 476px;
  }
`

const Items = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid rgba(244, 243, 235, 0.1);
  border-radius: 6px;
  opacity: ${({ active }) => (active ? '1' : '0')};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  ${({ theme }) => theme.adaptive.xs} {
    border-radius: 4px;
  }
`

const ItemCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 269px;
  height: 150px;
  background: #0e0e0e;
  ${({ theme }) => theme.adaptive.smm} {
    width: 174px;
    height: 150px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 139px;
    height: 117px;
  }
  &:nth-child(1) {
    border-bottom: 1px solid rgba(244, 243, 235, 0.1);
    border-right: 1px solid rgba(244, 243, 235, 0.1);
  }
  &:nth-child(2) {
    border-bottom: 1px solid rgba(244, 243, 235, 0.1);
  }
  &:nth-child(3) {
    border-right: 1px solid rgba(244, 243, 235, 0.1);
  }
  &:nth-child(2),
  &:nth-child(3) {
    background: #090909;
  }
`

const Item = styled.div`
  ${ItemCss};
`

const ItemImg = styled.img`
  display: block;
  width: 70px;
  height: 70px;
  ${({ theme }) => theme.adaptive.xs} {
    width: 54px;
    height: 54px;
  }
`

const ItemName = styled.div`
  width: 100%;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #74e7bf;
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 28px;
  }
  span {
    color: #adadad;
  }
`

const ActiveWrap = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid rgba(244, 243, 235, 0.1);
  border-radius: 6px;
  transition: 0.4s;
  opacity: ${({ active }) => (active ? '1' : '0')};
  background: #090909;
  ${({ theme }) => theme.adaptive.xs} {
    border-radius: 4px;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border-bottom: 1px solid rgba(244, 243, 235, 0.1);
  ${({ theme }) => theme.adaptive.xs} {
    height: 117px;
  }
`

const Text = styled.div`
  font-family: 'Manrope';
  width: 396px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.smm} {
    width: 296px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 235px;
    font-size: 13px;
  }
`

const ActiveItem = styled.div`
  a {
    ${ItemCss};
    margin: auto;
    border-right: 1px solid rgba(244, 243, 235, 0.1);
    border-left: 1px solid rgba(244, 243, 235, 0.1);
  }
`

const BtnText = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  margin-top: 20px;
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 28px;
    margin-top: 15px;
  }
`

const BtnArrow = styled.div<{ left: boolean }>`
  width: 30px;
  height: 18px;
  transform: ${({ left }) => (left ? 'rotate(180deg)' : '')};
  background: url('${arrowImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.smm} {
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 14px;
  }
`

const Btn = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 134px;
  height: 150px;
  opacity: ${({ active }) => (active ? '1' : '0.3')};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  ${({ theme }) => theme.adaptive.smm} {
    width: 86px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 67px;
  }
`

const Lines = styled.div`
  width: 100%;
  height: 41px;
  margin-top: 21px;
  background: url('${linesImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.smm} {
    height: 43px;
    margin-top: 22px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    height: 34px;
    margin-top: 18px;
  }
`

const About = styled.div`
  display: flex;
  margin-top: 58px;
  ${({ theme }) => theme.adaptive.smm} {
    margin-top: 66px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-top: 52px;
  }
`

const Icon = styled.div`
  width: 26px;
  height: 21px;
  background: url('${iconImg}') center no-repeat;
  background-size: cover;
  margin-right: 21px;
  margin-top: 3px;
  ${({ theme }) => theme.adaptive.smm} {
    margin-right: 25px;
    margin-top: 4px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 20px;
    height: 16px;
    margin-right: 22px;
    margin-top: 3px;
  }
`

const AboutText = styled.div`
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #f4f3eb;
  width: 450px;
  ${({ theme }) => theme.adaptive.smm} {
    width: 299px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 12px;
    width: 233px;
  }
  span {
    margin-bottom: 21px;
    font-size: 23px;
    display: block;
    font-weight: 600;
    ${({ theme }) => theme.adaptive.smm} {
      margin-bottom: 10px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      font-size: 18px;
      margin-bottom: 9px;
    }
  }
`

const Fishes = styled.div`
  position: absolute;
  top: -1017px;
  left: -353px;
  width: 1419px;
  height: 1419px;
  background: url('${fishesMob}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.smm} {
    top: -1030px;
    left: -360px;
    width: 1417px;
    height: 1417px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -785px;
    left: -263px;
    width: 1061px;
    height: 1061px;
  }
`

const ApplyText = styled.div`
  position: absolute;
  top: -529px;
  left: 18px;
  width: 114px;
  height: 66px;
  font-family: 'Coders Crux';
  text-align: right;
  font-weight: 400;
  font-size: 23px;
  line-height: 96%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.smm} {
    top: -540px;
    width: 113px;
    height: 65px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: -419px;
    width: 89px;
    height: 51px;
    font-size: 18px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: -18px;
    width: 158px;
    height: 110px;
    background: url('${TextBorder}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.xs} {
      width: 124px;
      height: 85px;
    }
  }
`
