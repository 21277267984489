import { ReactComponent as TierOneBg } from 'img/dao/tiers/tier-one-img.svg'
import { ReactComponent as TierTwoBg } from 'img/dao/tiers/tier-two-img.svg'
import { ReactComponent as TierThreeBg } from 'img/dao/tiers/tier-three-img.svg'

export interface TierProps {
  getBg: () => void
  title: string
  smallInfoVal: string
  requirementsValue: string
  firstLi: string
  secondLi: string
  thirdLi: string
  color: string
}

const daoTiersList: TierProps[] = [
  {
    getBg: () => <TierOneBg />,
    title: 'Apex Punk',
    smallInfoVal: '50k',
    requirementsValue: '50,000 NSTART',
    firstLi: 'IDO allocations',
    secondLi: 'Early access to incubated projects',
    thirdLi: 'Access to private rounds',
    color: '#74E7BF',
  },
  {
    getBg: () => <TierTwoBg />,
    title: 'Elf Punk',
    smallInfoVal: '100k',
    requirementsValue: '100,000 NSTART',
    firstLi: 'IDO allocations',
    secondLi: 'Early access to incubated projects',
    thirdLi: 'Access to seed & private rounds',
    color: '#FFFFFF',
  },
  {
    getBg: () => <TierThreeBg />,
    title: 'Myth Punk',
    smallInfoVal: '200k',
    requirementsValue: '200,000 NSTART',
    firstLi: 'Early access to incubated projects',
    secondLi: 'Access to seed & private rounds',
    thirdLi: 'Seat in the DAO governance Board',
    color: '#FF85FB',
  },
]

export default daoTiersList
