import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/theme'
import attentionImg from 'img/dao/benefits/attention.svg'
import steamImg from 'img/dao/benefits/steam.svg'
import steamSmImg from 'img/dao/benefits/steam-sm.svg'
import lines3dImg from 'img/dao/benefits/lines-3d.svg'
import fishImg from 'img/dao/benefits/fish.svg'
import nImg from 'img/dao/benefits/n.svg'
import descBg from 'img/dao/benefits/desc-bg.svg'
import descSmBg from 'img/dao/benefits/desc-bg-sm.svg'
import descSmmBg from 'img/dao/benefits/desc-bg-smm.svg'
import descXsBg from 'img/dao/benefits/desc-bg-xs.svg'

function Benefits() {
  return (
    <Root>
      <Container>
        <Cont>
          <Lines3d />
          <Steam />
          <Fish />
          <Title>Benefits</Title>
          <SubTitle>Overview</SubTitle>
          <Text>
            NEARStarter is a next generation tokenized incubator with full
            capabilities and resources to support the world’s best DeFi and Web3
            entrepreneurs to hyperscale and launch their projects on the NEAR
            blockchain.
          </Text>
          <Attention>
            The access to the NS tokenized incubator governance system{' '}
            <span>
              will only be grated to the most dedicated and reliable members
              with a large stake of $NSTART tokens.
            </span>
          </Attention>
          <DescBlock>
            <Desc>
              Only members of the sectors could join the NSDAO Board and get
              access to early stage fundraising rounds, exclusive events and
              more!
            </Desc>
            <List>
              <Column>
                <Item>Access to early stage investment rounds</Item>
                <Item>Seed and IDO access control</Item>
                <Item>
                  Invitations to hosted and sponsored NEARStarter events
                </Item>
              </Column>
              <Column>
                <Item>
                  Active involvement in the governance via Board calls
                </Item>
                <Item>
                  Ability to propose and vote for a new project to join the
                  ecosystem
                </Item>
                <Item>
                  Power to vote for and set forth governance proposals
                </Item>
              </Column>
            </List>
          </DescBlock>
        </Cont>
      </Container>
    </Root>
  )
}

export default Benefits

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 305px;
  ${({ theme }) => theme.adaptive.xl} {
    padding-top: 254px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    padding-top: 233px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    padding-top: 202px;
  }
  ${({ theme }) => theme.adaptive.md} {
    padding-top: 161px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    padding-top: 295px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    padding-top: 284px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    padding-top: 239px;
  }
`

const Cont = styled.div`
  position: relative;
  margin-left: 388px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-left: 323px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-left: 292px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-left: 259px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-left: 207px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-left: 0;
  }
`

const Title = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 234px;
  line-height: 63%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #74e7bf;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 195px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 175px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 156px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 124px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 116px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 90px;
  }
`

const SubTitle = styled.div`
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 90px;
  line-height: 63%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #090909;
  margin-bottom: 50px;
  ${({ theme }) => theme.adaptive.xl} {
    font-size: 75px;
    margin-bottom: 42px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    font-size: 67px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    font-size: 60px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.md} {
    font-size: 48px;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    font-size: 56px;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 56px;
    margin-bottom: 112px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 44px;
    margin-bottom: 86px;
  }
`

const Text = styled.div`
  width: 998px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  margin-bottom: 42px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 812px;
    font-size: 30px;
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 730px;
    font-size: 27px;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 648px;
    font-size: 24px;
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 530px;
    font-size: 20px;
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 35px;
    margin-bottom: 333px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 27px;
    margin-bottom: 300px;
  }
`

const Attention = styled.div`
  position: relative;
  width: 788px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 23px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  margin-left: 36px;
  margin-bottom: 136px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 745px;
    font-size: 20px;
    margin-left: 31px;
    margin-bottom: 108px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 633px;
    font-size: 17px;
    margin-left: 27px;
    margin-bottom: 96px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 597px;
    font-size: 16px;
    margin-left: 25px;
    margin-bottom: 82px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 500px;
    font-size: 13px;
    margin-left: 20px;
    margin-bottom: 61px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 500px;
    font-size: 23px;
    margin-left: 39px;
    margin-bottom: 136px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 331px;
    font-size: 23px;
    margin-left: 36px;
    margin-bottom: 119px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 260px;
    font-size: 18px;
    margin-left: 29px;
    margin-bottom: 100px;
  }
  span {
    font-weight: 700;
  }
  &:before {
    content: '';
    position: absolute;
    left: -36px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 71px;
    background: url('${attentionImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      left: -31px;
      width: 8px;
      height: 62px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      left: -27px;
      width: 7px;
      height: 54px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      left: -25px;
      width: 7px;
      height: 54px;
    }
    ${({ theme }) => theme.adaptive.md} {
      left: -20px;
      width: 6px;
      height: 46px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 4px;
      transform: none;
      left: -39px;
      width: 10px;
      height: 77px;
    }
    ${({ theme }) => theme.adaptive.smm} {
      left: -36px;
      width: 10px;
      height: 77px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      left: -29px;
      width: 8px;
      height: 63px;
    }
  }
`

const Steam = styled.div`
  position: absolute;
  top: -86px;
  left: 725px;
  width: 135px;
  height: 304px;
  background: url('${steamImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    top: -72px;
    left: 614px;
    width: 113px;
    height: 254px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -64px;
    left: 534px;
    width: 101px;
    height: 228px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -57px;
    left: 483px;
    width: 90px;
    height: 203px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -46px;
    left: 386px;
    width: 72px;
    height: 162px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: -41px;
    left: 280px;
    width: 400px;
    height: 399px;
    background: url('${steamSmImg}') center no-repeat;
    background-size: contain;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 27px;
    left: 112px;
    width: 400px;
    height: 399px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 22px;
    left: 90px;
    width: 325px;
    height: 324px;
  }
`

const Lines3d = styled.div`
  position: absolute;
  top: -115px;
  left: -747px;
  width: 653px;
  height: 968px;
  background: url('${lines3dImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    top: -96px;
    left: -622px;
    width: 544px;
    height: 807px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: -86px;
    left: -561px;
    width: 490px;
    height: 726px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: -76px;
    left: -497px;
    width: 435px;
    height: 645px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: -61px;
    left: -400px;
    width: 348px;
    height: 516px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    display: none;
  }
`

const Fish = styled.div`
  position: absolute;
  top: 184px;
  left: 1138px;
  width: 333px;
  height: 333px;
  background: url('${fishImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.xl} {
    top: 173px;
    left: 839px;
    width: 271px;
    height: 271px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    top: 156px;
    left: 781px;
    width: 271px;
    height: 271px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    top: 158px;
    left: 681px;
    width: 211px;
    height: 211px;
  }
  ${({ theme }) => theme.adaptive.md} {
    top: 100px;
    left: 536px;
    width: 192px;
    height: 192px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    top: 507px;
    left: 50%;
    transform: translateX(-50%);
    width: 271px;
    height: 271px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    top: 794px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 583px;
  }
`

const DescBlock = styled.div`
  position: relative;
  margin-left: 36px;
  ${({ theme }) => theme.adaptive.xl} {
    margin-left: 30px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-left: 27px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-left: 24px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-left: 19px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    margin-left: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: -40px;
    left: -36px;
    width: 1449px;
    height: 676px;
    background: url('${descBg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      top: -33px;
      left: -30px;
      width: 1208px;
      height: 563px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: -30px;
      left: -27px;
      width: 1087px;
      height: 507px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: -27px;
      left: -24px;
      width: 966px;
      height: 451px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: -21px;
      left: -19px;
      width: 773px;
      height: 361px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: -63px;
      left: -306px;
      width: 1687px;
      height: 1038px;
      background: url('${descSmBg}') center no-repeat;
      background-size: contain;
    }
    ${({ theme }) => theme.adaptive.smm} {
      top: -55px;
      left: -83px;
      width: 1464px;
      height: 1297px;
      background: url('${descSmmBg}') center no-repeat;
      background-size: contain;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: -49px;
      left: -229px;
      width: 1350px;
      height: 1065px;
      background: url('${descXsBg}') center no-repeat;
      background-size: contain;
    }
  }
`

const Desc = styled.div`
  width: 1003px;
  font-family: 'Manrope';
  font-weight: 400;
  font-size: 37px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  margin-bottom: 67px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 814px;
    font-size: 30px;
    margin-bottom: 57px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 735px;
    font-size: 27px;
    margin-bottom: 53px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 653px;
    font-size: 24px;
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 548px;
    font-size: 20px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    font-size: 37px;
    margin-bottom: 106px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    font-size: 35px;
    margin-bottom: 103px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    font-size: 28px;
    margin-bottom: 82px;
  }
  span {
    font-weight: 600;
  }
`

const List = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 1028px;
  margin-left: 40px;
  ${({ theme }) => theme.adaptive.xl} {
    width: 838px;
    margin-left: 32px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    width: 749px;
    margin-left: 29px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    width: 663px;
    margin-left: 25px;
  }
  ${({ theme }) => theme.adaptive.md} {
    width: 536px;
    margin-left: 20px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    display: block;
    margin-left: 0;
  }
`

const Item = styled.div`
  position: relative;
  margin-left: 40px;
  margin-bottom: 31px;
  font-family: 'Manrope';
  font-weight: 300;
  font-size: 30px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #090909;
  opacity: 0.8;
  ${({ theme }) => theme.adaptive.xl} {
    margin-left: 34px;
    margin-bottom: 26px;
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.lg} {
    margin-left: 30px;
    margin-bottom: 23px;
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.slg} {
    margin-left: 27px;
    margin-bottom: 21px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.md} {
    margin-left: 22px;
    margin-bottom: 17px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.sm} {
    width: 485px !important;
    margin-left: 40px;
    margin-bottom: 28px;
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.smm} {
    width: 304px !important;
  }
  ${({ theme }) => theme.adaptive.xs} {
    width: 261px !important;
    margin-left: 32px;
    margin-bottom: 23px;
    font-size: 24px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 9px;
    left: -40px;
    width: 20px;
    height: 20px;
    background: url('${nImg}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.xl} {
      top: 8px;
      left: -34px;
      width: 17px;
      height: 17px;
    }
    ${({ theme }) => theme.adaptive.lg} {
      top: 6px;
      left: -30px;
      width: 15px;
      height: 15px;
    }
    ${({ theme }) => theme.adaptive.slg} {
      top: 6px;
      left: -27px;
      width: 14px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.md} {
      top: 5px;
      left: -22px;
      width: 11px;
      height: 11px;
    }
    ${({ theme }) => theme.adaptive.sm} {
      top: 10px;
      left: -40px;
      width: 20px;
      height: 20px;
    }
    ${({ theme }) => theme.adaptive.xs} {
      top: 8px;
      left: -32px;
      width: 16px;
      height: 16px;
    }
  }
`

const Column = styled.div`
  display: block;
  width: 50%;
  ${({ theme }) => theme.adaptive.sm} {
    width: 100%;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    margin-bottom: 23px;
  }
  &:last-child {
    ${({ theme }) => theme.adaptive.sm} {
      margin-bottom: 0;
    }
  }
  &:nth-child(1) {
    ${Item} {
      &:nth-child(1) {
        width: 405px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 338px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 304px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 270px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 216px;
        }
      }
      &:nth-child(2) {
        width: 360px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 280px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 260px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 230px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 200px;
        }
      }
      &:nth-child(3) {
        width: 429px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 346px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 317px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 285px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 226px;
        }
      }
    }
  }
  &:nth-child(2) {
    ${Item} {
      &:nth-child(1) {
        width: 443px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 337px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 292px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 269px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 215px;
        }
      }
      &:nth-child(2) {
        width: 460px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 368px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 331px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 307px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 250px;
        }
      }
      &:nth-child(3) {
        width: 451px;
        ${({ theme }) => theme.adaptive.xl} {
          width: 368px;
        }
        ${({ theme }) => theme.adaptive.lg} {
          width: 321px;
        }
        ${({ theme }) => theme.adaptive.slg} {
          width: 287px;
        }
        ${({ theme }) => theme.adaptive.md} {
          width: 230px;
        }
      }
    }
  }
`
