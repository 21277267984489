import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import lineImg from 'img/header/line.svg'
import closeImg from 'img/header/close.svg'
import daoImg from 'img/index/incubator/dao-info-img.svg'
import dexImg from 'img/index/incubator/dex-info-img.svg'
import padImg from 'img/index/incubator/pad-info-img.svg'
import incubatorImg from 'img/index/incubator/incubator-info-img.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Telegram } from 'img/socials/telegram.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'
import { ReactComponent as Discord } from 'img/socials/discord.svg'
import {
  APPLY_FOR_IDO_LINK,
  CONTACT_LINK,
  DECK_LINK,
  DISCORD_LINK,
  MEDIUM_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
  FAQ_LINK,
} from 'config/app'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'

interface Props {
  isOpen: boolean
  onClose: () => void
}

function Menu(props: Props) {
  const rootRef = useRef(null)
  useOnClickOutside({
    ref: rootRef,
    handler: () => onClose(),
  })

  const onClose = () => {
    props.onClose()
  }

  return (
    <Root ref={rootRef} isOpen={props.isOpen}>
      <Line />
      <Container>
        <Main>
          <Item>
            <Link to={'/dao'}>
              <ItemImg src={daoImg} />
              <ItemText>
                <span>NS</span>DAO
              </ItemText>
            </Link>
          </Item>
          <Item>
            <Link to={'/pad'}>
              <ItemImg src={padImg} />
              <ItemText>
                <span>NS</span>PAD
              </ItemText>
            </Link>
          </Item>
          <Item>
            <Link to={'/dex'}>
              <ItemImg src={dexImg} />
              <ItemText>DEX</ItemText>
            </Link>
          </Item>
          <Item>
            <Link to={'/incubator'}>
              <ItemImg src={incubatorImg} />
              <ItemText>Incubator</ItemText>
            </Link>
          </Item>
        </Main>
        <Nav>
          <NavLink href={CONTACT_LINK} target={'_blank'}>
            Contact us
          </NavLink>
          <NavLink href={APPLY_FOR_IDO_LINK} target={'_blank'}>
            Apply for IDO
          </NavLink>
          <NavLink href={DECK_LINK} target={'_blank'}>
            Deck
          </NavLink>
          <NavLink href={FAQ_LINK} target={'_blank'}>
            FAQ
          </NavLink>
        </Nav>
        <Socials>
          <SocialItem href={TELEGRAM_LINK} target={'_blank'}>
            <Telegram />
            <SocialText>Telegram</SocialText>
          </SocialItem>
          <SocialItem href={MEDIUM_LINK} target={'_blank'}>
            <Medium />
            <SocialText>Medium</SocialText>
          </SocialItem>
          <SocialItem href={TWITTER_LINK} target={'_blank'}>
            <Twitter />
            <SocialText>Twitter</SocialText>
          </SocialItem>
          <SocialItem href={DISCORD_LINK} target={'_blank'}>
            <Discord />
            <SocialText>Discord</SocialText>
          </SocialItem>
        </Socials>
      </Container>
      <Close onClick={onClose} />
    </Root>
  )
}

export default Menu

const Root = styled.div<{ isOpen: boolean }>`
  width: 388px;
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 51px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  background: #f4f3eb;
  border: 2px solid #090909;
  box-shadow: 6px 6px 0px #444444;
  border-radius: 8px;
  transition: 0.4s;
  z-index: 11;
  ${({ theme }) => theme.adaptive.sm} {
    top: -3px;
    left: unset;
    right: -3px;
  }
  ${({ theme }) => theme.adaptive.xs} {
    top: 25px;
    width: 100%;
  }
`

const Container = styled.div`
  width: 288px;
  margin: 0 auto;
`

const Line = styled.div`
  width: 100%;
  height: 48px;
  background: url('${lineImg}') center repeat;
  background-size: cover;
  margin-bottom: 29px;
`

const Main = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 278px;
  border: 1px solid #dddcd4;
  border-radius: 6px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 100%;
    background: #dddcd4;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background: #dddcd4;
  }
`

const ItemImg = styled.img`
  display: block;
  width: 70px;
  height: 70px;
`

const ItemText = styled.div`
  width: 100%;
  font-family: 'Coders Crux';
  font-weight: 400;
  font-size: 35px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #090909;
  transition: 0.4s;
  span {
    color: #adadad;
    transition: 0.4s;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 50%;
  a {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &:hover {
      span {
        color: #74e7bf;
      }
    }
  }
`

const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 29px;
`

const NavLink = styled.a`
  width: 50%;
  margin-bottom: 14px;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #272a32;
  transition: 0.4s;
  &:hover {
    color: #ff65fa;
  }
`

const Socials = styled.div`
  position: relative;
  margin-top: 12px;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(9, 9, 9, 0.12);
  }
`

const SocialText = styled.div`
  font-family: 'MMC';
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #272a32;
  transition: 0.4s;
`

const SocialItem = styled.a`
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  cursor: pointer;
  svg {
    display: block;
    max-width: 19px;
    max-height: 14px;
    margin-right: 14px;
    transition: 0.4s;
    fill: #272a32;
  }
  &:hover {
    ${SocialText} {
      color: #ff65fa;
    }
    svg {
      fill: #ff65fa;
    }
  }
`

const Close = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  z-index: 2;
  ${({ theme }) => theme.adaptive.sm} {
    left: unset;
    right: -1px;
    top: -1px;
  }
`
